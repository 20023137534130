import React from 'react'
import { Typography, Checkbox } from '@mui/material'
import { Wrapper, CheckBoxFrame } from '../../styles.ts'

const DataTransfer = props => {
  const { doChecked, handleDoChecked } = props

  return (
    <Wrapper>
      <Typography variant='h2'>Передача данных в федеральные системы</Typography>
      <CheckBoxFrame>
        <Checkbox checked={doChecked} onChange={handleDoChecked} id='doChecked' />
        <label for='doChecked' style={{ fontSize: '15px', lineHeight: '24px', color: '#4f5960' }}>
          Одобрить передачу данных из протокола во ФГИС «Аршин» и ФСА
        </label>
      </CheckBoxFrame>
    </Wrapper>
  )
}

export default DataTransfer
