// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Typography } from '@mui/material'
import RightSideContainer from '../../../LocalElements/RightSideContainer/RightSideContainer'
import DatePicker from '../../../LocalElements/DatePicker/DatePicker'
import { Wrapper, FrameFilter } from '../../style'
import RadioButton from '../../../../../ui/radio-button/index'
import Button from '../../../../../ui/button/index.tsx'
import {
  changeKitVerifications,
  resetKitVerifications,
} from '../../../../../store/slices/app/views/journals'

const Filter = () => {
  const dispatch = useDispatch()

  const isReturned = useSelector(state => state.journalReducer.kitVerifications.isReturned)
  const createdByRole = useSelector(state => state.journalReducer.kitVerifications.createdByRole)
  const verificationDateFrom = useSelector(
    state => state.journalReducer.kitVerifications.verificationDateFrom
  )
  const verificationDateTo = useSelector(
    state => state.journalReducer.kitVerifications.verificationDateTo
  )
  const createdAtFrom = useSelector(state => state.journalReducer.kitVerifications.createdAtFrom)
  const createdAtTo = useSelector(state => state.journalReducer.kitVerifications.createdAtTo)
  const expirationDateFrom = useSelector(
    state => state.journalReducer.kitVerifications.expirationDateFrom
  )
  const expirationDateTo = useSelector(
    state => state.journalReducer.kitVerifications.expirationDateTo
  )

  const handleResetFilter = () => {
    dispatch(resetKitVerifications())
  }

  const changeIsReturned = event => {
    dispatch(changeKitVerifications({ value: 'isReturned', label: event.target.value }))
  }

  const changeCreatedByRole = event => {
    dispatch(changeKitVerifications({ value: 'createdByRole', label: event.target.value }))
  }

  const handleCreatedAFromChange = date => {
    if (date) {
      dispatch(
        changeKitVerifications({
          value: 'createdAtFrom',
          label: date.format('YYYY-MM-DD'),
        })
      )
    } else {
      dispatch(changeKitVerifications({ value: 'createdAtFrom', label: '' }))
    }
  }
  const handleCreatedAtToChange = date => {
    if (date) {
      dispatch(
        changeKitVerifications({
          value: 'createdAtTo',
          label: date.format('YYYY-MM-DD'),
        })
      )
    } else {
      dispatch(changeKitVerifications({ value: 'createdAtTo', label: '' }))
    }
  }
  const handleVerificationDateFromChange = date => {
    if (date) {
      dispatch(
        changeKitVerifications({
          value: 'verificationDateFrom',
          label: date.format('YYYY-MM-DD'),
        })
      )
    } else {
      dispatch(changeKitVerifications({ value: 'verificationDateFrom', label: '' }))
    }
  }
  const handleVerificationDateToChange = date => {
    if (date) {
      dispatch(
        changeKitVerifications({
          value: 'verificationDateTo',
          label: date.format('YYYY-MM-DD'),
        })
      )
    } else {
      dispatch(changeKitVerifications({ value: 'verificationDateTo', label: '' }))
    }
  }

  const handleExpirationDateFromChange = date => {
    if (date) {
      dispatch(
        changeKitVerifications({
          value: 'expirationDateFrom',
          label: date.format('YYYY-MM-DD'),
        })
      )
    } else {
      dispatch(changeKitVerifications({ value: 'expirationDateFrom', label: '' }))
    }
  }
  const handleExpirationDateToChange = date => {
    if (date) {
      dispatch(
        changeKitVerifications({
          value: 'expirationDateTo',
          label: date.format('YYYY-MM-DD'),
        })
      )
    } else {
      dispatch(changeKitVerifications({ value: 'expirationDateTo', label: '' }))
    }
  }

  useEffect(() => {
    return () => {
      handleResetFilter()
    }
  }, [])

  return (
    <RightSideContainer blockTitle={'Фильтры'} containerWidth={400}>
      <Wrapper>
        <FrameFilter>
          <Typography variant='subtitle1'>Отметка о новой поверке</Typography>
          <RadioButton
            value={isReturned}
            onChange={changeIsReturned}
            trueLabel={'Eсть'}
            falseLabel={'Нет'}
            trueValue={'is-new-verification'}
            falseValue={'is-old-verification'}
          />
        </FrameFilter>

        <FrameFilter>
          <Typography variant='subtitle1'>Создатель записи</Typography>
          <RadioButton
            value={createdByRole}
            onChange={changeCreatedByRole}
            trueLabel={'Система'}
            falseLabel={'Пользователь'}
            trueValue={'system'}
            falseValue={'user'}
          />
        </FrameFilter>

        <FrameFilter>
          <Typography variant='subtitle1'>Дата последней поверки</Typography>
          <DatePicker
            selectedDate={verificationDateFrom}
            dateChange={handleVerificationDateFromChange}
          />

          <DatePicker
            selectedDate={verificationDateTo}
            dateChange={handleVerificationDateToChange}
          />
        </FrameFilter>

        <FrameFilter>
          <Typography variant='subtitle1'>Дата истечения срока поверки</Typography>
          <DatePicker
            selectedDate={expirationDateFrom}
            dateChange={handleExpirationDateFromChange}
          />

          <DatePicker selectedDate={expirationDateTo} dateChange={handleExpirationDateToChange} />
        </FrameFilter>

        <FrameFilter>
          <Typography variant='subtitle1'>Дата создания записи</Typography>
          <DatePicker selectedDate={createdAtFrom} dateChange={handleCreatedAFromChange} />

          <DatePicker selectedDate={createdAtTo} dateChange={handleCreatedAtToChange} />
        </FrameFilter>
        <Button
          onClick={handleResetFilter}
          label='Сбросить все значения'
          color='secondary'
          fullWidth
        />
      </Wrapper>
    </RightSideContainer>
  )
}

export default Filter
