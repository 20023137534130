// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ImageUploading from 'react-images-uploading'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import CircularProgress from '@mui/material/CircularProgress'
import ImageViewer from '../../../LocalElements/Modal/ImageViewer'
import Input from '../../../LocalElements/Input/InputMUI'
import { settingsImageViewer } from '../../../../../store/slices/app/comps/modal'
import useWindowDimensions from '../../../../hooks/useWindowDimensions'
import DownloadIcon from '../../../../../img/icon/DownloadIcon'
import DeleteIcon from '@mui/icons-material/Delete'
import Replace from '../../../../../img/icon/Replace'
import SearchIcon from '../../../../../img/icon/SearchIcon'
import R from '../../../../../services/app/client-server/request.service'
import { changeProperty } from '../../water/Mi-1592-2015/state'
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from '../../../../../store/slices/app/comps/snackbar'

const ImageUpload = props => {
  const { showTitle = true, showInput = true, count = 10 } = props
  const dispatch = useDispatch()
  const { width } = useWindowDimensions()
  const maxNumber = count

  const isTrusted = useSelector(state => state.headerReducer.isTrusted)
  const images = useSelector(state => state.mi15922015Reducer.images)
  const selectedImages = useSelector(state => state.mi15922015Reducer.selectedImages || [])
  const comment = useSelector(state => state.mi15922015Reducer.comment)
  const file = useSelector(state => state.mi15922015Reducer.file)
  const imgBase64 = useSelector(state => state.mi15922015Reducer.imgBase64)
  const fileBase64 = useSelector(state => state.mi15922015Reducer.fileBase64)

  const [loading, setLoading] = React.useState(false)

  const totalImages = imgBase64.length + images.length

  const onChangeImage = imageList => {
    const remainingSlots = maxNumber - imgBase64.length
    const addedImages = imageList.slice(0, remainingSlots)
    const ignoredImagesCount = imageList.length - remainingSlots

    dispatch(
      changeProperty({
        value: 'images',
        label: addedImages.map(image => image.file),
      }),
    )

    if (ignoredImagesCount > 0) {
      dispatch(
        setMessage(
          'Максимальное количество изображений — 10. Изображения, превышающие этот лимит, добавляться не будут.',
        ),
      )
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
    }
  }

  const handleImageClick = imageUrl => {
    dispatch(settingsImageViewer({ value: 'currentImage', label: imageUrl }))
    dispatch(settingsImageViewer({ value: 'open', label: true }))
  }

  const handleCommentChange = param => {
    dispatch(changeProperty({ value: 'comment', label: param }))
  }

  const handleCheckboxChange = (index, isBase64 = false) => {
    const identifier = isBase64 ? `base64-${index}` : `image-${index}`
    dispatch(
      changeProperty({
        value: 'selectedImages',
        label: selectedImages.includes(identifier)
          ? selectedImages.filter(i => i !== identifier)
          : [...selectedImages, identifier],
      }),
    )
  }

  const handleSelectAll = () => {
    const allImageIds = [
      ...imgBase64.map((_, index) => `base64-${index}`),
      ...images.map((_, index) => `image-${index}`),
    ]
    dispatch(changeProperty({ value: 'selectedImages', label: allImageIds }))
  }

  const handleDeselectAll = () => {
    dispatch(changeProperty({ value: 'selectedImages', label: [] }))
  }

  const handleRemoveSelectedImages = () => {
    const selectedBase64Indexes = selectedImages
      .filter(id => id.startsWith('base64-'))
      .map(id => parseInt(id.replace('base64-', ''), 10))

    const selectedImageIndexes = selectedImages
      .filter(id => id.startsWith('image-'))
      .map(id => parseInt(id.replace('image-', ''), 10))

    dispatch(
      changeProperty({
        value: 'images',
        label: images.filter((_, index) => !selectedImageIndexes.includes(index)),
      }),
    )

    dispatch(
      changeProperty({
        value: 'file',
        label: file.filter((_, index) => !selectedImageIndexes.includes(index)),
      }),
    )

    dispatch(
      changeProperty({
        value: 'imgBase64',
        label: imgBase64.filter((_, index) => !selectedBase64Indexes.includes(index)),
      }),
    )

    dispatch(
      changeProperty({
        value: 'fileBase64',
        label: fileBase64.filter((_, index) => !selectedBase64Indexes.includes(index)),
      }),
    )

    dispatch(changeProperty({ value: 'selectedImages', label: [] }))
  }

  const handleSaveFile = useCallback(async () => {
    if (images.length === 0) return
    setLoading(true)
    const fileDetails = []
    const uploadFile = async (file, index) => {
      const formData = new FormData()
      formData.append('file', file)
      formData.append('type', 'protocols')

      const { status, data } = await R.addFiles(formData)

      if (status === 201) {
        if (data.id) {
          fileDetails.push({ id: data.id, serial: index })
        }
      }
    }

    for (let i = 0; i < images.length; i++) {
      await uploadFile(images[i], i)
    }

    dispatch(changeProperty({ value: 'file', label: fileDetails }))

    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }, [images, dispatch])

  useEffect(() => {
    handleSaveFile()
  }, [images, handleSaveFile])

  const handleDrop = event => {
    event.preventDefault()
    event.stopPropagation()

    const files = Array.from(event.dataTransfer.files)
    const remainingSlots = maxNumber - totalImages

    if (files.length > remainingSlots) {
      const addedFiles = files.slice(0, remainingSlots)
      const ignoredFilesCount = files.length - remainingSlots

      dispatch(changeProperty({ value: 'images', label: [...images, ...addedFiles] }))

      dispatch(
        setMessage(
          'Максимальное количество изображений — 10. Изображения, превышающие этот лимит, добавляться не будут.',
        ),
      )
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
    } else {
      dispatch(changeProperty({ value: 'images', label: [...images, ...files] }))
    }
  }

  const handleDragOver = event => {
    event.preventDefault()
    event.stopPropagation()
  }

  return (
    <React.Fragment>
      {showTitle && <h2 style={{ marginBottom: '0px' }}>Дополнительная информация</h2>}
      <div
        style={{
          width: '100%',
          marginBottom: '0px',
          marginTop: '18px',
        }}
      >
        <Input
          type={'text'}
          notRequired={true}
          multiline={false}
          placeholder={'Введите текст сообщения...'}
          label={'Сопроводительный комментарий (необязательно)'}
          value={comment}
          actions={{
            change: handleCommentChange,
          }}
          isDisabled={isTrusted}
        />
      </div>

      <div>
        <ImageUploading
          multiple
          value={images.map(file => ({ file }))}
          onChange={onChangeImage}
          maxFileSize={5242880}
        >
          {({ onImageUpload, onImageUpdate }) => (
            <div className='upload__image-wrapper' onDrop={handleDrop} onDragOver={handleDragOver}>
              {(images.length > 0 || imgBase64.length > 0) && !isTrusted && !loading && (
                <div className='btn--container'>
                  {selectedImages.length > 0 ? (
                    <Button
                      variant='text'
                      onClick={handleDeselectAll}
                      style={{
                        textTransform: 'none',
                        fontSize: '15px',
                        fontFamily: '"Wix Madefor Display", sans-serif',
                        padding: '11px 15px',
                        borderRadius: '12px',
                        color: '#4B5962',
                      }}
                    >
                      Отменить выделение
                    </Button>
                  ) : (
                    <Button
                      variant='text'
                      style={{
                        textTransform: 'none',
                        fontSize: '15px',
                        fontFamily: '"Wix Madefor Display", sans-serif',
                        padding: '11px 15px',
                        borderRadius: '12px',
                        color: '#4B5962',
                      }}
                      onClick={handleSelectAll}
                    >
                      Выделить все
                    </Button>
                  )}
                  <div className='btn--box'>
                    {false && (
                      <Button
                        variant='text'
                        startIcon={<DownloadIcon />}
                        style={{
                          textTransform: 'none',
                          fontSize: '15px',
                          fontFamily: '"Wix Madefor Display", sans-serif',
                          padding: '11px 15px',
                          borderRadius: '12px',
                          color: '#4B5962',
                        }}
                      >
                        Скачать
                      </Button>
                    )}
                    {selectedImages.length > 0 && (
                      <Button
                        variant='text'
                        onClick={handleRemoveSelectedImages}
                        startIcon={<DeleteIcon />}
                        style={{
                          textTransform: 'none',
                          fontSize: '15px',
                          fontFamily: '"Wix Madefor Display", sans-serif',
                          padding: '11px 15px',
                          borderRadius: '12px',
                          color: '#4B5962',
                        }}
                      >
                        Удалить
                      </Button>
                    )}
                  </div>
                </div>
              )}

              {loading && (
                <div
                  className='image-upload-container'
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <CircularProgress />
                </div>
              )}

              <div className='image-container'>
                <div className='image-container'>
                  {imgBase64.map((image, index) => (
                    <div
                      key={`base64-${index}`}
                      className='img--container'
                      style={{ position: 'relative' }}
                    >
                      {!isTrusted && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectedImages.includes(`base64-${index}`)}
                              onChange={() => handleCheckboxChange(index, true)}
                              style={{
                                position: 'absolute',
                                left: 0,
                                top: 0,
                                background: 'white',
                                margin: '10px',
                                borderRadius: '12px',
                              }}
                            />
                          }
                        />
                      )}
                      <img
                        src={image.path}
                        alt='Фото протокола'
                        className='img--box'
                        onClick={() => handleImageClick(image.path)}
                      />
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Tooltip title='Увеличить изображение' placement='top-start'>
                          <IconButton aria-label='zoom'>
                            <SearchIcon onClick={() => handleImageClick(image.path)} />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                  ))}
                </div>

                {images.length > 0 && !loading && (
                  <div className='image-container'>
                    {images.map((image, index) => (
                      <div
                        key={`image-${index}`}
                        className='img--container'
                        style={{ position: 'relative' }}
                      >
                        {!isTrusted && (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={selectedImages.includes(`image-${index}`)}
                                onChange={() => handleCheckboxChange(index, false)}
                                style={{
                                  position: 'absolute',
                                  left: 0,
                                  top: 0,
                                  background: 'white',
                                  margin: '10px',
                                  borderRadius: '12px',
                                }}
                              />
                            }
                          />
                        )}
                        <img
                          src={URL.createObjectURL(image)}
                          alt='Фото протокола'
                          className='img--box'
                          onClick={() => handleImageClick(URL.createObjectURL(image))}
                        />
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <Tooltip title='Заменить изображение' placement='top-start'>
                            <IconButton aria-label='replace' onClick={() => onImageUpdate(index)}>
                              <Replace />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title='Увеличить изображение' placement='top-start'>
                            <IconButton
                              aria-label='zoom'
                              onClick={() => handleImageClick(URL.createObjectURL(image))}
                            >
                              <SearchIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              {!isTrusted && totalImages < count && !loading && (
                <div
                  className='btn_container'
                  style={{
                    gap: '16px',
                    flexWrap: 'wrap',
                    justifyContent: 'space-around',
                  }}
                >
                  <div className='image-upload-container'>
                    <div
                      style={{
                        display: 'flex',
                        flexFlow: 'column',
                        alignItems: 'center',
                        padding: width > 1050 ? '0px' : '0px 14px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '10px',
                          marginTop: '40px',
                        }}
                      >
                        {width > 1050 && <DownloadIcon />}
                        <h4
                          style={{
                            display: 'block',
                            textAlign: width > 1050 ? 'left' : 'center',
                            fontWeight: '400',
                          }}
                        >
                          Чтобы загрузить изображения, перетащите их сюда или нажмите
                          <span
                            style={{
                              color: '#0084E2',
                              marginLeft: '5px',
                              cursor: 'pointer',
                            }}
                            onClick={onImageUpload}
                          >
                            здесь
                          </span>
                        </h4>
                      </div>
                      <span
                        style={{
                          display: 'block',
                          color: 'gray',
                          marginTop: '14px',
                          opacity: 0.8,
                          lineHeight: '24px',
                          textAlign: width > 1050 ? 'left' : 'center',
                        }}
                      >
                        Не более {count} изображений размером до 10 МБ
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </ImageUploading>
      </div>
      <ImageViewer />
    </React.Fragment>
  )
}

export default ImageUpload
