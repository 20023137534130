import { createSlice } from '@reduxjs/toolkit'
import { createMethodology } from '../../store.interface'

const initialState = createMethodology

const gost8101222Reducer = createSlice({
  name: 'gost8101222Reducer',
  initialState,
  reducers: {
    changeProperty: (state, action) => {
      state[action.payload.value] = action.payload.label
    },
    setDefaultState: state => {
      Object.assign(state, initialState)
    },
  },
})

export const { changeProperty, setDefaultState } = gost8101222Reducer.actions
export default gost8101222Reducer.reducer
