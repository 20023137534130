// ----------------------------------------------------------------
/* eslint-disable @typescript-eslint/no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Button from '../../../../../../ui/button/index.tsx'
import MeterData from '../../../views/MeterData/MeterWater/MeterData.jsx'
import DescriptionType from '../components/DescriptionType.jsx'
import Customer from '../../../views/Customer/Customer.jsx'
import Accepted from '../../../views/Accepted/Accepted.jsx'
import Conditions from '../components/ConditionsAdd.jsx'
import Indications from '../components/IndicationsAdd.jsx'
import TotalAccepted from '../../../views/TotalAccepted/TotalAccepted.jsx'
import ProtocolDate from '../../../views/ProtocolDate/ProtocolDate.jsx'
import DataTransfer from '../../../views/DataTransfer/DataTransfer.jsx'
import VerificationMark from '../../../views/VerificationMark/VerificationMark.jsx'
import VerificationToolsUsed from '../components/VerificationToolsUsed.jsx'
import { PageContainer, BoxContainer } from '../style.ts'
import dayjs, { Dayjs } from 'dayjs'
import { changeProperty } from '../state.js'

interface SelectedOption {
  value: string | null
  label: string
}

const AddProtocol = () => {
  const dispatch = useDispatch()

  const [isDisabledBtn, setIsDisabledBtn] = useState(false)
  const isTrusted = useSelector((state: any) => state.headerReducer.isTrusted)

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const meterId = useSelector((state: any) => state.gost8101222Reducer.meterId)
  const modifications = useSelector((state: any) => state.gost8101222Reducer.modifications)
  const selectedMod = useSelector((state: any) => state.gost8101222Reducer.selectedMod)
  const selectedAdditionals = useSelector(
    (state: any) => state.gost8101222Reducer.selectedAdditionals,
  )
  const defaultData = useSelector((state: any) => state.gost8101222Reducer.defaultData)
  const mpiColdAdditionals = useSelector(
    (state: any) => state.gost8101222Reducer.mpiColdAdditionals,
  )
  const mpiHotAdditionals = useSelector((state: any) => state.gost8101222Reducer.mpiHotAdditionals)
  const valueMod = useSelector((state: any) => state.gost8101222Reducer.valueMod)
  const valueAdditional = useSelector((state: any) => state.gost8101222Reducer.valueAdditional)
  const valueAnotherMod = useSelector((state: any) => state.gost8101222Reducer.valueAnotherMod)
  const is_custom_modification = useSelector(
    (state: any) => state.gost8101222Reducer.is_custom_modification,
  )
  const meterFactoryNumber = useSelector(
    (state: any) => state.gost8101222Reducer.meterFactoryNumber,
  )
  const meterYear = useSelector((state: any) => state.gost8101222Reducer.meterYear)

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const customerName = useSelector((state: any) => state.gost8101222Reducer.customerName)
  const customerNameType = useSelector((state: any) => state.gost8101222Reducer.customerNameType)
  const customerAddress = useSelector((state: any) => state.gost8101222Reducer.customerAddress)
  const customerFullName = useSelector((state: any) => state.gost8101222Reducer.customerFullName)
  const customerPhoneNumber = useSelector(
    (state: any) => state.gost8101222Reducer.customerPhoneNumber,
  )
  const agreementNumber = useSelector((state: any) => state.gost8101222Reducer.agreementNumber)

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const meterType = useSelector((state: any) => state.gost8101222Reducer.meterType)

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const isMechanicalAccepted = useSelector(
    (state: any) => state.gost8101222Reducer.isMechanicalAccepted,
  )
  const isTightnessAccepted = useSelector(
    (state: any) => state.gost8101222Reducer.isTightnessAccepted,
  )

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const isMarkPassport = useSelector((state: any) => state.gost8101222Reducer.isMarkPassport)
  const isMarkSi = useSelector((state: any) => state.gost8101222Reducer.isMarkSi)
  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const verificationDate = useSelector((state: any) => state.gost8101222Reducer.verificationDate)
  const nextVerificationDate = useSelector(
    (state: any) => state.gost8101222Reducer.nextVerificationDate,
  )
  const nextVerificationDateAutoGenerate = useSelector(
    (state: any) => state.gost8101222Reducer.nextVerificationDateAutoGenerate,
  )

  // -------------------------------------------------------------
  // -------------------------------------------------------------
  const doChecked = useSelector((state: any) => state.gost8101222Reducer.doChecked)

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const isTotalAccepted = useSelector((state: any) => state.gost8101222Reducer.isTotalAccepted)
  const indicationPass = useSelector((state: any) => state.gost8101222Reducer.indicationsPass)
  const indications = useSelector((state: any) => state.gost8101222Reducer.indications)
  const conditions = useSelector((state: any) => state.gost8101222Reducer.conditions)

  const externalInspection = [
    'При внешнем осмотре визуально определяют возможность считывания показаний со счетчика, проверяют целостность счетного механизма счетчика (при наличии счетного механизма у счетчика), отсутствие механических повреждений и каплепадений или течи воды на корпусе счетчика, соответствие комплектности счетчика описанию типа, наличие знака утверждения типа на счетчике в месте, установленном в описании типа данного счетчика (при указании места нанесения знака утверждения типа на счетчике в описании типа).',
    <>
      Результат проверки внешнего осмотра счетчика
      <span style={{ color: '#132532', fontWeight: 'bold' }}>считают положительным</span> в том
      случае, если
      <span>подтверждается возможность визуально считывать показания</span> счетчика,
      <span style={{ color: '#132532', fontWeight: 'bold' }}>
        отсутствие нарушений целостности счетного механизма
      </span>
      счетчика (при наличии счетного механизма у счетчика),
      <span style={{ color: '#132532', fontWeight: 'bold' }}>
        механических повреждений на корпусе
      </span>
      счетчика,
      <span style={{ color: '#132532', fontWeight: 'bold' }}>влияющих на работоспособность</span>
      счетчика.
    </>,
    <>
      Результат проверки внешнего осмотра счетчика
      <span style={{ color: '#132532', fontWeight: 'bold' }}>считают отрицательным</span> в том
      случае,
      <span style={{ color: '#132532', fontWeight: 'bold' }}>
        если не подтверждается возможность визуально считывать показания
      </span>
      счетчика, или <span>нарушена целостность счетного механизма</span> счетчика (при наличии
      счетного механизма у счетчика), или
      <span style={{ color: '#132532', fontWeight: 'bold' }}>
        присутствует механическое повреждение на корпусе
      </span>
      счетчика,
      <span style={{ color: '#132532', fontWeight: 'bold' }}>влияющее на работоспособность</span>
      счетчика. При отрицательном результате проверки внешнего осмотра результат поверки счетчика
      считают отрицательным и прекращают дальнейшую поверку счетчика.
    </>,
  ]

  const softwareConfirmation = [
    'Проверку идентификационных данных ПО осуществляют на соответствие описанию типа при наличии ПО в счетчике.',
    'Для определения идентификационных данных ПО необходимо:',
    '- Согласно эксплуатационным документам счетчика получить доступ к идентификационным данным ПО;',
    '- Считать идентификационные данные ПО.',
    <>
      Результат подтверждения соответствия ПО
      <span style={{ color: '#132532', fontWeight: 'bold' }}>считают положительным, если</span>
      полученные идентификационные данные ПО счетчика [идентификационное наименование ПО, номер
      версии (идентификационный номер ПО), цифровой идентификатор ПО]
      <span style={{ color: '#132532', fontWeight: 'bold' }}>соответствуют </span>
      идентификационным данным,
      <span style={{ color: '#132532', fontWeight: 'bold' }}>
        указанным в разделе «Программное обеспечение», описания типа
      </span>
      на поверяемый счетчик.
    </>,
    <>
      Результат подтверждения соответствия ПО
      <span style={{ color: '#132532', fontWeight: 'bold' }}>считают отрицательным, если</span>
      полученные идентификационные
      <span style={{ color: '#132532', fontWeight: 'bold' }}>данные ПО счетчика</span>
      [идентификационное наименование ПО, номер версии (идентификационный номер ПО), цифровой
      идентификатор ПО]
      <span style={{ color: '#132532', fontWeight: 'bold' }}>не соответствуют</span>
      идентификационным данным,
      <span style={{ color: '#132532', fontWeight: 'bold' }}>
        указанным в разделе «Программное обеспечение», описания типа
      </span>
      на поверяемый счетчик. При отрицательном результате подтверждения соответствия ПО результат
      поверки счетчика считают отрицательным и прекращают дальнейшую поверку счетчика.
    </>,
  ]

  const testing = [
    'При проведении опробования осуществляют операцию проверки герметичности соединений. При проверке герметичности соединений пропускают воду через счетчик при наибольшем расходе (при полностью открытых запорных устройствах) с целью удаления воздуха из системы в течение не менее 10 с. Проверяют герметичность соединений счетчика с трубопроводом и эталоном. Проверку проводят давлением воды в системе при открытом запорном устройстве перед и после счетчика и при закрытом запорном устройстве после эталона',
    <>
      Результаты проверки
      <span style={{ color: '#132532', fontWeight: 'bold' }}>считают положительными</span> в том
      случае,
      <span style={{ color: '#132532', fontWeight: 'bold' }}>
        если после выдержки в течение 1 мин
      </span>
      в местах соединения гидравлического тракта (соединения счетчика с трубопроводом и эталоном)
      <span style={{ color: '#132532', fontWeight: 'bold' }}>
        не наблюдается каплепадений или течи
      </span>
      воды.
    </>,
    <>
      <span style={{ color: '#132532', fontWeight: 'bold' }}>
        В случае наблюдения каплепадения или течи
      </span>
      воды в местах соединения гидравлического тракта (соединения счетчика с трубопроводом и
      эталоном)
      <span style={{ color: '#132532', fontWeight: 'bold' }}>осуществляют их устранение</span>
    </>,
  ]

  const capitalizeFirstLetter = (input: string): string => {
    return input.charAt(0).toUpperCase() + input.slice(1)
  }

  const changeName = (param: string) => {
    const newValue = capitalizeFirstLetter(param)
    dispatch(changeProperty({ value: 'customerName', label: newValue }))
  }

  const changeNameType = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeProperty({ value: 'customerNameType', label: event.target.value }))
    dispatch(changeProperty({ value: 'customerName', label: event.target.value }))
  }

  const changeAddress = (selectedOption: SelectedOption) => {
    dispatch(
      changeProperty({
        value: 'customerAddress',
        label: selectedOption ? selectedOption.value : null,
      }),
    )
  }

  const changeFullName = (param: string) => {
    const newValue = capitalizeFirstLetter(param)
    dispatch(changeProperty({ value: 'customerFullName', label: newValue }))
  }

  const formatPhoneNumber = (input: string): void => {
    const phoneNumber = input.replace(/\D/g, '')
    let formattedPhoneNumber = ''

    if (phoneNumber.length >= 1) {
      formattedPhoneNumber = `+7`
    }
    if (phoneNumber.length > 1) {
      formattedPhoneNumber += ` (${phoneNumber.slice(1, 4)}`
    }
    if (phoneNumber.length > 4) {
      formattedPhoneNumber += `) ${phoneNumber.slice(4, 7)}`
    }
    if (phoneNumber.length > 7) {
      formattedPhoneNumber += `-${phoneNumber.slice(7, 9)}`
    }
    if (phoneNumber.length > 9) {
      formattedPhoneNumber += `-${phoneNumber.slice(9, 11)}`
    }

    dispatch(
      changeProperty({
        value: 'customerPhoneNumber',
        label: formattedPhoneNumber,
      }),
    )
  }

  const changePhone = (param: string) => {
    formatPhoneNumber(param)
  }

  const handleAgreementChange = (param: string) => {
    dispatch(changeProperty({ value: 'agreementNumber', label: param }))
  }

  const changeMechanical = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value === 'true'
    dispatch(
      changeProperty({
        value: 'isMechanicalAccepted',
        label: newValue,
      }),
    )
  }

  const changeTightness = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value === 'true'
    dispatch(
      changeProperty({
        value: 'isTightnessAccepted',
        label: newValue,
      }),
    )
  }

  const handleMarkPassport = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      changeProperty({
        value: 'isMarkPassport',
        label: event.target.checked,
      }),
    )
  }

  const handleMarkSi = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      changeProperty({
        value: 'isMarkSi',
        label: event.target.checked,
      }),
    )
  }

  const handleDateChange = (date: Dayjs | null): void => {
    if (date) {
      dispatch(
        changeProperty({
          value: 'verificationDate',
          label: date.format('YYYY-MM-DD HH:mm:ss'),
        }),
      )
    }
  }

  const handleNextDateChange = (date: Dayjs | null): void => {
    if (date) {
      dispatch(
        changeProperty({
          value: 'nextVerificationDate',
          label: date.format('YYYY-MM-DD HH:mm:ss'),
        }),
      )
    }
  }

  const handleDoChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      changeProperty({
        value: 'doChecked',
        label: event.target.checked,
      }),
    )
  }

  useEffect(() => {
    if (defaultData && meterType && verificationDate && dayjs(verificationDate).isValid()) {
      let nextVerificationYears: number | undefined

      if (meterType === 'hot') {
        nextVerificationYears = mpiHotAdditionals
          ? parseFloat(mpiHotAdditionals)
          : parseFloat(defaultData.mpi_hot)
      } else if (meterType === 'cold') {
        nextVerificationYears = mpiColdAdditionals
          ? parseFloat(mpiColdAdditionals)
          : parseFloat(defaultData.mpi_cold)
      }

      if (nextVerificationYears) {
        const nextVerificationDateCalculated = dayjs(verificationDate)
          .add(nextVerificationYears, 'month')
          .subtract(1, 'day')
          .format('YYYY-MM-DD HH:mm:ss')

        dispatch(
          changeProperty({
            value: 'nextVerificationDateAutoGenerate',
            label: nextVerificationDateCalculated,
          }),
        )
      }
    } else {
      dispatch(
        changeProperty({
          value: 'nextVerificationDateAutoGenerate',
          label: '',
        }),
      )
    }
  }, [defaultData, meterType, verificationDate, mpiColdAdditionals, mpiHotAdditionals, dispatch])

  useEffect(() => {
    const totalAccepted =
      isMechanicalAccepted && isTightnessAccepted && !indicationPass.includes(false)

    dispatch(changeProperty({ value: 'isTotalAccepted', label: totalAccepted }))
  }, [isMechanicalAccepted, isTightnessAccepted, indicationPass, dispatch])

  return (
    <PageContainer>
      <BoxContainer>
        <VerificationToolsUsed />
      </BoxContainer>

      <BoxContainer>
        <Customer
          valueName={customerName}
          changeName={changeName}
          valueNameType={customerNameType}
          changeNameType={changeNameType}
          valueAddress={customerAddress}
          changeAddress={changeAddress}
          valueFullName={customerFullName}
          changeFullName={changeFullName}
          valuePhone={customerPhoneNumber}
          changePhone={changePhone}
          valueAgreement={agreementNumber}
          changeAgreement={handleAgreementChange}
        />
      </BoxContainer>

      <BoxContainer>
        <MeterData
          meterFactoryNumber={meterFactoryNumber}
          meterYear={meterYear}
          meterId={meterId}
          modifications={modifications}
          selectedMod={selectedMod}
          selectedAdditionals={selectedAdditionals}
          valueMod={valueMod}
          valueAdditional={valueAdditional}
          valueAnotherMod={valueAnotherMod}
          is_custom_modification={is_custom_modification}
          changeMeter={changeProperty}
          defaultData={defaultData}
        />
      </BoxContainer>

      <BoxContainer>
        <DescriptionType />
      </BoxContainer>

      <BoxContainer>
        <Accepted
          title={'Внешний осмотр'}
          description={externalInspection}
          label={'Соответствие пункту 4.3.7.1  ГОСТ 8.1012-2022 (с изменением №1)'}
          value={isMechanicalAccepted}
          onChange={changeMechanical}
        />
      </BoxContainer>

      <BoxContainer>
        <Accepted
          title={'Подтверждение соответствия программного обеспечения'}
          description={softwareConfirmation}
          label={'Соответствие пункту 4.2.7.2  ГОСТ 8.1012-2022 (с изменением №1)'}
        />
      </BoxContainer>

      <BoxContainer>
        <Accepted
          title={'Опробование'}
          description={testing}
          label={'Соответствие пункту 4.3.7.3 ГОСТ 8.1012-2022 (с изменением №1)'}
          value={isTightnessAccepted}
          onChange={changeTightness}
        />
      </BoxContainer>

      <BoxContainer>
        <Conditions />
      </BoxContainer>

      <BoxContainer>
        <Indications />
      </BoxContainer>

      <TotalAccepted value={isTotalAccepted} />

      <BoxContainer>
        <VerificationMark
          isTotalAccepted={isTotalAccepted}
          valuePassport={isMarkPassport}
          onChangePassport={handleMarkPassport}
          valueSi={isMarkSi}
          onChangeSi={handleMarkSi}
        />
      </BoxContainer>

      <BoxContainer>
        <ProtocolDate
          currentDate={verificationDate}
          changeCurrentDate={handleDateChange}
          nextDate={nextVerificationDate}
          nextDateAutoGenerate={nextVerificationDateAutoGenerate}
          changeNextDate={handleNextDateChange}
        />
      </BoxContainer>

      {!!isTrusted && (
        <BoxContainer>
          <DataTransfer doChecked={doChecked} handleDoChecked={handleDoChecked} />
        </BoxContainer>
      )}

      <Button label='Сохранить протокол' disabled={isDisabledBtn} color='primary' fullWidth />
    </PageContainer>
  )
}

export default AddProtocol
