import React, { useState } from 'react'
import RadioButton from '../../../../../ui/radio-button/index.jsx'
import { Typography, Collapse } from '@mui/material'
import { Wrapper } from '../../styles.ts'
import theme from '../../../../../utils/theme.ts'

const Accepted = props => {
  const { title, description, value, onChange, label } = props
  const [isExpanded, setIsExpanded] = useState(false)

  const descriptionLines = Array.isArray(description) ? description : description.split('\n')

  const handleToggle = () => {
    setIsExpanded(prev => !prev)
  }

  const shouldShowExpandButton = descriptionLines.length > 1

  return (
    <Wrapper>
      <Typography variant='h2'>{title}</Typography>

      <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
        {descriptionLines.slice(0, 1).map((line, index) => (
          <Typography variant='subtitle2' key={index}>
            {line}
          </Typography>
        ))}

        <Collapse in={isExpanded} timeout={500} unmountOnExit>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            {descriptionLines.slice(1).map((line, index) => (
              <Typography key={index + 1} variant='subtitle2'>
                {line}
              </Typography>
            ))}
          </div>
        </Collapse>

        {shouldShowExpandButton && (
          <Typography
            variant='subtitle2'
            style={{ color: theme.palette.text.secondary, cursor: 'pointer' }}
            onClick={handleToggle}
          >
            {isExpanded ? 'Скрыть' : 'Подробнее'}
          </Typography>
        )}
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
        <Typography variant='body2'>{label}</Typography>
        <RadioButton
          value={value}
          onChange={onChange}
          trueLabel={'Соответствует'}
          falseLabel={'Не соответствует'}
          trueValue={true}
          falseValue={false}
          threeBtn={title === 'Подтверждение соответствия программного обеспечения' ? true : false}
        />
      </div>
    </Wrapper>
  )
}

export default Accepted
