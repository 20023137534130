// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Typography, useMediaQuery, Collapse, Fade } from '@mui/material'
import AccordionComponent from '../../components/LocalElements/Accordion/Accordion'
import FilterCompany from '../../components/LocalElements/Filter/FilterCompany'
import R from '../../../services/app/client-server/request.service.js'
import Table from '../../components/LocalElements/Table/Table'
import { refreshMasterArshin, refreshCases } from '../../../store/slices/app/controlers/updater'
import { setName } from '../../../store/slices/app/controlers/pageName'
import BasicTabs from '../../components/LocalElements/TabsMenu/TabsMenu'
import StarIcon from '../../../img/icon/StarIcon'
import {
  setOpen,
  setTitle,
  setChildren,
  setConfirmAction,
  setBA,
  resetState,
} from '../../../store/slices/app/comps/modal'
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from '../../../store/slices/app/comps/snackbar'
import theme from '../../../utils/theme.ts'
import PencilIcon from '../../../img/icon/PencilIcon'
import DeleteIcon from '../../../img/icon/DeleteIcon'
import InfoCircle from '../../../img/icon/InfoCircle'
import Tooltip from '../../../ui/tooltip/index'

import { refreshIssueCase } from '../../../store/slices/app/controlers/updater'

const Cases = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isMobile = useMediaQuery(theme.breakpoints.up('lg'))

  const userRole = useSelector(state => state.headerReducer.role)
  const data = useSelector(state => state.casesReducer.casesList)

  const masterArshinData = useSelector(state => state.masterArshinReducer.masterArshinList)
  const companyIdSuperAdmin = useSelector(state => state.companyIdReducer.companyId)
  const companyIdUser = useSelector(state => state.headerReducer.companyId)
  const companyId = userRole === 'super' ? companyIdSuperAdmin : companyIdUser
  const [viewVariant, setViewVariant] = useState(0)

  const changeViewVariant = param => {
    setViewVariant(param)
    localStorage.setItem('viewVariant', param)
  }

  const handleCasesAdd = () => {
    navigate('/metriva/cases/create')
  }
  const handleCasesOfficialUserAdd = () => {
    navigate('/metriva/cases/create_official_user')
  }
  const handleRowClick = param => {
    navigate(`/metriva/cases/${param.row.id}`)
  }
  const handleEditCase = caseId => {
    navigate(`/metriva/cases/${caseId}/edit`)
  }
  const handleEditMaster = masterId => {
    navigate(`/metriva/cases/${masterId}/edit_master`)
  }

  const handleMainCase = async caseId => {
    const { status, data } = await R.mainCases(caseId)
    if (status === 200) {
      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Статус комплекта изменен'))
      dispatch(setOpen(false))
      dispatch(refreshCases())
    } else {
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage(data.message))
    }
  }

  const handleDelete = async caseId => {
    const { status, data } = await R.deleteCase(caseId)
    if (status === 200) {
      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Комплект СП удален'))
      dispatch(setOpen(false))
      dispatch(refreshCases())
      dispatch(resetState())
      dispatch(refreshIssueCase())
    } else {
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage(data.message))
    }
  }

  const handleDeleteMaster = async masterId => {
    const { status, data } = await R.deleteMaster(masterId)
    if (status === 200) {
      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Мастер удален'))
      dispatch(setOpen(false))
      dispatch(refreshMasterArshin())
      dispatch(resetState())
    } else {
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage(data.message))
    }
  }

  let colData = [
    { field: 'innerNumber', headerName: 'Внутренний номер СП', type: 'string' },
    {
      field: 'master',
      headerNameAlt: 'Официальный пользователь',
      headerName: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          Официальный пользователь
          {(userRole === 'metrologist' || userRole === 'brigadier') && (
            <Tooltip
              title={
                'Квалифицированный сотрудник, проводящий поверочные работы в определённых областях измерения, данные о котором передаются в ФГИС «Аршин» и ФСА, а также используются для отчётных журналов.'
              }
              style={{ marginLeft: '7px' }}
            >
              <InfoCircle />
            </Tooltip>
          )}
        </div>
      ),
      type: 'string',
    },
    {
      field: 'isUsable',
      headerName: 'Использование комплекта СП',
      disableFilter: true,
      renderCell: params => {
        const { isUsable } = params.row

        if (isUsable) {
          return (
            <Typography variant='subtitle2' sx={{ color: theme.palette.secondary.contrastText }}>
              Доступно
            </Typography>
          )
        } else {
          return (
            <Typography variant='subtitle2' sx={{ color: theme.palette.background.error }}>
              Недоступно
            </Typography>
          )
        }
      },
    },
    { field: 'measurement', headerName: 'Область измерения', type: 'string' },
    { field: 'methods', headerName: 'Методика поверки', type: 'string' },
    { field: 'typeMethods', headerName: 'Способ поверки', type: 'string' },
  ]

  if (userRole === 'brigadier' || userRole === 'metrologist') {
    colData.splice(6, 0, {
      field: 'status',
      headerName: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          Основной комплект
          <Tooltip
            title='Данные основного комплекта СП будут использованы для автозаполнения полей в момент создания нового протокола'
            style={{ marginLeft: '7px' }}
          >
            <InfoCircle />
          </Tooltip>
        </div>
      ),
      renderCell: params => {
        const { status, id } = params.row
        const handleClick = async event => {
          event.stopPropagation()
          await handleMainCase(id)
        }
        if (status === 'main') {
          return (
            <Tooltip title='Отменить назначение'>
              <div style={{ cursor: 'pointer' }}>
                <StarIcon onClick={handleClick} color='#FF8E26' />
              </div>
            </Tooltip>
          )
        } else if (status === 'not-main') {
          return (
            <Tooltip title='Назначить основным комплектом СП'>
              <div style={{ cursor: 'pointer' }}>
                <StarIcon onClick={handleClick} />
              </div>
            </Tooltip>
          )
        } else {
          const handleDisabledIconClick = event => {
            event.stopPropagation()
          }
          return (
            <Tooltip title='Основной комплект СП уже назначен, для его изменения отмените текущее назначение'>
              <div style={{ cursor: 'default' }}>
                <StarIcon onClick={handleDisabledIconClick} color='#BDC1C4' />
              </div>
            </Tooltip>
          )
        }
      },
    })
  }

  if (userRole === 'super' || userRole === 'admin') {
    colData.splice(6, 0, {
      field: 'actions',
      type: 'actions',
      headerName: 'Действия',
      getActions: item => {
        const localItem = item

        return [
          <div
            style={{
              display: 'flex',
              flexFlow: 'row',
              alignItems: 'center',
              gap: '16px',
            }}
          >
            <Tooltip title='Редактировать Комплект СП'>
              <div style={{ cursor: 'pointer' }} onClick={() => handleEditCase(localItem.id)}>
                <PencilIcon />
              </div>
            </Tooltip>

            <Tooltip title='Удалить Комплект СП'>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  dispatch(setOpen(true))
                  dispatch(setTitle('Вы действительно хотите удалить комплект СП?'))
                  dispatch(setChildren('Отменить действие будет уже невозможно.'))
                  dispatch(setConfirmAction(() => handleDelete(localItem.id)))
                }}
              >
                <DeleteIcon />
              </div>
            </Tooltip>
          </div>,
        ]
      },
    })
  }

  useEffect(() => {
    dispatch(setName('Средства проверки'))
  }, [])

  const noRowsText =
    viewVariant === 0
      ? 'Данные о доступных комплектах СП отсутствуют'
      : 'Данные об официальных пользователях СП отсутствуют'

  return (
    <main className='main'>
      {userRole === 'super' && (
        <div style={{ width: isMobile ? '605px' : '100%' }}>
          <FilterCompany />
        </div>
      )}
      <Collapse in={!companyId} timeout={500} unmountOnExit>
        <div className='page_null'>
          <Fade in={!companyId} timeout={500}>
            <Typography variant='body1'>Для просмотра данных выберите компанию</Typography>
          </Fade>
        </div>
      </Collapse>
      <Collapse in={!!companyId} timeout={500} unmountOnExit>
        <Fade in={!!companyId} timeout={500}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {(userRole === 'super' || userRole === 'admin') && (
              <div style={{ margin: viewVariant === 1 ? '10px 0 12px' : '10px 0 0px' }}>
                <BasicTabs
                  viewVariant={viewVariant}
                  callback={changeViewVariant}
                  tabs={[{ label: 'Комплекты СП' }, { label: 'Официальные пользователи СП' }]}
                />
              </div>
            )}

            {viewVariant === 0 ? (
              <React.Fragment>
                <Table
                  colData={colData}
                  rowData={data.map(item => ({
                    id: item?.id,
                    innerNumber: item?.inner_name,
                    master: item?.arshin_master_fullname,
                    isUsable: item?.is_usable,
                    measurement: item?.measurement,
                    methods: item?.method,
                    status: item?.is_main_status,
                    typeMethods: item?.verification_methods,
                  }))}
                  showToolbar={true}
                  rowCallback={handleRowClick}
                  addButtonCallback={handleCasesAdd}
                  noRowsText={noRowsText}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <AccordionComponent
                  title='Краткое описание'
                  dataType='custom'
                  backgroundColor='white'
                  data={
                    <span>
                      Официальные пользователи СП — это ответственные лица, которые обладают
                      квалификацией для проведения поверочных работ в рамках определённых областей
                      измерения. Информация о данных пользователях передаются в ФГИС «Аршин» и ФСА,
                      а также используются для создания и заполнения отчётных журналов.
                    </span>
                  }
                  isExpanded={true}
                />
                <Table
                  colData={[
                    {
                      field: 'name',
                      headerName: 'ФИО',
                      type: 'string',
                    },
                    {
                      field: 'role',
                      headerName: 'Должность в компании',
                      type: 'string',
                    },
                    {
                      field: 'attribution',
                      headerName: 'Приписанные комплекты, шт',
                      headerAlign: 'left',
                      type: 'number',
                      align: 'center',
                    },
                    {
                      field: 'measurement',
                      headerNameAlt: 'Рабочая область измерения',
                      headerName: (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          Рабочая область измерения
                          <Tooltip
                            title={
                              'Области измерения, в рамках которых пользователь имеет официальное право осуществлять поверочную деятельность'
                            }
                            style={{ marginLeft: '7px' }}
                          >
                            <InfoCircle />
                          </Tooltip>
                        </div>
                      ),
                      type: 'string',
                    },
                    {
                      field: 'actions',
                      type: 'actions',
                      headerName: 'Действия',
                      getActions: item => {
                        const localItem = item

                        return [
                          <div
                            style={{
                              display: 'flex',
                              flexFlow: 'row',
                              alignItems: 'center',
                              gap: '16px',
                            }}
                          >
                            <Tooltip title='Редактировать данные пользователя СП'>
                              <div
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleEditMaster(localItem.id)}
                              >
                                <PencilIcon />
                              </div>
                            </Tooltip>
                            {localItem.row.attribution === 0 ? (
                              <Tooltip title='Удалить пользователя СП'>
                                <div
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    dispatch(setOpen(true))
                                    dispatch(setTitle('Удаление официального пользователя СП'))
                                    dispatch(
                                      setChildren(
                                        'Вы действительно хотите удалить выбранного пользователя СП? Обратите внимание, после удаления данные о нём будут стёрты из системы окончательно.',
                                      ),
                                    )
                                    dispatch(setBA('Удалить'))
                                    dispatch(
                                      setConfirmAction(() => handleDeleteMaster(localItem.id)),
                                    )
                                  }}
                                >
                                  <DeleteIcon />
                                </div>
                              </Tooltip>
                            ) : (
                              <Tooltip title='Удаление недоступно т.к. пользователь указан поверителем комплектов СП'>
                                <DeleteIcon color='#BDC1C4' />
                              </Tooltip>
                            )}
                          </div>,
                        ]
                      },
                    },
                  ]}
                  rowData={masterArshinData.map(item => ({
                    id: item?.id,
                    name: item?.fullname,
                    role: item?.post,
                    attribution: item?.cases_count,
                    measurement: item?.measurements,
                  }))}
                  showToolbar={true}
                  noRowsText={noRowsText}
                  addButtonCallback={handleCasesOfficialUserAdd}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                />
              </React.Fragment>
            )}
          </div>
        </Fade>
      </Collapse>
    </main>
  )
}

export default Cases
