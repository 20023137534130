// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import DatePicker from '../../LocalElements/DatePicker/DatePicker'
import Measurement from '../../LocalElements/Filter/FilterMeasurement'
import Feedback from '../../LocalElements/Feedback/Feedback'
import R from '../../../../services/app/client-server/request.service.js'
import { changePersonal, resetPersonal } from '../../../../store/slices/app/views/journals'
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from '../../../../store/slices/app/comps/snackbar'
import { refreshPersonalMetrolog } from '../../../../store/slices/app/controlers/updater'
import Input from '../../../../ui/input/index.tsx'
import Button from '../../../../ui/button/index.tsx'
import RadioButton from '../../../../ui/radio-button/index'
import { Typography } from '@mui/material'
import { Wrapper, Frame, Content, ContentFrame, LabelFrame } from '../style'

import usePrompt from '../../../hooks/usePrompt.ts'

const Edit = () => {
  const { journal_id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userRole = useSelector(state => state.headerReducer.role)
  const show = useSelector(state => state.feedbackReducer.show)

  const [isDisabledBtn, setIsDisabledBtn] = useState(false)
  const [getPrompt, setGetPrompt] = useState(true)

  const companyIdSuperAdmin = useSelector(state => state.companyIdReducer.companyId)
  const companyIdUser = useSelector(state => state.headerReducer.companyId)
  const companyId = userRole === 'super' ? companyIdSuperAdmin : companyIdUser
  const masterArshine = useSelector(state => state.journalReducer.masterArshine)

  const measurement = useSelector(state => state.journalReducer.personal.measurement)
  const numSi = useSelector(state => state.journalReducer.personal.numSi)
  const nameSi = useSelector(state => state.journalReducer.personal.nameSi)
  const typeSi = useSelector(state => state.journalReducer.personal.typeSi)
  const numFactory = useSelector(state => state.journalReducer.personal.numFactory)
  const verificationDate = useSelector(state => state.journalReducer.personal.verificationDate)
  const numNotification = useSelector(state => state.journalReducer.personal.numNotification)
  const numProtocol = useSelector(state => state.journalReducer.personal.numProtocol)

  usePrompt(
    'Вы уверены, что хотите продолжить действие? Все введенные или измененные данные не будут сохранены',
    getPrompt,
  )

  const changeMeasurement = param => {
    dispatch(changePersonal({ value: 'measurement', label: param }))
  }

  const changeNumSi = param => {
    const validInput = param.replace(/[^0-9\- ]/g, '')
    dispatch(changePersonal({ value: 'numSi', label: validInput }))
  }

  const changeNameSi = param => {
    dispatch(changePersonal({ value: 'nameSi', label: param }))
  }

  const changeIsNameSi = event => {
    dispatch(changePersonal({ value: 'nameSi', label: event.target.value }))
  }

  const changeTypeSi = param => {
    dispatch(changePersonal({ value: 'typeSi', label: param }))
  }

  const changeNumFactory = param => {
    dispatch(changePersonal({ value: 'numFactory', label: param }))
  }

  const handleNumNotification = param => {
    dispatch(changePersonal({ value: 'numNotification', label: param }))
  }

  const handleNumProtocol = param => {
    dispatch(changePersonal({ value: 'numProtocol', label: param }))
  }

  const handleVerificationDateChange = date => {
    if (date) {
      dispatch(
        changePersonal({
          value: 'verificationDate',
          label: date.format('YYYY-MM-DD'),
        })
      )
    } else {
      dispatch(changePersonal({ value: 'verificationDate', label: '' }))
    }
  }

  useEffect(() => {
    ;(async () => {
      try {
        const { data: personalData } = await R.getPersonalMetrologItem(
          journal_id,
          companyId,
          masterArshine
        )
        const data = personalData.data

        dispatch(
          changePersonal({
            value: 'measurement',
            label: data?.measurement_id,
          })
        )
        dispatch(
          changePersonal({
            value: 'numSi',
            label: data?.fif_number,
          })
        )

        dispatch(
          changePersonal({
            value: 'nameSi',
            label: data?.customer_arshin_name,
          })
        )

        dispatch(
          changePersonal({
            value: 'typeSi',
            label: data?.name_type_si,
          })
        )

        dispatch(
          changePersonal({
            value: 'numFactory',
            label: data?.meter_factory_number,
          })
        )

        dispatch(
          changePersonal({
            value: 'numNotification',
            label: data?.certificate,
          })
        )

        dispatch(
          changePersonal({
            value: 'numProtocol',
            label: data?.protocol_number,
          })
        )

        dispatch(
          changePersonal({
            value: 'verificationDate',
            label: data?.verification_date,
          })
        )
      } catch (error) {
        console.error(error)
      }
    })()
  }, [journal_id, companyId, masterArshine, dispatch])

  useEffect(() => {
    return () => {
      dispatch(resetPersonal())
    }
  }, [])

  const handleSave = async () => {
    setIsDisabledBtn(true)
    setGetPrompt(false)

    const data = {
      measurement_id: measurement,
      fif_number: numSi,
      customer_arshin_name: nameSi,
      name_type_si: typeSi,
      meter_factory_number: numFactory,
      verification_date: verificationDate,
      certificate: numNotification,
      protocol_number: numProtocol,
    }

    const { status } = await R.putPersonalMetrolog(journal_id, companyId, masterArshine, data)

    if (status === 200) {
      dispatch(setMessage('Журнал изменен.'))
      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
      dispatch(refreshPersonalMetrolog())
      dispatch(resetPersonal())
      navigate('/metriva/journals')
    } else {
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Журнал не изменен. - ошибка валидации'))
      setIsDisabledBtn(false)
      setGetPrompt(true)
    }
  }

  const navigateToProtocols = () => {
    navigate('/metriva/journals')
  }

  return (
    <main className='main'>
      {show ? (
        <Feedback />
      ) : (
        <Wrapper>
          <Frame onClick={navigateToProtocols}>
            <i
              className='fas fa-arrow-left icon_back'
              style={{ width: '30px', height: '30px', marginRight: '6px' }}
            />
            <Typography variant='subtitle2'>Все записи документа</Typography>
          </Frame>

          <Typography variant='h2'>Персональный журнал инженера по метрологии</Typography>

          <Content>
            <Typography variant='h2' style={{ marginBottom: '4px' }}>
              Информация о журнале
            </Typography>
            <ContentFrame style={{ marginBottom: '4px' }}>
              <LabelFrame sx={{ width: '32.8%' }}>
                <Measurement
                  measurementId={measurement}
                  setMeasurementId={changeMeasurement}
                  label={{
                    text: (
                      <div style={{ display: 'flex', alignItems: 'center' }}>Область измерения</div>
                    ),
                    topBg: 'white',
                    bottomBg: 'white',
                  }}
                />
              </LabelFrame>

              <LabelFrame sx={{ width: '32.8%' }}>
                <Input
                  label={'Наименование, тип СИ'}
                  placeholder={'Введите наименование и тип СИ'}
                  type={'text'}
                  value={typeSi}
                  actions={{
                    change: changeTypeSi,
                  }}
                />
              </LabelFrame>

              <LabelFrame sx={{ width: '32.8%' }}>
                <Input
                  label={'№ СИ в госреетсре'}
                  placeholder={'Пример ввода - 349392-93'}
                  type={'text'}
                  value={numSi}
                  actions={{
                    change: changeNumSi,
                  }}
                />
              </LabelFrame>
            </ContentFrame>

            <ContentFrame style={{ marginBottom: '4px' }}>
              <LabelFrame sx={{ width: '32.8%' }}>
                <Input
                  label={'Заводской номер'}
                  placeholder={'Пример ввода - 3829JH39'}
                  type={'text'}
                  value={numFactory}
                  actions={{
                    change: changeNumFactory,
                  }}
                />
              </LabelFrame>

              <div style={{ display: 'flex', flexFlow: 'column', width: 'calc(65.6% + 16px)' }}>
                <Input
                  label={'Наименование владельца СИ'}
                  placeholder={'Выберите тип лица или введите наименование владельца СИ'}
                  type={'text'}
                  value={nameSi}
                  actions={{
                    change: changeNameSi,
                  }}
                />
              </div>
            </ContentFrame>
            <ContentFrame style={{ marginBottom: '4px' }}>
              <LabelFrame sx={{ width: '32.8%' }}></LabelFrame>

              <div
                style={{
                  display: 'flex',
                  flexFlow: 'column',
                  width: 'calc(65.6% + 16px)',
                  marginTop: '-10px',
                }}
              >
                <RadioButton
                  value={nameSi}
                  onChange={changeIsNameSi}
                  trueLabel={'Физическое лицо'}
                  falseLabel={'Юридическое лицо'}
                  trueValue={'Физическое лицо'}
                  falseValue={'Юридическое лицо'}
                />
              </div>
            </ContentFrame>

            <ContentFrame>
              <LabelFrame sx={{ width: '32.8%' }}>
                <Input
                  label={'№ свидетельства или извещения'}
                  placeholder={'Пример ввода - АИК/24-08-2024/382808433'}
                  type={'text'}
                  value={numNotification}
                  actions={{
                    change: handleNumNotification,
                  }}
                />
              </LabelFrame>

              <LabelFrame sx={{ width: '32.8%' }}>
                <Input
                  label={'Номер протокола поверки'}
                  placeholder={'Введите номер протокола'}
                  type={'text'}
                  value={numProtocol}
                  actions={{
                    change: handleNumProtocol,
                  }}
                />
              </LabelFrame>

              <LabelFrame sx={{ width: '32.8%' }}>
                <DatePicker
                  selectedDate={verificationDate}
                  dateChange={handleVerificationDateChange}
                  label='Дата поверки'
                  isRequired={true}
                />
              </LabelFrame>
            </ContentFrame>
          </Content>

          <Button
            onClick={handleSave}
            label='Редактировать запись'
            disabled={isDisabledBtn}
            color='primary'
            fullWidth
          />
        </Wrapper>
      )}
    </main>
  )
}

export default Edit
