const AddNewCircleIcon = ({ color = '#4B5962', width = 32, height = 32, onClick }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      fill='none'
      viewBox='0 0 32 33'
      onClick={onClick}
      style={{ cursor: 'pointer' }}
    >
      <path
        d='M29.3343 17.4303C29.3323 19.3897 29.3174 21.0552 29.2061 22.4217C29.0769 24.0075 28.8121 25.3326 28.2195 26.4331C27.9581 26.9186 27.6369 27.3532 27.2458 27.7443C26.1359 28.8542 24.722 29.3569 22.93 29.5978C21.1794 29.8332 18.9356 29.8332 16.0725 29.8332H15.9301C13.067 29.8332 10.8232 29.8332 9.07257 29.5978C7.28064 29.3569 5.86666 28.8542 4.75682 27.7443C3.77291 26.7604 3.2648 25.5358 2.99461 24.0167C2.72918 22.5243 2.68062 20.6677 2.67054 18.3621C2.66797 17.7757 2.66797 17.1554 2.66797 16.5011V16.4287C2.66795 13.5656 2.66794 11.3218 2.90331 9.5711C3.14423 7.77918 3.64698 6.3652 4.75682 5.25536C5.86666 4.14551 7.28064 3.64277 9.07257 3.40185C10.6295 3.19253 12.6326 3.16937 15.0707 3.16682C15.5844 3.16628 16.0013 3.58298 16.0013 4.09673C16.0013 4.61049 15.5842 5.02677 15.0705 5.02731C12.598 5.02993 10.758 5.05246 9.32047 5.24572C7.73485 5.4589 6.77892 5.86435 6.07237 6.5709C5.36582 7.27745 4.96037 8.23339 4.74719 9.819C4.53041 11.4314 4.52843 13.5501 4.52843 16.4998C4.52843 16.8601 4.52844 17.2084 4.52886 17.5457L5.77058 16.4592C6.90084 15.4703 8.6043 15.527 9.66627 16.589L14.9869 21.9096C15.8392 22.7619 17.181 22.8782 18.1673 22.185L18.5371 21.9251C19.9563 20.9277 21.8764 21.0433 23.1658 22.2037L26.6765 25.3633C27.0298 24.6212 27.2397 23.6461 27.3517 22.2707C27.4573 20.9746 27.4719 19.4005 27.4739 17.4304C27.4744 16.9166 27.8907 16.4998 28.4044 16.4998C28.9182 16.4998 29.3349 16.9166 29.3343 17.4303Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M23.3346 15.1665C20.5062 15.1665 19.092 15.1665 18.2133 14.2878C17.3346 13.4091 17.3346 11.9949 17.3346 9.1665C17.3346 6.33808 17.3346 4.92386 18.2133 4.04518C19.092 3.1665 20.5062 3.1665 23.3346 3.1665C26.1631 3.1665 27.5773 3.1665 28.456 4.04518C29.3346 4.92386 29.3346 6.33808 29.3346 9.1665C29.3346 11.9949 29.3346 13.4091 28.456 14.2878C27.5773 15.1665 26.1631 15.1665 23.3346 15.1665ZM24.3346 6.49984C24.3346 5.94755 23.8869 5.49984 23.3346 5.49984C22.7824 5.49984 22.3346 5.94755 22.3346 6.49984V8.1665H20.668C20.1157 8.1665 19.668 8.61422 19.668 9.1665C19.668 9.71879 20.1157 10.1665 20.668 10.1665H22.3346V11.8332C22.3346 12.3855 22.7824 12.8332 23.3346 12.8332C23.8869 12.8332 24.3346 12.3855 24.3346 11.8332V10.1665H26.0013C26.5536 10.1665 27.0013 9.71879 27.0013 9.1665C27.0013 8.61422 26.5536 8.1665 26.0013 8.1665H24.3346V6.49984Z'
        fill={color}
      />
    </svg>
  )
}

export default AddNewCircleIcon
