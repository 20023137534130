import { styled } from '@mui/material/styles'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'

export const StyledRadioGroup = styled(RadioGroup)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '16px',
  padding: '0 6px',
  [theme.breakpoints.down('lg')]: {
    gap: '0px',
  },
}))

export const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  '& .MuiFormControlLabel-label': {
    fontSize: '15px',
    color: theme.palette.text.primary,
    fontWeight: '400',
    marginLeft: '4px',
  },
  '&.Mui-disabled': {
    color: theme.palette.background.light,
  },
}))

export const StyledRadio = styled(Radio)(({ theme }) => ({
  width: '30px',
  height: '30px',
  color: theme.palette.text.secondary,
  background: theme.palette.background.default,

  '&.Mui-checked': {
    color: theme.palette.text.secondary,
    width: '30px',
    height: '30px',
    background: theme.palette.background.default,
    border: '1px solid white',

    '&:before': {
      content: '""',
      display: 'block',
      width: '13px',
      height: '13px',
      borderRadius: '50%',
      background: theme.palette.primary.main,
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
}))
