import React, { ReactElement, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Input from '../LocalElements/Input/InputMUI'
import DatePicker from '../LocalElements/DatePicker/DatePicker'
import { Button } from '@mui/material'

import {
  changeEtalonMaintenancesForm,
  resetEtalonMaintenancesForm,
  changeEtalonMaintenances,
} from '../../../store/slices/app/views/etalon'
import { setOpen } from '../../../store/slices/app/comps/bottomContainer'
import { IModelEtalon__KitMaintenancesItem, IModelEtalonCreate } from '../../types'

const CreateTSItem: React.FC = (): ReactElement => {
  const dispatch = useDispatch()

  const formData: IModelEtalon__KitMaintenancesItem = useSelector(
    (state: any) => state.etalonReducer.createData.kitMaintenancesForm,
  )
  const etalonCreateData: IModelEtalonCreate = useSelector(
    (state: any) => state.etalonReducer.createData,
  )

  const addRow = (): void => {
    dispatch(
      changeEtalonMaintenances({
        date: formData.date,
        interval: etalonCreateData.interval_maintenances,
        by_name: formData.by_name,
      }),
    )

    dispatch(resetEtalonMaintenancesForm())
    dispatch(setOpen(false))
  }

  const validate = useMemo(() => {
    return formData.by_name && formData.date
  }, [formData.by_name, formData.date])

  return (
    <React.Fragment>
      <div className='flexContainerWithGap' style={{ marginTop: '10px', marginBottom: '14px' }}>
        <div className='width50Percent'>
          <Input
            className={'input'}
            type={'text'}
            placeholder={'Введите ФИО'}
            label={'ФИО лица, проводившего ТО'}
            value={formData.by_name}
            actions={{
              change: (param: string) =>
                dispatch(changeEtalonMaintenancesForm({ value: 'by_name', label: param })),
            }}
            pattern={undefined}
            length={undefined}
            style={undefined}
            multiline={undefined}
            minRows={undefined}
            maxRows={undefined}
            sx={undefined}
            rows={undefined}
          />
        </div>
        <div className='width50Percent' style={{ display: 'flex', gap: '10px' }}>
          <DatePicker
            isRequired={true}
            disableFuture={true}
            selectedDate={formData.date ? formData.date : null}
            dateChange={(date: { format: (arg0: string) => any }) =>
              dispatch(
                changeEtalonMaintenancesForm({
                  value: 'date',
                  label: date,
                }),
              )
            }
            label='Дата проведения'
          />
        </div>
      </div>
      <div className='flexContainerWithGap' style={{ justifyContent: 'flex-end' }}>
        <div style={{ width: '240px' }}>
          <Button
            variant='contained'
            onClick={addRow}
            disabled={validate ? false : true}
            style={{
              textTransform: 'none',
              fontSize: '15px',
              fontFamily: '"Wix Madefor Display", sans-serif',
              padding: '11px 20px 11px',
              margin: 0,
              borderRadius: '12px',
              width: '100%',
            }}
          >
            {'Добавить'}
          </Button>
        </div>
        <div style={{ width: '240px' }}>
          <Button
            variant='contained'
            onClick={() => dispatch(resetEtalonMaintenancesForm())}
            disabled={false}
            style={{
              textTransform: 'none',
              fontSize: '15px',
              fontFamily: '"Wix Madefor Display", sans-serif',
              padding: '11px 20px 11px',
              margin: 0,
              borderRadius: '12px',
              width: '100%',
              filter: 'grayscale(1)',
            }}
          >
            Сбросить все значения
          </Button>
        </div>
      </div>
    </React.Fragment>
  )
}

export default CreateTSItem
