import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import AsyncSelector from '../LocalElements/Selector/reactSelect/Selector.async'
import R from '../../../services/app/client-server/request.service'

import { changeMainData } from '../../../store/slices/app/views/companies'

const AddressCompany = ({ validationErrors }) => {
  const dispatch = useDispatch()
  const address = useSelector(state => state.companiesReducer.createData.main.address)

  const [regionValue, setRegionValue] = useState(null)

  useEffect(() => {
    ;(async () => {
      const { data } = await R.getUserRegions()

      const region = data.regions.map(regionId => regionId.id)
      setRegionValue(region)
    })()
  }, [])

  const getValue = selectedOption => {
    dispatch(
      changeMainData({
        value: 'address',
        label: selectedOption ? selectedOption.value : null,
      }),
    )
  }

  return (
    <React.Fragment>
      <div className='width67Percent'>
        <p style={{ marginBottom: '9px', fontWeight: 500 }}>Юридический адрес компании</p>
        <AsyncSelector
          callback={getValue}
          optionKey={'suggestions'}
          options={async param =>
            R.addresses(
              param,
              regionValue.map(regionId => ({ kladr_id: regionId })),
            )
          }
          value={address}
        />
        {!address && validationErrors['address'] && (
          <div className='error'>{validationErrors['address']}</div>
        )}
      </div>
      <div className='customer_name' style={{ width: '0%', marginRight: '0px' }} />
    </React.Fragment>
  )
}

export default AddressCompany
