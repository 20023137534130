// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import TableArchive from '../../components/Journals/JournalArchive/Table.jsx'
import FilterCompany from '../../components/LocalElements/Filter/FilterCompany.jsx'
import BasicTabs from '../../components/LocalElements/TabsMenu/TabsMenu.jsx'
import { setName } from '../../../store/slices/app/controlers/pageName.js'
import { setViewVariantMain } from '../../../store/slices/app/views/journals.js'
import { setJournalSettings } from '../../../store/slices/admin/admin.js'
import useWindowDimensions from '../../hooks/useWindowDimensions.js'

import R from '../../../services/app/client-server/request.service.js'

const MagazinesIndex = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { width } = useWindowDimensions()

  const userRole = useSelector(state => state.headerReducer.role)
  const isToggle = useSelector(state => state.toggleReducer.isToggle)
  const journalId = useSelector(state => state.journalReducer.journalId)
  const viewVariant = useSelector(state => state.journalReducer.viewVariantMain)

  const companyIdSuperAdmin = useSelector(state => state.companyIdReducer.companyId)
  const companyIdUser = useSelector(state => state.headerReducer.companyId)
  const companyId = userRole === 'super' ? companyIdSuperAdmin : companyIdUser

  const changeViewVariant = param => {
    dispatch(setViewVariantMain(param))
    param === 0 && navigate('/metriva/journals')
    param === 1 && navigate('/metriva/settings/journals')
    param === 2 && navigate('/metriva/journals/archive')
  }

  useEffect(() => {
    dispatch(setName('История журналов'))
  }, [])

  useEffect(() => {
    ;(async () => {
      if (companyId) {
        const { data } = await R.getJournalSettings(companyId)
        if (userRole === 'super' || userRole === 'admin' || true) {
          dispatch(setJournalSettings({ value: 'mainData', label: data.main }))
          dispatch(setJournalSettings({ value: 'personalData', label: data.personal }))
          dispatch(setJournalSettings({ value: 'specificData', label: data.specific }))
        }
      }
    })()
  }, [companyId])

  return (
    <main className={isToggle ? 'main_open' : 'main'}>
      {userRole === 'super' && (
        <div
          style={{
            width: width > 1050 ? '605px' : '100%',
            marginBottom: '10px',
          }}
        >
          <FilterCompany disabled={true} />
        </div>
      )}

      <div style={{ margin: '0px 0 12px' }}>
        <BasicTabs
          viewVariant={2}
          callback={changeViewVariant}
          tabs={[
            { label: 'Актуальная версия документа' },
            { label: 'Настройки' },
            { label: 'Архив' },
          ]}
        />
      </div>
      <div className='header_box'>
        <div
          className='selector'
          style={{
            width: '605px',
            display: 'flex',
            flexFlow: 'row',
            alignItems: 'center',
            marginBottom: '10px',
            marginTop: '20px',
          }}
        >
          <h4 style={{ display: 'block', position: 'relative', marginTop: '-14px' }}>
            Журнал регистрации архивных документов
          </h4>
        </div>
      </div>
      <TableArchive />
    </main>
  )
}

export default MagazinesIndex
