// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Collapse from '@mui/material/Collapse'
import ErrorIcon from '@mui/icons-material/Error'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

import Selector from '../../../../LocalElements/Selector/reactSelect/Selector'
import Input from '../../../../LocalElements/Input/InputMUI'

import R from '../../../../../../services/app/client-server/request.service'
import useWindowDimensions from '../../../../../hooks/useWindowDimensions'

const MeterGas = props => {
  const {
    meterFactoryNumber,
    meterYear,
    meterId,
    selectedMeter,
    selectedMod,
    valueMod,
    is_custom_modification,
    additionalOption,
    valueAnotherMod,
    changeMeter,
  } = props
  const dispatch = useDispatch()
  const { width } = useWindowDimensions()
  const [data, setData] = useState([])

  const measurementId = useSelector(state => state.protocolReducer.measurementId)

  const [valueModId, setValueModId] = useState('')

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const optionsMeter = data
    ? data.map(item => ({
        value: item.id,
        label: `${item.fif_number}, ${item.name_si}, ${item.manufacturer.name}`,
      }))
    : []

  const getValueMeter = selectedOption => {
    if (selectedOption) {
      const selectedMeter = data.find(item => item.id === selectedOption.value)

      dispatch(changeMeter({ value: 'meterId', label: selectedOption.value }))

      if (selectedMeter && selectedMeter.details) {
        const detailsData = selectedMeter.details.map(item => ({
          id: item.id,
          modification: item.modification,

          q_max_horizontal: item.q_max_horizontal,
          q_max_limit_cold: item.q_max_limit_cold,
          q_max_limit_hot: item.q_max_limit_hot,
          q_max_vertical: item.q_max_vertical,
          q_min_horizontal: item.q_min_horizontal,
          q_min_limit_cold: item.q_min_limit_cold,
          q_min_limit_hot: item.q_min_limit_hot,
          q_min_vertical: item.q_min_vertical,
          q_t_horizontal: item.q_t_horizontal,
          q_t_vertical: item.q_t_vertical,
          additional_option: item.additional_option,
        }))

        dispatch(
          changeMeter({
            value: 'selectedMeter',
            label: detailsData,
          }),
        )
      }

      dispatch(changeMeter({ value: 'selectedMod', label: null }))
      dispatch(changeMeter({ value: 'valueMod', label: '' }))
      dispatch(changeMeter({ value: 'additionalOption', label: '' }))
      setValueModId('')
    }
  }

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const optionsMod = selectedMeter
    ? selectedMeter
        .map(item => ({
          value: item.id,
          label: item.additional_option
            ? `${item.modification} (${item.additional_option})`
            : item.modification,
          modName: item.modification,
        }))
        .concat([
          {
            value: 'Нет модификации',
            label: 'Нет модификации',
            modName: 'Нет модификации',
          },
          {
            value: 'another',
            label: 'Другая модификация',
            modName: 'another',
          },
        ])
    : []

  const getValueMod = selectedOption => {
    setValueModId(selectedOption ? selectedOption.value : null)

    dispatch(
      changeMeter({
        value: 'valueMod',
        label: selectedOption ? selectedOption.modName : null,
      }),
    )

    const selectedModification = selectedOption
      ? selectedMeter.find(item => item.id === selectedOption.value)
      : null

    dispatch(
      changeMeter({
        value: 'selectedMod',
        label: selectedModification || null,
      }),
    )
    dispatch(
      changeMeter({
        value: 'additionalOption',
        label: selectedModification.additional_option || null,
      }),
    )

    dispatch(changeMeter({ value: 'diameter', label: null }))
    dispatch(changeMeter({ value: 'meterLocation', label: '' }))
    dispatch(changeMeter({ value: 'meterType', label: '' }))

    dispatch(changeMeter({ value: 'q_min', label: '' }))
    dispatch(changeMeter({ value: 'q_t', label: '' }))
    dispatch(changeMeter({ value: 'q_max', label: '' }))

    dispatch(changeMeter({ value: 'q_min_limit', label: '' }))
    dispatch(changeMeter({ value: 'q_max_limit', label: '' }))
  }

  useEffect(() => {
    if (valueMod) {
      const selectedModification = selectedMeter.find(item => {
        if (additionalOption) {
          return item.modification === valueMod && item.additional_option === additionalOption
        } else {
          return item.modification === valueMod
        }
      })

      if (selectedModification) {
        dispatch(
          changeMeter({
            value: 'selectedMod',
            label: selectedModification,
          }),
        )
      }
    }
  }, [valueMod, additionalOption, selectedMeter, dispatch])

  useEffect(() => {
    if (selectedMod) {
      setValueModId(selectedMod.id)
    }
  }, [selectedMod])

  useEffect(() => {
    if (valueMod === 'Нет модификации') {
      setValueModId('Нет модификации')
    }
  }, [valueMod])

  useEffect(() => {
    if (valueModId && valueModId === 'another') {
      dispatch(changeMeter({ value: 'is_custom_modification', label: true }))
    }
  }, [valueModId])

  useEffect(() => {
    if (is_custom_modification) {
      setValueModId('another')
    }
  }, [is_custom_modification])

  const handleValueModChange = param => {
    dispatch(
      changeMeter({
        value: 'valueAnotherMod',
        label: param,
      }),
    )
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  useEffect(() => {
    ;(async () => {
      const { data: metersList } = await R.getMetersList(
        `?measurement_id=${measurementId}&limit=1000`,
      )
      const meterData = metersList.data
      setData(meterData)
    })()
  }, [measurementId])

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  const handleMeterFactoryNumberChange = param => {
    dispatch(
      changeMeter({
        value: 'meterFactoryNumber',
        label: param,
      }),
    )
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  const yearlist = Array.from({ length: 25 }, (item, index) => (2000 + index).toString())

  yearlist.push('Не выбрано')

  const optionsYear = yearlist
    ? yearlist.map(item => ({
        value: item,
        label: item,
      }))
    : []

  const getValueYear = selectedOption => {
    dispatch(
      changeMeter({
        value: 'meterYear',
        label: selectedOption ? selectedOption.value : null,
      }),
    )
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  return (
    <React.Fragment>
      <h2 style={{ marginBottom: '14px' }}>Данные о поверяемом приборе</h2>
      <div className='flexContainerWithGap' style={{ marginBottom: '10px' }}>
        <div className='width50Percent'>
          <p
            style={{
              marginBottom: '9px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            Поиск поверяемого прибора ( № Госреестра, Завод производитель... )
            {meterId ? (
              <CheckCircleIcon sx={{ color: '#63E6BE', marginLeft: '8px', marginTop: '0px' }} />
            ) : (
              <ErrorIcon sx={{ color: '#FFD43B', marginLeft: '8px', marginTop: '0px' }} />
            )}
          </p>
          <Selector
            placeholder={'Выберите прибор'}
            options={optionsMeter}
            onChange={getValueMeter}
            value={meterId ? optionsMeter.find(option => option.value === meterId) : null}
            isClearable={false}
            isSearchable={true}
            noOptionsMessage={() => 'Нет доступных приборов'}
          />
        </div>

        <div className='width50Percent'>
          <p
            style={{
              marginBottom: '9px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            Модификация прибора
            {valueModId ? (
              <CheckCircleIcon sx={{ color: '#63E6BE', marginLeft: '8px', marginTop: '-2px' }} />
            ) : (
              <ErrorIcon
                sx={{
                  color: '#FFD43B',
                  marginLeft: '8px',
                  marginTop: '0px',
                }}
              />
            )}
          </p>
          <Selector
            placeholder={'Выберите модификацию'}
            options={optionsMod}
            onChange={getValueMod}
            isClearable={false}
            isSearchable={true}
            noOptionsMessage={() => 'Выберите поверяемый прибор'}
            value={valueModId ? optionsMod.find(option => option.value === valueModId) : null}
          />
        </div>
      </div>

      <Collapse in={valueModId === 'another'}>
        <div
          className='width50Percent'
          style={
            width > 1050
              ? { marginTop: '18px', marginBottom: '13px', width: '100%' }
              : { margin: 0, marginTop: '16px', width: '100%' }
          }
        >
          <Input
            label={'Другая модификация прибора'}
            type={'text'}
            placeholder={'Введите модификацию прибора'}
            value={valueAnotherMod}
            actions={{
              change: handleValueModChange,
            }}
          />
        </div>
      </Collapse>

      <div
        className='flexContainerWithGap'
        style={width > 1050 ? {} : { marginTop: '16px', marginBottom: '0px' }}
      >
        <div className='width50Percent'>
          {width > 1050 && (
            <p
              style={{
                marginBottom: '9px',
                display: 'flex',
                alignItems: 'center',
                opacity: 0,
              }}
            >
              Заводской номер прибора
              {!!meterFactoryNumber ? (
                <CheckCircleIcon
                  sx={{
                    color: '#63E6BE',
                    marginLeft: '8px',
                    marginTop: '-2px',
                  }}
                />
              ) : (
                <ErrorIcon
                  sx={{
                    color: '#FFD43B',
                    marginLeft: '8px',
                    marginTop: '-1px',
                    opacity: 0,
                  }}
                />
              )}
            </p>
          )}
          <Input
            label={'Заводской номер прибора'}
            placeholder={'Введите заводской номер'}
            value={meterFactoryNumber}
            type={'text'}
            actions={{
              change: handleMeterFactoryNumberChange,
            }}
          />
        </div>
        <div className='width50Percent'>
          <p
            style={{
              marginBottom: '9px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            Год выпуска прибора
            {!!meterYear ? (
              <CheckCircleIcon
                sx={{
                  color: '#63E6BE',
                  marginLeft: '8px',
                  marginTop: '0px',
                }}
              />
            ) : (
              <ErrorIcon
                sx={{
                  color: '#FFD43B',
                  marginLeft: '8px',
                  marginTop: '0px',
                }}
              />
            )}
          </p>
          <Selector
            placeholder={'Выберите год'}
            options={optionsYear}
            onChange={getValueYear}
            value={optionsYear.find(option => option.value === meterYear)}
            isClearable={true}
            isSearchable={true}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default MeterGas
