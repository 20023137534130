import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Selector from '../LocalElements/Selector/reactSelect/Selector'
import { changeData } from '../../../store/slices/app/views/cases'
import R from '../../../services/app/client-server/request.service'
import { LabelFrame } from '../../pages/Cases/style'

const Measurements = () => {
  const dispatch = useDispatch()
  const [data, setData] = useState(null)

  const measurementId = useSelector(state => state.casesReducer.createData.measurementId)

  const options = data
    ? data.map(data => ({
        value: data.id,
        label: data.name,
      }))
    : []

  const getValue = selectedOption => {
    if (selectedOption) {
      dispatch(
        changeData({
          value: 'measurementId',
          label: selectedOption.value,
        }),
      )

      dispatch(
        changeData({
          value: 'methodId',
          label: '',
        }),
      )

      dispatch(
        changeData({
          value: 'methodPlaces',
          label: [],
        }),
      )

      dispatch(
        changeData({
          value: 'typeMethodId',
          label: [],
        }),
      )

      dispatch(
        changeData({
          value: 'methodPoints',
          label: [],
        }),
      )

      dispatch(changeData({ value: 'deviceIds', label: [] }))
    } else {
      dispatch(
        changeData({
          value: 'measurementId',
          label: '',
        }),
      )

      dispatch(
        changeData({
          value: 'methodId',
          label: '',
        }),
      )

      dispatch(
        changeData({
          value: 'methodPlaces',
          label: [],
        }),
      )

      dispatch(
        changeData({
          value: 'typeMethodId',
          label: [],
        }),
      )

      dispatch(
        changeData({
          value: 'methodPoints',
          label: [],
        }),
      )
      dispatch(changeData({ value: 'deviceIds', label: [] }))
    }
  }

  useEffect(() => {
    ;(async () => {
      const { data: listMeasurement } = await R.getMeasurementItem()
      const measurement = listMeasurement.measurements
      setData(measurement)
    })()
  }, [])

  return (
    <LabelFrame>
      <Selector
        placeholder={'Выберите область измерения'}
        label={{
          text: 'Область измерения',
          topBg: 'white',
          bottomBg: 'white',
        }}
        isClearable={true}
        isSearchable={true}
        options={options}
        value={measurementId ? options.find(option => option.value === measurementId) : null}
        onChange={getValue}
        noOptionsMessage={() => 'Нет доступных областей измерения'}
        isRequired={true}
      />
    </LabelFrame>
  )
}

export default Measurements
