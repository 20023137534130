// ----------------------------------------------------------------
/* eslint-disable @typescript-eslint/no-unused-vars */
// ----------------------------------------------------------------
import React, { ChangeEvent, ReactElement, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Input from '../LocalElements/Input/InputMUI'
import DatePicker from '../LocalElements/DatePicker/DatePicker'
import Tooltip from '../../../ui/tooltip'
import InfoCircle from '../../../img/icon/InfoCircle'
import { Button, Radio, RadioGroup, FormControlLabel } from '@mui/material'

import {
  changeEtalonVerificationsForm,
  resetEtalonVerificationsForm,
  changeEtalonVerifications,
} from '../../../store/slices/app/views/etalon'
import { setOpen } from '../../../store/slices/app/comps/bottomContainer'
import { IModelEtalon__KitVerificationsItem, IModelEtalonCreate } from '../../types'

const CreateCheckItem: React.FC = (): ReactElement => {
  const dispatch = useDispatch()

  const journalId = useSelector((state: any) => state.journalReducer.journalId)

  const formData: IModelEtalon__KitVerificationsItem = useSelector(
    (state: any) => state.etalonReducer.createData.kitVerificationsForm,
  )
  const etalonCreateData: IModelEtalonCreate = useSelector(
    (state: any) => state.etalonReducer.createData,
  )

  const addRow = (): void => {
    const verificationData: any = {
      place: formData.place,
      date: formData.date,
      interval: Number(etalonCreateData.interval_verifications),
      link: formData.link,
    }

    if (!journalId) {
      verificationData.certificate_number = formData.certificate_number
      verificationData.is_suitable = formData.is_suitable
    }

    dispatch(changeEtalonVerifications(verificationData))
    dispatch(resetEtalonVerificationsForm())
    dispatch(setOpen(false))
  }

  const validate = useMemo(() => {
    if (!journalId) {
      return (
        formData.place &&
        formData.date &&
        etalonCreateData.interval_verifications &&
        formData.certificate_number &&
        formData.link
      )
    }

    return (
      formData.place && formData.date && etalonCreateData.interval_verifications && formData.link
    )
  }, [
    formData.place,
    formData.date,
    etalonCreateData.interval_verifications,
    formData.certificate_number,
    formData.link,
    journalId,
  ])

  useEffect(() => {
    console.log(typeof formData.is_suitable)
  }, [formData.is_suitable])

  return (
    <React.Fragment>
      <div className='flexContainerWithGap' style={{ marginTop: '10px', marginBottom: '22px' }}>
        <div className='width50Percent'>
          <Input
            className={'input'}
            type={'text'}
            placeholder={'Введите место проведения'}
            label={'Место проведения (наименование организации)'}
            value={formData.place}
            actions={{
              change: (param: string) =>
                dispatch(changeEtalonVerificationsForm({ value: 'place', label: param })),
            }}
            pattern={undefined}
            length={undefined}
            style={undefined}
            multiline={undefined}
            minRows={undefined}
            maxRows={undefined}
            sx={undefined}
            rows={undefined}
          />
        </div>
        <div className='width50Percent' style={{ display: 'flex', gap: '10px' }}>
          <div style={{ width: journalId ? '100%' : '50%' }}>
            <DatePicker
              isRequired={true}
              disableFuture={true}
              selectedDate={formData.date ? formData.date : null}
              dateChange={(date: { format: (arg0: string) => any }) =>
                dispatch(
                  changeEtalonVerificationsForm({
                    value: 'date',
                    label: date,
                  }),
                )
              }
              label='Дата проведения'
            />
          </div>
          {!journalId && (
            <div className='width50Percent' style={{ paddingLeft: '10px' }}>
              <span>Заключение поверки</span>
              <RadioGroup
                aria-labelledby='demo-controlled-radio-buttons-group'
                name='controlled-radio-buttons-group'
                value={formData.is_suitable}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  dispatch(
                    changeEtalonVerificationsForm({
                      value: 'is_suitable',
                      label: event.target.value === 'true',
                    }),
                  )
                }}
                style={{
                  flexDirection: 'row',
                  fontFamily: "'Wix Madefor Display', sans-serif",
                  color: '#123532',
                  fontSize: '16px',
                  fontWeight: '400',
                  marginTop: '0px',
                  paddingLeft: '5px',
                }}
              >
                <FormControlLabel
                  value={true}
                  control={
                    <Radio
                      sx={{
                        '& .MuiSvgIcon-root': {
                          fontSize: '22px !important',
                        },
                      }}
                    />
                  }
                  label='Пригоден'
                  sx={{
                    '& .MuiTypography-root': {
                      fontFamily: "'Wix Madefor Display', sans-serif !important",
                      fontSize: '15px !important',
                      fontWeight: 400,
                      color: '#132532',
                    },
                  }}
                />
                <FormControlLabel
                  value={false}
                  control={
                    <Radio
                      sx={{
                        '& .MuiSvgIcon-root': {
                          fontSize: '22px !important',
                        },
                      }}
                    />
                  }
                  label='Непригоден'
                  sx={{
                    '& .MuiTypography-root': {
                      fontFamily: "'Wix Madefor Display', sans-serif !important",
                      fontSize: '15px !important',
                      fontWeight: 400,
                      color: '#132532',
                    },
                  }}
                />
              </RadioGroup>
            </div>
          )}
        </div>
      </div>
      <div className='flexContainerWithGap' style={{ marginBottom: '14px' }}>
        <div className='width50Percent'>
          <Input
            className={'input'}
            type={'text'}
            placeholder={'Введите № свидетельства или извещения'}
            label={'№ свидетельства или извещения'}
            value={formData.certificate_number}
            actions={{
              change: (param: string) =>
                dispatch(
                  changeEtalonVerificationsForm({ value: 'certificate_number', label: param }),
                ),
            }}
            pattern={undefined}
            length={undefined}
            style={undefined}
            multiline={undefined}
            minRows={undefined}
            maxRows={undefined}
            sx={undefined}
            rows={undefined}
          />
        </div>
        {!journalId && (
          <div className='width50Percent'>
            <Input
              className={'input'}
              type={'text'}
              placeholder={'Вставьте необходимую ссылку'}
              label={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  Ссылка на сведения о результатах поверки СИ
                  <Tooltip
                    title='Ссылка на ФГИС Аршин'
                    style={{ marginLeft: '7px' }}
                    disableHoverListener={undefined}
                  >
                    <InfoCircle />
                  </Tooltip>
                </div>
              }
              value={formData.link}
              actions={{
                change: (param: string) =>
                  dispatch(changeEtalonVerificationsForm({ value: 'link', label: param })),
              }}
              pattern={undefined}
              length={undefined}
              style={undefined}
              multiline={undefined}
              minRows={undefined}
              maxRows={undefined}
              sx={undefined}
              rows={undefined}
            />
          </div>
        )}

        {journalId && <div className='width50Percent'></div>}
      </div>
      <div
        className='flexContainerWithGap'
        style={{ justifyContent: 'flex-end', alignItems: 'center' }}
      >
        <div style={{ width: '240px' }}>
          <Button
            variant='contained'
            onClick={addRow}
            disabled={validate ? false : true}
            style={{
              textTransform: 'none',
              fontSize: '15px',
              fontFamily: '"Wix Madefor Display", sans-serif',
              padding: '11px 20px 11px',
              margin: 0,
              borderRadius: '12px',
              width: '100%',
            }}
          >
            {'Добавить'}
          </Button>
        </div>
        <div style={{ width: '240px' }}>
          <Button
            variant='contained'
            onClick={() => dispatch(resetEtalonVerificationsForm())}
            disabled={false}
            style={{
              textTransform: 'none',
              fontSize: '15px',
              fontFamily: '"Wix Madefor Display", sans-serif',
              padding: '11px 20px 11px',
              margin: 0,
              borderRadius: '12px',
              width: '100%',
              filter: 'grayscale(1)',
            }}
          >
            Сбросить все значения
          </Button>
        </div>
      </div>
    </React.Fragment>
  )
}

export default CreateCheckItem
