// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React from 'react'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import theme from '../../../../utils/theme.ts'
import CheckCircleIcon from '../../../../img/icon/CheckCircleIcon'
import VisibilityToggleIcon from '../../../../img/icon/VisibilityToggleIcon'

const Input = ({
  type,
  pattern,
  className,
  placeholder,
  label,
  errorLabel = 'Ошибка заполнения поля',
  length,
  style,
  value,
  actions,
  autoComplete = 'off',
  isDisabled = false,
  notRequired = false,
  error = false,
  multiline = false,
  minRows,
  maxRows,
  rows,
  sx,
}) => {
  const isPassword = type === 'password'
  const [showPassword, setShowPassword] = React.useState(false)

  const handleTogglePasswordVisibility = () => {
    setShowPassword(prevShowPassword => !prevShowPassword)
  }

  return (
    <FormControl fullWidth sx={{ m: 1, margin: '0px' }} style={style}>
      <InputLabel
        htmlFor='outlined-adornment-amount'
        shrink
        sx={{
          '&.MuiInputLabel-root': {
            color: !!!error ? theme.palette.text.dark : '#d32f2f',
            fontSize: '14px !important',
            fontWeight: '400 !important',
            background: 'white',
            padding: '0 4px',
            borderRadius: '6px 6px 0 0 ',
          },
        }}
      >
        {error ? errorLabel : label}
      </InputLabel>
      <OutlinedInput
        id='outlined-adornment-amount'
        type={isPassword ? (showPassword ? 'text' : 'password') : type}
        startAdornment={
          !notRequired && (
            <InputAdornment position='start' sx={{ ...sx }}>
              <React.Fragment>
                {value ? (
                  <CheckCircleIcon sx={{ color: theme.palette.secondary.light }} />
                ) : (
                  <VisibilityToggleIcon sx={{ color: '#FFCF25' }} />
                )}
              </React.Fragment>
            </InputAdornment>
          )
        }
        endAdornment={
          isPassword && (
            <InputAdornment position='end'>
              <IconButton
                style={{ marginRight: '4px' }}
                aria-label='toggle password visibility'
                onClick={handleTogglePasswordVisibility}
                edge='end'
              >
                {showPassword ? (
                  <VisibilityIcon sx={{ color: '#63E6BE', fontSize: 20 }} />
                ) : (
                  <VisibilityOffIcon sx={{ color: '#d7ddea', fontSize: 20 }} />
                )}
              </IconButton>
            </InputAdornment>
          )
        }
        label={label}
        placeholder={placeholder}
        pattern={pattern}
        className={className}
        maxLength={length}
        value={value}
        autoComplete={autoComplete}
        disabled={isDisabled}
        multiline={multiline}
        minRows={minRows}
        maxRows={maxRows}
        rows={rows}
        error={error}
        onChange={event => {
          try {
            actions['change'](event.target.value)
          } catch {
            actions['change'] &&
              actions['change'][0] &&
              actions['change'][0]([...actions['change'][1], event.target.value])
          }
        }}
        onKeyUp={event => {
          actions['keyup'] && actions['keyup'][0](event.key)
        }}
        sx={{
          height: !!!multiline ? '55px !important' : null,
          borderRadius: '12px',
          background: 'white',
          '& .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${theme.palette.secondary.main}`,
          },
          '&:not(.Mui-disabled):hover .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${theme.palette.text.secondary}`,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${theme.palette.text.secondary}`,
          },
          '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: `1px solid ${theme.palette.background.light}`,
          },
          '&.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: `1px solid ${theme.palette.background.error}`,
          },
        }}
      />
    </FormControl>
  )
}

export default Input
