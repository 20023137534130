import { styled } from '@mui/material'

export const PageContainer = styled('div')(() => ({
  display: 'flex',
  position: 'relative',
  flexFlow: 'column',
  gap: '16px',
  marginTop: '16px',
}))

export const BoxContainer = styled('div')(({ theme }) => ({
  padding: '22px',
  background: 'white',
  border: `1px solid ${theme.palette.secondary.main}`,
  borderRadius: '12px',
  boxSizing: 'border-box',
}))

export const Wrapper = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
}))

export const Container = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
  },
}))

export const FlexStartFrame = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
  },
}))

export const LabelFrame25 = styled('div')(({ theme }) => ({
  width: '24%',
  display: 'flex',
  flexDirection: 'column',
  gap: '6px',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
  },
}))

export const LabelFrame33 = styled('div')(({ theme }) => ({
  width: '33%',
  display: 'flex',
  flexDirection: 'column',
  gap: '6px',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
  },
}))

export const LabelFrame50 = styled('div')(({ theme }) => ({
  width: '50%',
  display: 'flex',
  flexDirection: 'column',
  gap: '6px',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
  },
}))

export const LabelFrame70 = styled('div')(({ theme }) => ({
  width: '70%',
  display: 'flex',
  flexDirection: 'column',
  gap: '6px',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
  },
}))

export const SingleDiameter = styled('div')(() => ({
  height: '54px',
  border: '1px solid #d7ddea',
  padding: '14px 16px',
  borderRadius: '12px',
}))

export const ConditionBox = styled('div')(({ theme }) => ({
  width: '100%',
  padding: '20px',
  borderRadius: '12px',
  background: '#f8f9fc',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
}))

export const CountIndicationBox = styled('div')(() => ({
  width: '44px',
  height: '44px',
  borderRadius: '12px',
  background: '#e6eaf3',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

export const QActualBox = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '22px 0 22px 22px',
  borderRadius: '12px',
  background:
    'linear-gradient(90deg, rgba(248, 249, 252, 1) 0%, rgba(248, 249, 252, 1) 66%, rgba(248, 249, 252, 0) 100%)',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
  },
}))
