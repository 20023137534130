import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const HistoryListener = () => {
  const location = useLocation()

  useEffect(() => {
    console.log(location.pathname)
  }, [location])

  return null
}

export default HistoryListener
