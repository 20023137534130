import React from 'react'
import { Typography } from '@mui/material'
import { Wrapper } from '../style.ts'
import theme from '../../../../../../utils/theme.ts'
import AddImage from './AddImage.jsx'

const MOC = [
  { id: 1, name: 'Установка поверочная (передвижная)' },
  { id: 2, name: 'Секундомер' },
  { id: 3, name: 'Название СП 1' },
  { id: 4, name: 'Название СП 2' },
  { id: 5, name: 'Название СП 3' },
]

const VerificationToolsUsed = () => {
  return (
    <Wrapper>
      <Typography variant='h2'>Применяемые средства поверки</Typography>
      <Typography
        variant='subtitle2'
        color={theme.palette.text.secondary}
        sx={{
          borderBottom: `1px solid ${theme.palette.text.secondary}`,
          cursor: 'pointer',
          width: '180px',
        }}
      >
        Ссылка на Комплект СП
      </Typography>

      {MOC.map(item => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '16px',
          }}
        >
          <Typography variant='subtitle2' key={item.id} fontWeight={'bold'}>
            {item.name}
          </Typography>
          <AddImage
            title={
              'Согласно пункту № 4.3.1 Методика поверки «Рг»  ГОСТ 8.1012-2022 (с изменением №1), при проведении поверки осуществляют цифровую фиксацию (ЦФ) поверки. В рамках ЦФ происходит фотофиксация общего вида эталона и его подключений к гидравлической линии. А также, его идентификационные признаки (заводской номер и (или) этикетку, установленную в соответствии с системой менеджмента качества организации, осуществляющую данную поверку счетчика воды).'
            }
            count={4}
          />
        </div>
      ))}
    </Wrapper>
  )
}

export default VerificationToolsUsed
