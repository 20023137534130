// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import CheckCircleIcon from '../../../../img/icon/CheckCircleIcon'
import VisibilityToggleIcon from '../../../../img/icon/VisibilityToggleIcon'
import Measurement from '../../LocalElements/Filter/FilterMeasurement'
import AsyncSelector from '../../LocalElements/Selector/reactSelect/Selector.async'
import DatePicker from '../../LocalElements/DatePicker/DatePicker'
import Feedback from '../../LocalElements/Feedback/Feedback'
import { setShow } from '../../../../store/slices/app/comps/feedback'
import R from '../../../../services/app/client-server/request.service.js'
import { changeTickets, resetTickets } from '../../../../store/slices/app/views/journals'
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from '../../../../store/slices/app/comps/snackbar'
import { refreshTickets } from '../../../../store/slices/app/controlers/updater'
import Input from '../../../../ui/input/index.tsx'
import Button from '../../../../ui/button/index.tsx'
import RadioButton from '../../../../ui/radio-button/index'
import { Typography } from '@mui/material'
import { Wrapper, Frame, Content, ContentFrame, LabelFrame } from '../style'

import usePrompt from '../../../hooks/usePrompt.ts'

const Edit = () => {
  const { journal_id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const userRole = useSelector(state => state.headerReducer.role)
  const show = useSelector(state => state.feedbackReducer.show)

  const [tariffIds, setTariffIds] = useState([])
  const [regionValue, setRegionValue] = useState([])
  const [isDisabledBtn, setIsDisabledBtn] = useState(false)
  const [getPrompt, setGetPrompt] = useState(true)

  const companyIdSuperAdmin = useSelector(state => state.companyIdReducer.companyId)
  const companyIdUser = useSelector(state => state.headerReducer.companyId)
  const companyId = userRole === 'super' ? companyIdSuperAdmin : companyIdUser

  const [isAutogenerated, setIsAutogenerated] = useState('')
  const [extendedProtocolEditableFields, setExtendedProtocolEditableFields] = useState('')

  const measurement = useSelector(state => state.journalReducer.tickets.measurement)
  const createdAt = useSelector(state => state.journalReducer.tickets.createdAt)
  const createdBy = useSelector(state => state.journalReducer.tickets.createdBy)
  const customerName = useSelector(state => state.journalReducer.tickets.customerName)
  const customerPhone = useSelector(state => state.journalReducer.tickets.customerPhone)
  const customerAddress = useSelector(state => state.journalReducer.tickets.customerAddress)
  const verificationDate = useSelector(state => state.journalReducer.tickets.verificationDate)
  const isCheckedMark = useSelector(state => state.journalReducer.tickets.isCheckedMark)
  const arshinFullName = useSelector(state => state.journalReducer.tickets.arshinFullName)
  const numberAgreement = useSelector(state => state.journalReducer.tickets.numberAgreement)
  const comment = useSelector(state => state.journalReducer.tickets.comment)

  usePrompt(
    'Вы уверены, что хотите продолжить действие? Все введенные или измененные данные не будут сохранены',
    getPrompt,
  )

  const handleCreatedByChange = param => {
    dispatch(changeTickets({ value: 'createdBy', label: param }))
  }
  const handleCustomerNameChange = param => {
    dispatch(changeTickets({ value: 'customerName', label: param }))
  }
  const handleArshinFullNameChange = param => {
    dispatch(changeTickets({ value: 'arshinFullName', label: param }))
  }

  const handleNumberAgreementChange = param => {
    dispatch(changeTickets({ value: 'numberAgreement', label: param }))
  }
  const handleCommentChange = param => {
    dispatch(changeTickets({ value: 'comment', label: param }))
  }

  const changeMeasurement = param => {
    dispatch(changeTickets({ value: 'measurement', label: param }))
  }

  const formatPhoneNumber = input => {
    const phoneNumber = input.replace(/\D/g, '')
    let formattedPhoneNumber = ''

    if (phoneNumber.length >= 1) {
      formattedPhoneNumber = `+7`
    }
    if (phoneNumber.length > 1) {
      formattedPhoneNumber += ` (${phoneNumber.slice(1, 4)}`
    }
    if (phoneNumber.length > 4) {
      formattedPhoneNumber += `) ${phoneNumber.slice(4, 7)}`
    }
    if (phoneNumber.length > 7) {
      formattedPhoneNumber += `-${phoneNumber.slice(7, 9)}`
    }
    if (phoneNumber.length > 9) {
      formattedPhoneNumber += `-${phoneNumber.slice(9, 11)}`
    }

    dispatch(
      changeTickets({
        value: 'customerPhone',
        label: formattedPhoneNumber,
      }),
    )
  }

  const changePhone = param => {
    formatPhoneNumber(param)
  }

  const changeIsChecked = event => {
    const value = event.target.value === 'true'
    dispatch(changeTickets({ value: 'isCheckedMark', label: value }))
  }

  const handleCreatedAtChange = date => {
    if (date) {
      dispatch(changeTickets({ value: 'createdAt', label: date.format('YYYY-MM-DD') }))
    } else {
      dispatch(changeTickets({ value: 'createdAt', label: '' }))
    }
  }

  const handleVerificationDateChange = date => {
    if (date) {
      dispatch(
        changeTickets({
          value: 'verificationDate',
          label: date.format('YYYY-MM-DD'),
        }),
      )
    } else {
      dispatch(changeTickets({ value: 'verificationDate', label: '' }))
    }
  }

  const changeAddress = selectedOption => {
    dispatch(
      changeTickets({
        value: 'customerAddress',
        label: selectedOption ? selectedOption.value : null,
      }),
    )
  }

  const isPhoneValid = phone => {
    const phoneRegex = /^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/
    return phoneRegex.test(phone)
  }

  useEffect(() => {
    ;(async () => {
      const { data } = await R.getUserRegions()

      const region = data.regions.map(regionId => regionId.id)
      setRegionValue(region)

      const tariffIds = data.tariffs.map(tariff => tariff.id)
      if (tariffIds.length === 0) {
        setTariffIds(null)
      } else {
        setTariffIds(tariffIds)
      }
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      dispatch(setShow(true))
      try {
        const { data: conditionsItemData } = await R.getTicketsItem(journal_id, companyId)
        const data = conditionsItemData.data

        setIsAutogenerated(data?.is_autogenerated)
        setExtendedProtocolEditableFields(data?.extended_protocol_editable_fields)

        dispatch(
          changeTickets({
            value: 'measurement',
            label: data?.measurement_id,
          }),
        )

        dispatch(
          changeTickets({
            value: 'createdAt',
            label: data?.contact_date,
          }),
        )

        dispatch(
          changeTickets({
            value: 'arshinFullName',
            label: data?.customer_arshin_name,
          }),
        )

        dispatch(
          changeTickets({
            value: 'createdBy',
            label: data?.arshin_master_name,
          }),
        )

        dispatch(
          changeTickets({
            value: 'customerName',
            label: data?.customer_fullname,
          }),
        )

        dispatch(
          changeTickets({
            value: 'customerPhone',
            label: data?.customer_phone,
          }),
        )

        dispatch(
          changeTickets({
            value: 'customerAddress',
            label: data?.customer_address,
          }),
        )

        dispatch(
          changeTickets({
            value: 'verificationDate',
            label: data?.planned_date,
          }),
        )

        dispatch(
          changeTickets({
            value: 'isCheckedMark',
            label: data?.is_checked,
          }),
        )

        dispatch(
          changeTickets({
            value: 'numberAgreement',
            label: data?.agreement_number,
          }),
        )

        dispatch(
          changeTickets({
            value: 'comment',
            label: data?.comment,
          }),
        )
      } catch (error) {
        console.error(error)
      }
      dispatch(setShow(false))
    })()
  }, [journal_id, companyId, dispatch])

  useEffect(() => {
    return () => {
      dispatch(resetTickets())
    }
  }, [])

  const handleSave = async () => {
    setIsDisabledBtn(true)
    setGetPrompt(false)

    let data = {
      comment: comment,
      contact_date: createdAt,
      planned_date: verificationDate,
    }

    if (!isAutogenerated) {
      data = {
        ...data,
        measurement_id: measurement,
        customer_arshin_name: createdBy,
        customer_address: customerAddress,
        is_checked: isCheckedMark,
        arshin_master_name: arshinFullName,
        agreement_number: numberAgreement,
      }
    }

    if (extendedProtocolEditableFields === 'all') {
      data.customer_fullname = customerName
      data.customer_phone = customerPhone
    } else if (extendedProtocolEditableFields === 'customer_fullname') {
      data.customer_phone = customerPhone
    } else if (extendedProtocolEditableFields === 'customer_phone') {
      data.customer_fullname = customerName
    }

    const { status, data: conditionsAddData } = await R.editTickets(journal_id, companyId, data)

    if (status === 200) {
      dispatch(setMessage('Запись успешно отредактирована'))
      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
      dispatch(refreshTickets())
      dispatch(resetTickets())
      navigate('/metriva/journals')
    } else {
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Запись не отредактирована - ошибка валидации'))
      setIsDisabledBtn(false)
      setGetPrompt(true)
    }
  }

  const navigateToProtocols = () => {
    navigate('/metriva/journals')
  }

  const measurementLabelIcon = measurement ? (
    <CheckCircleIcon
      sx={{
        color: '#63E6BE',
        marginLeft: '8px',
        marginTop: '-0px',
      }}
    />
  ) : (
    <VisibilityToggleIcon
      sx={{
        color: '#FFD43B',
        marginLeft: '8px',
        marginTop: '-0px',
      }}
    />
  )

  return (
    <main className='main'>
      {show ? (
        <Feedback />
      ) : (
        <Wrapper>
          <Frame onClick={navigateToProtocols}>
            <i
              className='fas fa-arrow-left icon_back'
              style={{ width: '30px', height: '30px', marginRight: '6px' }}
            />
            <Typography variant='subtitle2'>Все записи документа</Typography>
          </Frame>

          <Typography variant='h2'>Журнал регистрации заявок на поверку</Typography>

          <Content>
            <Typography variant='h2' style={{ marginBottom: '4px' }}>
              Информация о журнале
            </Typography>

            {!isAutogenerated && (
              <ContentFrame style={{ marginBottom: '4px' }}>
                <div className='width50Percent'>
                  <Measurement
                    measurementId={measurement}
                    setMeasurementId={changeMeasurement}
                    label={{
                      text: 'Область измерения',
                      topBg: 'white',
                      bottomBg: 'white',
                    }}
                  />
                </div>
                <div className='width50Percent'></div>
              </ContentFrame>
            )}

            {(extendedProtocolEditableFields === 'all' ||
              extendedProtocolEditableFields === 'customer_fullname') && (
              <ContentFrame style={{ marginBottom: '4px' }}>
                <div className='width50Percent'>
                  <Input
                    label={'Владелец СИ'}
                    placeholder={'Напишите имя владельца СИ'}
                    type={'text'}
                    value={createdBy}
                    actions={{
                      change: handleCreatedByChange,
                    }}
                  />
                </div>
                <div className='width50Percent'>
                  <Input
                    label={'ФИО инженера по метрологии'}
                    placeholder={'Напишите ФИО инженера'}
                    type={'text'}
                    value={arshinFullName}
                    actions={{
                      change: handleArshinFullNameChange,
                    }}
                  />
                </div>
              </ContentFrame>
            )}

            {(extendedProtocolEditableFields === 'all' ||
              extendedProtocolEditableFields === 'customer_phone') && (
              <ContentFrame>
                <div className='width50Percent'>
                  <Input
                    label={'ФИО заказчика'}
                    placeholder={'Напишите ФИО заказчика'}
                    type={'text'}
                    value={customerName}
                    actions={{
                      change: handleCustomerNameChange,
                    }}
                  />
                </div>

                <div className='width50Percent'>
                  <Input
                    notRequired={true}
                    label={'Контактный телефон'}
                    placeholder={'Напишите номер телефона'}
                    type={'text'}
                    value={customerPhone}
                    actions={{
                      change: changePhone,
                    }}
                    isValid={isPhoneValid(customerPhone)}
                  />
                </div>
              </ContentFrame>
            )}

            {!isAutogenerated && (
              <LabelFrame style={{ marginBottom: '4px' }}>
                <Typography variant='subtitle2' sx={{ display: 'flex', alignItems: 'center' }}>
                  Адрес проведения поверки
                  {!!customerAddress ? (
                    <CheckCircleIcon
                      sx={{
                        color: '#63E6BE',
                        marginLeft: '8px',
                        marginTop: '-0px',
                      }}
                    />
                  ) : (
                    <VisibilityToggleIcon
                      sx={{
                        color: '#FFD43B',
                        marginLeft: '8px',
                        marginTop: '-0px',
                      }}
                    />
                  )}
                </Typography>

                <AsyncSelector
                  callback={changeAddress}
                  optionKey={'suggestions'}
                  options={async param =>
                    R.addresses(
                      param,
                      regionValue.map(regionId => ({ kladr_id: regionId })),
                    )
                  }
                  value={customerAddress}
                />
              </LabelFrame>
            )}

            <ContentFrame>
              <LabelFrame sx={{ width: '32.8%' }}>
                <DatePicker
                  selectedDate={verificationDate}
                  dateChange={handleVerificationDateChange}
                  label={'Плановая дата поверки'}
                  isRequired={true}
                />
              </LabelFrame>
              <LabelFrame sx={{ width: '32.8%' }}>
                <DatePicker
                  selectedDate={createdAt}
                  dateChange={handleCreatedAtChange}
                  label={'Дата заявки'}
                  isRequired={true}
                />
              </LabelFrame>

              {!isAutogenerated && (
                <LabelFrame sx={{ width: '32.8%' }}>
                  <Typography variant='subtitle2' sx={{ display: 'flex', alignItems: 'center' }}>
                    Отметка о поверке
                    {isCheckedMark !== '' ? (
                      <CheckCircleIcon
                        sx={{
                          color: '#63E6BE',
                          marginLeft: '8px',
                          marginTop: '-0px',
                        }}
                      />
                    ) : (
                      <VisibilityToggleIcon
                        sx={{
                          color: '#FFD43B',
                          marginLeft: '8px',
                          marginTop: '-0px',
                        }}
                      />
                    )}
                  </Typography>
                  <RadioButton
                    value={isCheckedMark}
                    onChange={changeIsChecked}
                    trueLabel={'Присутствует'}
                    falseLabel={'Отсутствует'}
                    trueValue={true}
                    falseValue={false}
                  />
                </LabelFrame>
              )}

              {isAutogenerated && (
                <LabelFrame sx={{ width: '32.8%' }}>
                  <Input
                    notRequired={true}
                    label={'Примечание к записи'}
                    placeholder={'Примечание к записи в журнале'}
                    type={'text'}
                    value={comment}
                    actions={{
                      change: handleCommentChange,
                    }}
                  />
                </LabelFrame>
              )}
            </ContentFrame>

            {!isAutogenerated && (
              <ContentFrame>
                <LabelFrame sx={{ width: '32.8%' }}>
                  <Input
                    notRequired={true}
                    label={'Номер договора'}
                    placeholder={'Напишите номер договора'}
                    type={'text'}
                    value={numberAgreement}
                    actions={{
                      change: handleNumberAgreementChange,
                    }}
                  />
                </LabelFrame>
                <LabelFrame sx={{ width: '32.8%' }}>
                  <Input
                    notRequired={true}
                    label={'Примечание к записи'}
                    placeholder={'Примечание к записи в журнале'}
                    type={'text'}
                    value={comment}
                    actions={{
                      change: handleCommentChange,
                    }}
                  />
                </LabelFrame>
              </ContentFrame>
            )}
          </Content>

          <Button
            onClick={handleSave}
            label='Редактировать запись'
            disabled={isDisabledBtn}
            color='primary'
            fullWidth
          />
        </Wrapper>
      )}
    </main>
  )
}

export default Edit
