import { useContext, useEffect } from 'react'
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom'

interface Transition {
  retry: () => void
}

type Blocker = (tx: Transition) => void

interface NavigatorWithBlock extends Navigator {
  block(prompt: (tx: Transition) => void): () => void
}

interface NavigationContextType {
  navigator: NavigatorWithBlock
}

const useBlocker = (blocker: Blocker, when: boolean = true): void => {
  const { navigator } = useContext(NavigationContext) as unknown as NavigationContextType

  useEffect(() => {
    if (!when) return

    const unblock = navigator.block((tx: Transition) => {
      const autoUnblockingTx: Transition = {
        ...tx,
        retry() {
          unblock()
          tx.retry()
        },
      }

      blocker(autoUnblockingTx)
    })

    return unblock
  }, [navigator, blocker, when])
}

export default useBlocker
