// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useState, useEffect, useCallback, useMemo } from 'react'
import AsyncSelector from '../../../LocalElements/Selector/reactSelect/Selector.async.jsx'
import OptionsInputAsync from '../../../LocalElements/Selector/reactSelect/OptionsInput.async.tsx'
import { Typography } from '@mui/material'
import Input from '../../../../../ui/input/index.tsx'
import RadioButton from '../../../../../ui/radio-button/index.jsx'
import { Wrapper, Container, LabelFrame33, OwnedSi, LabelFrame70 } from '../../styles.ts'
import R from '../../../../../services/app/client-server/request.service'
import useWindowDimensions from '../../../../hooks/useWindowDimensions.js'

const Customer = props => {
  const {
    valueName,
    changeName,
    valueNameType,
    changeNameType,
    valueFullName,
    changeFullName,
    valueAddress,
    changeAddress,
    valuePhone,
    changePhone,
    valueAgreement,
    changeAgreement,
  } = props

  const [tariffIds, setTariffIds] = useState([])
  const [regionValue, setRegionValue] = useState([])

  const { width } = useWindowDimensions()

  useEffect(() => {
    const fetchUserRegions = async () => {
      const { data } = await R.getUserRegions()
      setRegionValue(data.regions.map(region => region.id))

      const tariffs = data.tariffs.map(tariff => tariff.id)
      setTariffIds(tariffs.length === 0 ? null : tariffs)
    }
    fetchUserRegions()
  }, [])

  const fetchAddresses = useCallback(
    async param => {
      return R.addresses(
        param,
        regionValue.map(regionId => ({ kladr_id: regionId })),
      )
    },
    [regionValue],
  )

  const addressLabel = useMemo(
    () => ({
      text: 'Адрес проведения поверки',
      topBg: 'white',
      bottomBg: 'white',
    }),
    [],
  )

  return (
    <Wrapper>
      <Typography variant='h2'>Информация о заказчике</Typography>
      <Container>
        <OwnedSi>
          <Input
            label={'Владелец СИ в системе Аршин'}
            type={'text'}
            placeholder={'Выберите тип лица или введите ФИО'}
            value={valueName}
            actions={{ change: changeName }}
          />
          <RadioButton
            value={valueNameType}
            onChange={changeNameType}
            trueLabel={'Физическое лицо'}
            falseLabel={'Юридическое лицо'}
            trueValue={'Физическое лицо'}
            falseValue={'Юридическое лицо'}
          />
        </OwnedSi>
        <LabelFrame70>
          <OptionsInputAsync
            callback={changeAddress}
            optionKey={'suggestions'}
            options={fetchAddresses}
            value={valueAddress}
            label={addressLabel}
            isRequired={true}
          />
        </LabelFrame70>
      </Container>

      {tariffIds && tariffIds.includes(6) && (
        <Container>
          <LabelFrame33>
            <Input
              type={'text'}
              placeholder={'Введите ФИО'}
              label={'ФИО заказчика (необязательно)'}
              notRequired={true}
              value={valueFullName}
              actions={{ change: changeFullName }}
            />
          </LabelFrame33>
          <LabelFrame33>
            <Input
              type={'text'}
              placeholder={'+7(XXX)XXX-XX-XX'}
              label={'Телефон заказчика (необязательно)'}
              notRequired={true}
              value={valuePhone}
              actions={{ change: changePhone }}
            />
          </LabelFrame33>
          <LabelFrame33>
            <Input
              label={'Номер договора (необязательно)'}
              placeholder={'Пример ввода - 00000-00'}
              type={'text'}
              notRequired={true}
              value={valueAgreement}
              actions={{ change: changeAgreement }}
            />
          </LabelFrame33>
        </Container>
      )}
    </Wrapper>
  )
}

export default Customer
