// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import Button from '@mui/material/Button'
import { format } from 'date-fns'

import api from '../../../../../services/app/client-server/axios-config.service'
import Cookies from 'js-cookie'

import MeterData from '../../views/MeterData/MeterGas/MeterGas'
import Customer from '../../views/Customer/Customer'
import Accepted from '../../views/Accepted/Accepted'
import Conditions from './ProtocolComponents/Condition'
import IndicationsFull from './ProtocolComponents/IndicationsFull'
import IndicationsShort from './ProtocolComponents/IndicationsShort'
import DescriptionType from './ProtocolComponents/DescriptionType'
import TotalAccepted from '../../views/TotalAccepted/TotalAccepted'
import ProtocolDate from '../../views/ProtocolDate/ProtocolDate'
import ImageUpload from '../../views/ImagesUploading/ImagesUploading'

import { changeProperty, setDefaultState } from './state'

import { refreshProtocols } from '../../../../../store/slices/app/controlers/updater'
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from '../../../../../store/slices/app/comps/snackbar'

import css from '../../styles.css'

const { PageContainer, BoxContainer } = css

const AddProtocol = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isDisabledBtn, setIsDisabledBtn] = useState(false)
  const [validationErrors, setValidationErrors] = useState({})

  const permissiblePass = useSelector(state => state.gost83242002Reducer.permissiblePass)
  const token = useSelector(state => state.authReducer.authData.token)

  const capitalizeFirstLetter = input => {
    return input.charAt(0).toUpperCase() + input.slice(1)
  }

  const caseId = useSelector(state => state.protocolReducer.case.caseId)
  const customerName = useSelector(state => state.gost83242002Reducer.customerName)
  const customerNameType = useSelector(state => state.gost83242002Reducer.customerNameType)
  const customerAddress = useSelector(state => state.gost83242002Reducer.customerAddress)
  const customerFullName = useSelector(state => state.gost83242002Reducer.customerFullName)
  const customerPhoneNumber = useSelector(state => state.gost83242002Reducer.customerPhoneNumber)

  const typeSize = useSelector(state => state.gost83242002Reducer.typeSize)
  const q_min = useSelector(state => state.gost83242002Reducer.q_min)
  const q_t = useSelector(state => state.gost83242002Reducer.q_t)
  const q_max = useSelector(state => state.gost83242002Reducer.q_max)

  const q_min_limit = useSelector(state => state.gost83242002Reducer.q_min_limit)
  const q_max_limit = useSelector(state => state.gost83242002Reducer.q_max_limit)
  const q_min_pressure_loss = useSelector(state => state.gost83242002Reducer.q_min_pressure_loss)
  const q_max_pressure_loss = useSelector(state => state.gost83242002Reducer.q_max_pressure_loss)
  const isMechanicalAccepted = useSelector(state => state.gost83242002Reducer.isMechanicalAccepted)
  const isTightnessAccepted = useSelector(state => state.gost83242002Reducer.isTightnessAccepted)
  const isMarkPassport = useSelector(state => state.gost83242002Reducer.isMarkPassport)
  const isMarkSi = useSelector(state => state.gost83242002Reducer.isMarkSi)
  const verificationDate = useSelector(state => state.gost83242002Reducer.verificationDate)
  const nextVerificationDate = useSelector(state => state.gost83242002Reducer.nextVerificationDate)
  const agreementNumber = useSelector(state => state.gost83242002Reducer.agreementNumber)
  const isTotalAccepted = useSelector(state => state.gost83242002Reducer.isTotalAccepted)

  const conditions = useSelector(state => state.gost83242002Reducer.conditions)
  const indications = useSelector(state => state.gost83242002Reducer.indications)
  const indicationPass = useSelector(state => state.gost83242002Reducer.indicationPass)

  const changeName = param => {
    const newValue = capitalizeFirstLetter(param)
    dispatch(changeProperty({ value: 'customerName', label: newValue }))
  }

  const changeNameType = event => {
    dispatch(changeProperty({ value: 'customerNameType', label: event.target.value }))
    dispatch(changeProperty({ value: 'customerName', label: event.target.value }))
  }

  const changeAddress = selectedOption => {
    dispatch(
      changeProperty({
        value: 'customerAddress',
        label: selectedOption ? selectedOption.value : null,
      }),
    )
  }

  const changeFullName = param => {
    const newValue = capitalizeFirstLetter(param)
    dispatch(changeProperty({ value: 'customerFullName', label: newValue }))
  }

  const formatPhoneNumber = input => {
    const phoneNumber = input.replace(/\D/g, '')
    let formattedPhoneNumber = ''

    if (phoneNumber.length >= 1) {
      formattedPhoneNumber = `+7`
    }
    if (phoneNumber.length > 1) {
      formattedPhoneNumber += ` (${phoneNumber.slice(1, 4)}`
    }
    if (phoneNumber.length > 4) {
      formattedPhoneNumber += `) ${phoneNumber.slice(4, 7)}`
    }
    if (phoneNumber.length > 7) {
      formattedPhoneNumber += `-${phoneNumber.slice(7, 9)}`
    }
    if (phoneNumber.length > 9) {
      formattedPhoneNumber += `-${phoneNumber.slice(9, 11)}`
    }

    dispatch(
      changeProperty({
        value: 'customerPhoneNumber',
        label: formattedPhoneNumber,
      }),
    )
  }
  const changePhone = param => {
    formatPhoneNumber(param)
  }

  const changeMechanical = event => {
    const newValue = event.target.value === 'true'
    dispatch(
      changeProperty({
        value: 'isMechanicalAccepted',
        label: newValue,
      }),
    )
  }

  const changeTightness = event => {
    const newValue = event.target.value === 'true'
    dispatch(
      changeProperty({
        value: 'isTightnessAccepted',
        label: newValue,
      }),
    )
  }

  const handleMarkPassport = event => {
    dispatch(
      changeProperty({
        value: 'isMarkPassport',
        label: event.target.checked,
      }),
    )
  }

  const handleMarkSi = event => {
    dispatch(
      changeProperty({
        value: 'isMarkSi',
        label: event.target.checked,
      }),
    )
  }

  const handleDateChange = date => {
    if (date) {
      dispatch(
        changeProperty({
          value: 'verificationDate',
          label: date.format('YYYY-MM-DD HH:mm:ss'),
        }),
      )
    }
  }

  const handleAgreementChange = param => {
    dispatch(changeProperty({ value: 'agreementNumber', label: param }))
  }

  useEffect(() => {
    const totalAccepted =
      isMechanicalAccepted && isTightnessAccepted && !indicationPass.includes(false)

    dispatch(changeProperty({ value: 'isTotalAccepted', label: totalAccepted }))
  }, [isMechanicalAccepted, isTightnessAccepted, indicationPass])

  const handleSaveProtocol = async () => {
    try {
      setIsDisabledBtn(true)

      const apiUrl = `${process.env.REACT_APP_API_URL}/api/protocols`
      const headers = {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      }

      const data = {
        case_id: caseId,
        customer_arshin_name: customerName,
        customer_address: customerAddress,
        customer_fullname: customerFullName,
        customer_phone: customerPhoneNumber,
        accepted_at: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
        total_accepted: isTotalAccepted,
        mechanical_accepted: isMechanicalAccepted,
        tightness_accepted: isTightnessAccepted,
        mark_passport: isMarkPassport,
        mark_si: isMarkSi,
        indications: indications,
        conditions: conditions,
        verification_date: verificationDate,
        next_verification_date: nextVerificationDate,
        agreement_number: agreementNumber,

        meterable_id: 1,

        details: {
          meter_factory_number: 1,
          meter_factory_year: 2002,
          q_min: q_min,
          q_t: q_t,
          q_max: q_max,
          q_min_limit: q_min_limit,
          q_max_limit: q_max_limit,
          size: typeSize,
        },
      }

      const request = {
        url: apiUrl,
        method: 'POST',
        payload: data,
        headers: headers,
      }

      if ('serviceWorker' in navigator && 'SyncManager' in window) {
        if (!navigator.onLine) {
          navigator.serviceWorker.controller.postMessage({
            form_data: request,
          })
          navigator.serviceWorker.ready.then(function (registration) {
            return registration.sync.register('sendFormData')
          })
          dispatch(setType('success'))
          dispatch(setOpenAlert(true))
          dispatch(
            setMessage(
              'Протокол успешно создан. Данные будут отправлены на сервер, когда интернет появится.',
            ),
          )
          navigate('/metriva/protocols')
        } else {
          await api.post(apiUrl, data, { headers })
          dispatch(setMessage('Протокол создан'))
          dispatch(refreshProtocols())
          dispatch(setType('success'))
          dispatch(setOpenAlert(true))
          navigate('/metriva/protocols')
        }
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        setValidationErrors(error.response.data.errors)
        dispatch(setType('error'))
        dispatch(setOpenAlert(true))
        dispatch(setMessage('Ошибка валидации.'))
        console.error(error)
        setIsDisabledBtn(false)
      }
    }
  }

  return (
    <PageContainer>
      <BoxContainer margin={'14px 0px 14px'}>
        <Customer
          valueName={customerName}
          changeName={changeName}
          valueNameType={customerNameType}
          changeNameType={changeNameType}
          valueAddress={customerAddress}
          changeAddress={changeAddress}
          valueFullName={customerFullName}
          changeFullName={changeFullName}
          valuePhone={customerPhoneNumber}
          changePhone={changePhone}
        />
      </BoxContainer>

      <BoxContainer>
        <MeterData />
      </BoxContainer>

      <BoxContainer>
        <DescriptionType />
      </BoxContainer>

      <BoxContainer>
        <Accepted
          title={'Внешний осмотр'}
          description={
            'Наличие товарного знака предприятия-изготовителя, а также надписей с указанием типа, порядкового номера, года изготовления, наибольшего избыточного давления, максимального и минимального значений расхода.'
          }
          label={'Соответствие требованиям п. 9.1 методики поверки'}
          value={isMechanicalAccepted}
          onChange={changeMechanical}
        />
      </BoxContainer>

      <BoxContainer>
        <Accepted
          title={'Опробование'}
          description={
            'Счетчик работает устойчиво, без рывков, заеданий, посторонних шумов. Показания отсчетного устройства равномерно увеличиваются.'
          }
          label={'Соответствие требованиям п. 9.2 методики поверки'}
          value={isTightnessAccepted}
          onChange={changeTightness}
        />
      </BoxContainer>

      <BoxContainer>
        <Conditions />
      </BoxContainer>

      <BoxContainer>{permissiblePass ? <IndicationsShort /> : <IndicationsFull />}</BoxContainer>

      <BoxContainer
        style={{
          backgroundColor: 'transparent',
          padding: '0px',
          border: 'none',
        }}
      >
        <TotalAccepted value={isTotalAccepted} />
      </BoxContainer>

      <BoxContainer>
        <ProtocolDate
          isTotalAccepted={isTotalAccepted}
          valuePassport={isMarkPassport}
          onChangePassport={handleMarkPassport}
          valueSi={isMarkSi}
          onChangeSi={handleMarkSi}
          currentDate={verificationDate}
          changeCurrentDate={handleDateChange}
          nextDate={nextVerificationDate}
          valueAgreement={agreementNumber}
          changeAgreement={handleAgreementChange}
        />
      </BoxContainer>

      <Button
        variant='contained'
        onClick={handleSaveProtocol}
        disabled={isDisabledBtn}
        sx={{ mt: 1, mr: 1 }}
        style={{
          textTransform: 'none',
          fontSize: '15px',
          fontFamily: '"Wix Madefor Display", sans-serif',
          padding: '11px 20px 11px',
          margin: 0,
          borderRadius: '12px',
          width: '100%',
        }}
      >
        Сохранить протокол
      </Button>
    </PageContainer>
  )
}

export default AddProtocol
