// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Typography } from '@mui/material'
import Selector from '../../../../LocalElements/Selector/reactSelect/Selector.jsx'
import Input from '../../../../../../ui/input/index.tsx'
import RadioButton from '../../../../../../ui/radio-button/index.jsx'
import { changeProperty } from '../state.js'
import { Wrapper, Container, LabelFrame33, LabelFrame50, SingleDiameter } from '../style.ts'
import CheckCircleIcon from '../../../../../../img/icon/CheckCircleIcon.jsx'
import VisibilityToggleIcon from '../../../../../../img/icon/VisibilityToggleIcon.jsx'

const DescriptionType = () => {
  const dispatch = useDispatch()

  const pointId = useSelector(state => state.protocolReducer.case.pointId)

  const selectedMod = useSelector(state => state.gost8101222Reducer.selectedMod)
  const defaultData = useSelector(state => state.gost8101222Reducer.defaultData)

  const diameter = useSelector(state => state.gost8101222Reducer.diameter)
  const meterType = useSelector(state => state.gost8101222Reducer.meterType)
  const meterLocation = useSelector(state => state.gost8101222Reducer.meterLocation)

  const q_min = useSelector(state => state.gost8101222Reducer.q_min)
  const q_t = useSelector(state => state.gost8101222Reducer.q_t)
  const q_max = useSelector(state => state.gost8101222Reducer.q_max)
  const q_min_limit = useSelector(state => state.gost8101222Reducer.q_min_limit)
  const q_max_limit = useSelector(state => state.gost8101222Reducer.q_max_limit)

  const meterTypeData = [10, 15, 20]
  const options = meterTypeData
    ? meterTypeData.map(item => ({
        value: item,
        label: item,
      }))
    : []

  const getValue = selectedOption => {
    dispatch(
      changeProperty({
        value: 'diameter',
        label: selectedOption ? selectedOption.value : null,
      }),
    )
  }

  const handleMeterTypeChange = e => {
    dispatch(changeProperty({ value: 'meterType', label: e.target.value }))
  }

  const handleMeterLocationChange = e => {
    dispatch(changeProperty({ value: 'meterLocation', label: e.target.value }))
  }

  const handleQMinChange = param => {
    dispatch(changeProperty({ value: 'q_min', label: param.replace(',', '.') }))
  }

  const handleQtChange = param => {
    dispatch(changeProperty({ value: 'q_t', label: param.replace(',', '.') }))
  }

  const handleQMaxChange = param => {
    dispatch(changeProperty({ value: 'q_max', label: param.replace(',', '.') }))
  }

  const handleQMinLimitChange = param => {
    dispatch(changeProperty({ value: 'q_min_limit', label: param.replace(',', '.') }))
  }

  const handleQMaxLimitChange = param => {
    dispatch(changeProperty({ value: 'q_max_limit', label: param.replace(',', '.') }))
  }

  useEffect(() => {
    if (selectedMod !== null) {
      if (selectedMod && selectedMod.diameter) {
        dispatch(changeProperty({ value: 'diameter', label: selectedMod.diameter }))
      }

      if (selectedMod && selectedMod.type === 'hot') {
        dispatch(changeProperty({ value: 'meterType', label: 'hot' }))
      } else if (selectedMod && selectedMod.type === 'cold') {
        dispatch(changeProperty({ value: 'meterType', label: 'cold' }))
      }

      if (selectedMod && selectedMod.q_min_vertical && meterLocation === 'vertical') {
        const q_min = parseFloat(selectedMod.q_min_vertical).toFixed(2)
        const q_t = parseFloat(selectedMod.q_t_vertical).toFixed(2)
        const q_max = parseFloat(selectedMod.q_max_vertical).toFixed(2)
        dispatch(changeProperty({ value: 'q_min', label: q_min }))
        dispatch(changeProperty({ value: 'q_t', label: q_t }))
        dispatch(changeProperty({ value: 'q_max', label: q_max }))
      }
      if (selectedMod && selectedMod.q_min_horizontal && meterLocation === 'horizontal') {
        const q_min = parseFloat(selectedMod.q_min_horizontal).toFixed(2)
        const q_t = parseFloat(selectedMod.q_t_horizontal).toFixed(2)
        const q_max = parseFloat(selectedMod.q_max_horizontal).toFixed(2)
        dispatch(changeProperty({ value: 'q_min', label: q_min }))
        dispatch(changeProperty({ value: 'q_t', label: q_t }))
        dispatch(changeProperty({ value: 'q_max', label: q_max }))
      }

      if (selectedMod && defaultData && meterType === 'cold') {
        const q_min_limit = parseFloat(defaultData.q_min_limit_cold)
        const q_max_limit = parseFloat(defaultData.q_max_limit_cold)
        dispatch(changeProperty({ value: 'q_min_limit', label: q_min_limit }))
        dispatch(changeProperty({ value: 'q_max_limit', label: q_max_limit }))
      }

      if (selectedMod && defaultData && meterType === 'hot') {
        const q_min_limit = parseFloat(defaultData.q_min_limit_hot)
        const q_max_limit = parseFloat(defaultData.q_max_limit_hot)
        dispatch(changeProperty({ value: 'q_min_limit', label: q_min_limit }))
        dispatch(changeProperty({ value: 'q_max_limit', label: q_max_limit }))
      }
    }
  }, [selectedMod, defaultData, meterLocation, meterType, dispatch])

  return (
    <Wrapper>
      <Typography variant='h2'>
        Метрологические и технические характеристики по описанию типа
      </Typography>

      <Container>
        <LabelFrame33>
          <Typography
            fontSize={14}
            style={{
              display: 'flex',
              alignItems: 'center',
              paddingLeft: '12px',
            }}
          >
            Диаметр условного прохода, мм
            {!diameter ? (
              <VisibilityToggleIcon
                sx={{
                  color: '#FFD43B',
                  marginLeft: '8px',
                  marginTop: '-0px',
                }}
              />
            ) : (
              <CheckCircleIcon sx={{ color: '#63E6BE', marginLeft: '8px', marginTop: '0px' }} />
            )}
          </Typography>
          {selectedMod ? (
            <SingleDiameter>{diameter}</SingleDiameter>
          ) : (
            <Selector
              placeholder={'Выберите диаметр условного прохода'}
              options={options}
              onChange={getValue}
              value={diameter ? options.find(option => option.value === diameter) : null}
              isClearable={true}
              isSearchable={true}
            />
          )}
        </LabelFrame33>

        <LabelFrame33 style={{ height: '86px', gap: '16px' }}>
          <Typography
            fontSize={14}
            style={{
              display: 'flex',
              alignItems: 'center',
              paddingLeft: '12px',
            }}
          >
            Расположение прибора
            {!meterLocation ? (
              <VisibilityToggleIcon
                sx={{
                  color: '#FFD43B',
                  marginLeft: '8px',
                  marginTop: '-0px',
                }}
              />
            ) : (
              <CheckCircleIcon sx={{ color: '#63E6BE', marginLeft: '8px', marginTop: '0px' }} />
            )}
          </Typography>
          <RadioButton
            value={meterLocation}
            onChange={handleMeterLocationChange}
            trueLabel={'Вертикальное'}
            falseLabel={'Горизонтальное'}
            trueValue={'vertical'}
            falseValue={'horizontal'}
          />
        </LabelFrame33>

        <LabelFrame33 style={{ height: '86px', gap: '16px' }}>
          <Typography
            fontSize={14}
            style={{
              display: 'flex',
              alignItems: 'center',
              paddingLeft: '12px',
            }}
          >
            Измеряемая вода
            {!meterType ? (
              <VisibilityToggleIcon
                sx={{
                  color: '#FFD43B',
                  marginLeft: '8px',
                  marginTop: '-0px',
                }}
              />
            ) : (
              <CheckCircleIcon sx={{ color: '#63E6BE', marginLeft: '8px', marginTop: '0px' }} />
            )}
          </Typography>
          <RadioButton
            value={meterType}
            onChange={handleMeterTypeChange}
            trueLabel={'Холодная'}
            falseLabel={'Горячая'}
            trueValue={'cold'}
            falseValue={'hot'}
          />
        </LabelFrame33>
      </Container>

      <Container>
        <LabelFrame33>
          <Input
            label={
              pointId === 4 || pointId === 5
                ? 'Минимальный расход (Qmin), м³/ч'
                : 'Qmin (описание типа), м³/ч'
            }
            placeholder={'0.000'}
            value={q_min}
            actions={{
              change: handleQMinChange,
            }}
          />
        </LabelFrame33>

        <LabelFrame33>
          <Input
            label={
              pointId === 4 || pointId === 5
                ? 'Переходный расход (Qп), м³/ч'
                : 'Qt (описание типа), м³/ч'
            }
            placeholder={'0.000'}
            value={q_t}
            actions={{
              change: handleQtChange,
            }}
          />
        </LabelFrame33>

        <LabelFrame33>
          <Input
            label={
              pointId === 4 || pointId === 5
                ? 'Максимальный расход (Qmax), м³/ч'
                : 'Qmax (описание типа), м³/ч'
            }
            placeholder={'0.000'}
            value={q_max}
            actions={{
              change: handleQMaxChange,
            }}
          />
        </LabelFrame33>
      </Container>

      <Container>
        <LabelFrame50>
          <Input
            label={
              pointId === 4 || pointId === 5
                ? 'Пределы допуск.погрешности от Qmin до 1.1Qп, %'
                : 'Предел допуск. погрешности от Qmin до Qt, %'
            }
            placeholder={'± 0.00'}
            value={q_min_limit}
            actions={{
              change: handleQMinLimitChange,
            }}
          />
        </LabelFrame50>

        <LabelFrame50>
          <Input
            label={
              pointId === 4 || pointId === 5
                ? 'Пределы допуск.погрешности от 1.1Qп до Qmax, %'
                : 'Предел допуск. погрешности от Qt до Qmax, %'
            }
            placeholder={'± 0.00'}
            value={q_max_limit}
            actions={{
              change: handleQMaxLimitChange,
            }}
          />
        </LabelFrame50>
      </Container>
    </Wrapper>
  )
}

export default DescriptionType
