import * as React from 'react'
import { useDispatch } from 'react-redux'
import { setStepNumber } from '../../../../store/slices/app/views/protocols'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import MobileStepper from '@mui/material/MobileStepper'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'

const Stepper = ({ steps }) => {
  const dispatch = useDispatch()
  const theme = useTheme()

  const [activeStep, setActiveStep] = React.useState(0)
  const maxSteps = steps.length

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  React.useEffect(() => {
    dispatch(setStepNumber(activeStep))
  }, [activeStep, dispatch])

  return (
    <Box>
      {steps[activeStep].label && (
        <Paper
          square
          elevation={0}
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: 50,
            pl: 2,
            bgcolor: 'background.default',
          }}
        >
          <Typography>{steps[activeStep].label}</Typography>
        </Paper>
      )}
      <Box sx={{ width: '100%' }}>{steps[activeStep].description}</Box>
      <MobileStepper
        style={{
          marginTop: '0px',
          marginBottom: '14px',
          backgroundColor: 'transparent',
        }}
        variant='dots'
        steps={maxSteps}
        position='static'
        activeStep={activeStep}
        nextButton={
          <Button
            size='small'
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
            style={{ textTransform: 'none', fontSize: '16px' }}
          >
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft sx={{ transform: 'scale(130%)' }} />
            ) : (
              <KeyboardArrowRight sx={{ transform: 'scale(130%)' }} />
            )}
          </Button>
        }
        backButton={
          <Button
            size='small'
            onClick={handleBack}
            disabled={activeStep === 0}
            style={{ textTransform: 'none', fontSize: '16px' }}
          >
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight sx={{ transform: 'scale(130%)' }} />
            ) : (
              <KeyboardArrowLeft sx={{ transform: 'scale(130%)' }} />
            )}
          </Button>
        }
      />
    </Box>
  )
}

export default Stepper
