import axios from './axios-config.service'
import Cookies from 'js-cookie'
class Request {
  // ----------------------------------------------------------------
  // #1 авторизация и регистрация
  // ----------------------------------------------------------------

  #authUrl = `${process.env.REACT_APP_API_URL}/api/auth/login`

  // ----------------------------------------------------------------
  // #2 dadata - получение списка адресов
  // ----------------------------------------------------------------

  #addressUrl = `https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address`

  // ----------------------------------------------------------------
  // #3 информация о тарифах
  // ----------------------------------------------------------------

  #tariffsUrl = `${process.env.REACT_APP_API_URL}/api/tariffs`
  #subscriptionUrl = `${process.env.REACT_APP_API_URL}/api/subscriptions`
  #tariffsUrlExtra = `${process.env.REACT_APP_API_URL}/api/tariffs/services_rate`

  // ----------------------------------------------------------------
  // #4 подключение lanbilling
  // ----------------------------------------------------------------

  #billingUrlPostPayment = `${process.env.REACT_APP_API_URL}/api/billing`
  #billingUrlPrePayment = `${process.env.REACT_APP_API_URL}/api/billing/prepay`

  // ----------------------------------------------------------------
  // #5 компании
  // ----------------------------------------------------------------

  #companiesUrl = `${process.env.REACT_APP_API_URL}/api/companies`
  #companiesStatusUrl = companyId =>
    `${process.env.REACT_APP_API_URL}/api/companies/${companyId}/activate`
  #companiesUrlList = `${process.env.REACT_APP_API_URL}/api/companies/list`
  #companiesUrlItem = companyId =>
    `${process.env.REACT_APP_API_URL}/api/companies/${companyId}/edit`
  #companiesUrlEdit = companyId =>
    `${process.env.REACT_APP_API_URL}/api/companies/${companyId}/update`

  // ----------------------------------------------------------------
  // #6 комплекты сп
  // ----------------------------------------------------------------

  #casesUrl = `${process.env.REACT_APP_API_URL}/api/cases`
  #casesUrlItem = `${process.env.REACT_APP_API_URL}/api/cases/list`
  #casesUrlEdit = caseId => `${process.env.REACT_APP_API_URL}/api/cases/${caseId}`
  #casesUrlPut = caseId => `${process.env.REACT_APP_API_URL}/api/cases/${caseId}`
  #mainCaseUrl = caseId => `${process.env.REACT_APP_API_URL}/api/cases/set-main/${caseId}`

  // ----------------------------------------------------------------
  // #7 мастера аршина
  // ----------------------------------------------------------------

  #masterArshinUrl = `${process.env.REACT_APP_API_URL}/api/arshin-masters`
  #masterUrlPut = masterId => `${process.env.REACT_APP_API_URL}/api/arshin-masters/${masterId}`
  #masterUrlItem = masterId =>
    `${process.env.REACT_APP_API_URL}/api/arshin-masters/${masterId}/edit`
  #masterUrl = `${process.env.REACT_APP_API_URL}/api/arshin-masters/list`

  // ----------------------------------------------------------------
  // #8 неклассифицированная группа
  // ----------------------------------------------------------------

  #measurementUrlItem = `${process.env.REACT_APP_API_URL}/api/measurements/list`
  #methodUrlItem = `${process.env.REACT_APP_API_URL}/api/methods/list`
  #typeUrlItem = `${process.env.REACT_APP_API_URL}/api/verification-type/list`
  #pointUrlItem = `${process.env.REACT_APP_API_URL}/api/points/list`

  // ----------------------------------------------------------------
  // #9 база приборов
  // ----------------------------------------------------------------

  #metersUrlList = `${process.env.REACT_APP_API_URL}/api/meters/list`
  #metersUrl = `${process.env.REACT_APP_API_URL}/api/meters`
  #metersUrlWithParams = (measurementId, searchQuery) =>
    `${process.env.REACT_APP_API_URL}/api/meters?measurement_id=${measurementId}&search=${searchQuery}`

  // ----------------------------------------------------------------
  // #10 журналы verifications-shedule
  // ----------------------------------------------------------------

  #verificationsUrl = `${process.env.REACT_APP_API_URL}/api/journals/verifications-shedule`
  #verificationsUrlEdit = journalId =>
    `${process.env.REACT_APP_API_URL}/api/journals/verifications-shedule/${journalId}`
  #verificationsUrlItem = journalId =>
    `${process.env.REACT_APP_API_URL}/api/journals/verifications-shedule/${journalId}/edit`

  // ----------------------------------------------------------------
  // #11 журналы tickets
  // ----------------------------------------------------------------

  #ticketsUrl = companyId => `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/tickets`
  #ticketsDeleteUrl = (companyId, journalId) =>
    `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/tickets/${journalId}`

  #ticketsArchiveUrl = companyId =>
    `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/tickets/archive`

  #ticketsUrlEdit = (journalId, companyId) =>
    `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/tickets/${journalId}`

  #ticketsUrlItem = (journalId, companyId) =>
    `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/tickets/${journalId}/edit`

  // ----------------------------------------------------------------
  // #12 журналы personal-metrolog
  // ----------------------------------------------------------------

  #personalUrl = (companyId, arshinMasterId) =>
    `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/personal-metrolog/${arshinMasterId}`
  #personalArchiveUrl = (companyId, arshinMasterId) =>
    `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/personal-metrolog/${arshinMasterId}/archive`
  #personalUrlEdit = (journalId, companyId, arshinMasterId) =>
    `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/personal-metrolog/${arshinMasterId}/${journalId}`
  #personalUrlItem = (journalId, companyId, arshinMasterId) =>
    `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/personal-metrolog/${arshinMasterId}/${journalId}/edit`
  #personalUrlPdf = (companyId, arshinMasterId) =>
    `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/personal-metrolog/${arshinMasterId}/pdf`

  // ----------------------------------------------------------------
  // #13 журналы conditions-of-cases
  // ----------------------------------------------------------------

  #conditionsUrl = companyId =>
    `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/conditions-of-cases`

  #conditionsUrlEdit = (journalId, companyId) =>
    `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/conditions-of-cases/${journalId}`

  #conditionsUrlItem = (journalId, companyId) =>
    `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/conditions-of-cases/${journalId}/edit`

  #archiveConditionsUrl = companyId =>
    `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/conditions-of-cases/archive`

  // ----------------------------------------------------------------
  // #14 журналы issue-cases
  // ----------------------------------------------------------------

  #issueCaseUrl = companyId =>
    `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/issue-cases`

  #issueCaseArchiveUrl = companyId =>
    `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/issue-cases/archive`

  #issueCaseUrlEdit = (journalId, companyId) =>
    `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/issue-cases/${journalId}`

  #issueCaseUrlItem = (journalId, companyId) =>
    `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/issue-cases/${journalId}/edit`

  #receivedByNameList = companyId =>
    `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/issue-cases/received-by-name-list`

  #issueCaseUrlReturn = (journalId, companyId) =>
    `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/issue-cases/return/${journalId}`

  // ----------------------------------------------------------------
  // #15 журналы kit-verifications
  // ----------------------------------------------------------------

  #kitVerificationsUrl = companyId =>
    `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/kit-verifications`

  #kitVerificationsUrlItem = (journalId, companyId) =>
    `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/kit-verifications/${journalId}/edit`

  #kitVerificationsUrlEdit = (journalId, companyId) =>
    `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/kit-verifications/${journalId}`

  #kitVerificationsArchiveUrl = companyId =>
    `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/kit-verifications/archive`

  // ----------------------------------------------------------------
  // журналы kit-passports
  // ----------------------------------------------------------------

  #kitPassportsUrl = companyId =>
    `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/kit-passports`

  #kitPassportsUrlItem = (journalId, companyId) =>
    `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/kit-passports/${journalId}/edit`

  #kitPassportsUrlEdit = (journalId, companyId) =>
    `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/kit-passports/${journalId}`

  #kitPassportsArchiveUrl = (journal_id, companyId) =>
    `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/kit-passports/${journal_id}/archive`

  #kitPassportsPdfUrl = companyId =>
    `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/kit-passports/pdf`

  #responsibleNameListUrl = companyId =>
    `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/kit-passports/responsible-name-list`

  // ----------------------------------------------------------------
  // #14 журналы
  // ----------------------------------------------------------------

  #complectUrl = `${process.env.REACT_APP_API_URL}/api/journals/issue-cases`
  #complectUrlEdit = journalId =>
    `${process.env.REACT_APP_API_URL}/api/journals/issue-cases/${journalId}`
  #complectUrlItem = journalId =>
    `${process.env.REACT_APP_API_URL}/api/journals/issue-cases/${journalId}/edit`

  // ----------------------------------------------------------------
  // #17 журналы accounting-of-verifications
  // ----------------------------------------------------------------

  #receivedUrl = companyId =>
    `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/accounting-of-verifications`
  #receivedArchiveUrl = companyId =>
    `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/accounting-of-verifications/archive`

  #receivedUrlItem = (journalId, companyId) =>
    `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/accounting-of-verifications/${journalId}/edit`

  #receivedUrlEdit = (journalId, companyId) =>
    `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/accounting-of-verifications/${journalId}`

  // ----------------------------------------------------------------
  // #18 журналы archives
  // ----------------------------------------------------------------

  #archiveUrl = companyId => `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/archives`
  #archiveUnarchiveUrl = (companyId, entryUnarchiveId) =>
    `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/archives/${entryUnarchiveId}/unarchive`
  #archiveDeleteUrl = (companyId, entryDeleteId) =>
    `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/archives/${entryDeleteId}/schedule-deletion`
  #archiveEditUrl = (companyId, entryEditId) =>
    `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/archives/${entryEditId}`
  #archiveGetEditUrl = (companyId, entryEditId) =>
    `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/archives/${entryEditId}/edit`

  #archiveEntriesUrl = (companyId, entryId) =>
    `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/archives/${entryId}/entries`

  #archiveCsv = companyId =>
    `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/archives/csv`

  #archiveExcel = companyId =>
    `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/archives/excel`

  #archivePdf = companyId =>
    `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/archives/pdf`
  #archiveEntriesPdf = companyId =>
    `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/archives/entries_pdf`

  // ----------------------------------------------------------------
  // #19 пользователи
  // ----------------------------------------------------------------

  #usersListUrl = `${process.env.REACT_APP_API_URL}/api/users/list`
  #userRegionsUrl = `${process.env.REACT_APP_API_URL}/api/protocols/create`
  #usersUrl = `${process.env.REACT_APP_API_URL}/api/users`
  #usersUrlItem = userId => `${process.env.REACT_APP_API_URL}/api/users/${userId}`
  #usersTrustUrl = userId => `${process.env.REACT_APP_API_URL}/api/users/${userId}/trust`
  #usersBanUrl = userId => `${process.env.REACT_APP_API_URL}/api/users/${userId}/ban`

  // ----------------------------------------------------------------
  // #20 наборы инструментов
  // ----------------------------------------------------------------

  #etalonUrl = `${process.env.REACT_APP_API_URL}/api/kits`
  #kitsList = `${process.env.REACT_APP_API_URL}/api/kits/list`
  #etalonUrlEdit = etalonId => `${process.env.REACT_APP_API_URL}/api/kits/${etalonId}`
  #etalonUrlItem = etalonId => `${process.env.REACT_APP_API_URL}/api/kits/${etalonId}`
  #etalonUrlEditItem = etalonId => `${process.env.REACT_APP_API_URL}/api/kits/${etalonId}/edit`
  #etalonUrlClassesList = `${process.env.REACT_APP_API_URL}/api/kits-accuracy-classes/list`
  #etalonUrlDevicesList = kitGroupId =>
    `${process.env.REACT_APP_API_URL}/api/devices/list?kit_group_ids=${kitGroupId}`
  #kitsAccuracyClassesUrl = `${process.env.REACT_APP_API_URL}/api/kits-accuracy-classes/list`

  // ----------------------------------------------------------------
  // #21 протоколы
  // ----------------------------------------------------------------

  #protocolsUrl = `${process.env.REACT_APP_API_URL}/api/protocols`
  #protocolsUrlEdit = protocol_id => `${process.env.REACT_APP_API_URL}/api/protocols/${protocol_id}`
  #filesUrl = `${process.env.REACT_APP_API_URL}/api/files`
  #protocolsCheck = `${process.env.REACT_APP_API_URL}/api/protocols/sent`
  #protocolsArchive = `${process.env.REACT_APP_API_URL}/api/protocols/archive`
  #protocolsUnArchive = `${process.env.REACT_APP_API_URL}/api/protocols/unarchive`
  #protocolsPdf = `${process.env.REACT_APP_API_URL}/api/protocols/pdf`

  // ----------------------------------------------------------------
  // #22 обновление информации о текущей сессии
  // ----------------------------------------------------------------

  #headerDataUrl = `${process.env.REACT_APP_API_URL}/api/profile/refresh`

  // ----------------------------------------------------------------
  // #23 настройки в различных разделах
  // ----------------------------------------------------------------

  #journalsSettings = company_id =>
    `${process.env.REACT_APP_API_URL}/api/journals/${company_id}/settings`

  // ----------------------------------------------------------------
  // #24 сервер внутренних служб поддержки
  // ----------------------------------------------------------------

  #supportUrlLogs = `${process.env.REACT_APP_SUPPORT_URL}/data`

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  async auth(login, password, callback = () => {}) {
    const response = await axios
      .post(this.#authUrl, {
        login,
        password,
      })
      .catch(error => {
        const { status, data } = error.response
        callback()

        return { status, data }
      })

    const { status, data } = response
    callback()

    return { status, data }
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  async addresses(inputValue, locations) {
    const response = await axios
      .post(
        this.#addressUrl,
        locations
          ? {
              query: inputValue,
              count: 0,
              locations,
            }
          : {
              query: inputValue,
              count: 0,
            },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            // ----------------------------------------------------------------
            // Authorization: `Token 4004411614eac159f915e4ae43989e1565b5ca1b`,
            // ----------------------------------------------------------------
            Authorization: `Token a309be5626b1dabbc052c743113db881ebbcdd80`,
          },
        },
      )
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response

    return { status, data }
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  async subscription() {
    const response = await axios.get(this.#subscriptionUrl).catch(error => {
      console.error(error)
    })

    const { status, data } = response
      ? response
      : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async subscriptionService(route) {
    const response = await axios.get(this.#subscriptionUrl + route).catch(error => {
      console.error(error)
    })

    const { status, data } = response

    return { status, data }
  }

  async tariffs() {
    const response = await axios.get(this.#tariffsUrl).catch(error => {
      console.error(error)
    })

    const { status, data } = response
      ? response
      : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async tariffSingle(route) {
    const response = await axios.get(this.#tariffsUrl + route).catch(error => {
      console.error(error)
    })

    const { status, data } = response

    return { status, data }
  }

  async tariffChange(queryData, companyId) {
    const response = await axios
      .post(this.#tariffsUrl + '/' + companyId, queryData)
      .catch(error => {
        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async tariffChangeActivate(queryData, companyId, param) {
    const response = await axios
      .patch(this.#companiesUrl + '/' + companyId + '/' + param, queryData)
      .catch(error => {
        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  async getBillingPostPay(route) {
    const response = await axios.get(this.#billingUrlPostPayment + route).catch(error => {
      const { status, data } = error.response

      return { status, data }
    })

    const { status, data } = response

    return { status, data }
  }

  async getBillingPrePay(route) {
    const response = await axios.get(this.#billingUrlPrePayment + route).catch(error => {
      const { status, data } = error.response

      return { status, data }
    })

    const { status, data } = response

    return { status, data }
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  async getCompanies(param) {
    const response = await axios.get(this.#companiesUrl + param).catch(error => {
      console.error(error)
    })

    const { status, data } = response
      ? response
      : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async getCompaniesList() {
    const response = await axios.get(this.#companiesUrlList).catch(error => {
      console.error(error)
    })

    const { status, data } = response
      ? response
      : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async addCompanies(queryData) {
    const response = await axios
      .post(this.#companiesUrl, queryData, {
        headers: {
          Authorization: `Bearer ${Cookies.get('accessToken')}`,
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'multipart/form-data',
        },
      })
      .catch(error => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async getCompaniesItem(companyId) {
    const response = await axios.get(this.#companiesUrlItem(companyId)).catch(error => {
      console.error(error)
    })

    const { status, data } = response
      ? response
      : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async editCompanies(companyId, queryData) {
    const response = await axios
      .post(this.#companiesUrlEdit(companyId), queryData, {
        headers: {
          Authorization: `Bearer ${Cookies.get('accessToken')}`,
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'multipart/form-data',
        },
      })
      .catch(error => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async changeStatus(companyId) {
    const response = await axios.patch(this.#companiesStatusUrl(companyId)).catch(error => {
      console.error(error)
    })

    const { status, data } = response

    return { status, data }
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  async getCases(param) {
    const response = await axios.get(this.#casesUrl + param).catch(error => {
      console.error(error)
    })

    const { status, data } = response
      ? response
      : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async getCasesItem(param) {
    const response = await axios.get(this.#casesUrlItem + param).catch(error => {
      console.error(error)
    })

    const { status, data } = response
      ? response
      : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async getMeasurementItem() {
    const response = await axios.get(this.#measurementUrlItem).catch(error => {
      console.error(error)
    })

    const { status, data } = response
      ? response
      : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async getMethodItem(param) {
    const response = await axios.get(this.#methodUrlItem + param).catch(error => {
      console.error(error)
    })

    const { status, data } = response
      ? response
      : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async getTypeItem(param) {
    const response = await axios.get(this.#typeUrlItem + param).catch(error => {
      console.error(error)
    })

    const { status, data } = response
      ? response
      : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async getPointItem() {
    const response = await axios.get(this.#pointUrlItem).catch(error => {
      console.error(error)
    })

    const { status, data } = response
      ? response
      : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async getCasesShow(caseId) {
    const response = await axios.get(this.#casesUrlEdit(caseId)).catch(error => {
      console.error(error)
    })

    const { status, data } = response
      ? response
      : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async addCases(queryData) {
    const response = await axios.post(this.#casesUrl, queryData).catch(error => {
      false && console.log(error)

      const { status, data } = error.response

      return { status, data }
    })

    const { status, data } = response

    return { status, data }
  }

  async addCasesPut(caseId, queryData) {
    const response = await axios.put(this.#casesUrlPut(caseId), queryData).catch(error => {
      false && console.log(error)

      const { status, data } = error.response

      return { status, data }
    })

    const { status, data } = response

    return { status, data }
  }

  async mainCases(caseId) {
    const response = await axios.patch(this.#mainCaseUrl(caseId)).catch(error => {
      false && console.log(error)

      const { status, data } = error.response

      return { status, data }
    })

    const { status, data } = response

    return { status, data }
  }

  async deleteCase(param) {
    const response = await axios.delete(this.#casesUrl + '/' + param).catch(error => {
      console.error(error)
    })

    const { status, data } = response

    return { status, data }
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  async getMasterArshin(param) {
    const response = await axios.get(this.#masterArshinUrl + param).catch(error => {
      console.error(error)
    })

    const { status, data } = response
      ? response
      : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async postMaster(queryData) {
    const response = await axios.post(this.#masterArshinUrl, queryData).catch(error => {
      false && console.log(error)

      const { status, data } = error.response

      return { status, data }
    })

    const { status, data } = response

    return { status, data }
  }

  async putMaster(masterId, queryData) {
    const response = await axios.put(this.#masterUrlPut(masterId), queryData).catch(error => {
      false && console.log(error)

      const { status, data } = error.response

      return { status, data }
    })

    const { status, data } = response

    return { status, data }
  }

  async getMaster(masterId) {
    const response = await axios.get(this.#masterUrlItem(masterId)).catch(error => {
      false && console.log(error)

      const { status, data } = error.response

      return { status, data }
    })

    const { status, data } = response

    return { status, data }
  }

  async deleteMaster(masterId) {
    const response = await axios.delete(this.#masterUrlPut(masterId)).catch(error => {
      console.error(error)
    })

    const { status, data } = response

    return { status, data }
  }

  async getMasterItem() {
    const response = await axios.get(this.#masterUrl).catch(error => {
      console.error(error)
    })

    const { status, data } = response
      ? response
      : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  async getVerifications(param) {
    const response = await axios.get(this.#verificationsUrl + param).catch(error => {
      console.error(error)
    })

    const { status, data } = response
      ? response
      : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async addVerifications(queryData) {
    const response = await axios.post(this.#verificationsUrl, queryData).catch(error => {
      false && console.log(error)

      const { status, data } = error.response

      return { status, data }
    })

    const { status, data } = response

    return { status, data }
  }

  async getVerificationsItem(journalId) {
    const response = await axios.get(this.#verificationsUrlItem(journalId)).catch(error => {
      console.error(error)
    })

    const { status, data } = response
      ? response
      : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async editVerifications(journalId, queryData) {
    const response = await axios
      .put(this.#verificationsUrlEdit(journalId), queryData)
      .catch(error => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async deleteVerifications(param) {
    const response = await axios.delete(this.#verificationsUrl + '/' + param).catch(error => {
      console.error(error)
    })

    const { status, data } = response

    return { status, data }
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  async getTickets(company_id, queryData) {
    const response = await axios.get(this.#ticketsUrl(company_id) + queryData).catch(error => {
      console.error(error)
    })

    const { status, data } = response
      ? response
      : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async addTickets(company_id, queryData) {
    const response = await axios.post(this.#ticketsUrl(company_id), queryData).catch(error => {
      false && console.log(error)

      const { status, data } = error.response

      return { status, data }
    })

    const { status, data } = response

    return { status, data }
  }

  async getTicketsItem(journalId, companyId) {
    const response = await axios.get(this.#ticketsUrlItem(journalId, companyId)).catch(error => {
      console.error(error)
    })

    const { status, data } = response
      ? response
      : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async editTickets(journalId, companyId, queryData) {
    const response = await axios
      .put(this.#ticketsUrlEdit(journalId, companyId), queryData)
      .catch(error => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async deleteTickets(company_id, journal_id) {
    const response = await axios
      .delete(this.#ticketsDeleteUrl(company_id, journal_id))
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response

    return { status, data }
  }

  async archiveTickets(company_id, queryData) {
    const response = await axios
      .patch(this.#ticketsArchiveUrl(company_id), queryData)
      .catch(error => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  async getPersonalMetrolog(company_id, arshinMasterId, param) {
    const response = await axios
      .get(this.#personalUrl(company_id, arshinMasterId) + param)
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response
      ? response
      : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async addPersonalMetrolog(company_id, arshinMasterId, queryData) {
    const response = await axios
      .post(this.#personalUrl(company_id, arshinMasterId), queryData)
      .catch(error => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async getPersonalMetrologItem(journalId, companyId, arshinMasterId) {
    const response = await axios
      .get(this.#personalUrlItem(journalId, companyId, arshinMasterId))
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response
      ? response
      : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async putPersonalMetrolog(journalId, companyId, arshinMasterId, queryData) {
    const response = await axios
      .put(this.#personalUrlEdit(journalId, companyId, arshinMasterId), queryData)
      .catch(error => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async deletePersonalMetrolog(journalId, company_id, arshinMasterId) {
    const response = await axios
      .delete(this.#personalUrlEdit(journalId, company_id, arshinMasterId))
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response

    return { status, data }
  }

  async archivePersonalMetrolog(companyId, arshinMasterId, queryData) {
    const response = await axios
      .patch(this.#personalArchiveUrl(companyId, arshinMasterId), queryData)
      .catch(error => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  async getConditions(company_id, queryData) {
    const response = await axios.get(this.#conditionsUrl(company_id) + queryData).catch(error => {
      console.error(error)
    })

    const { status, data } = response
      ? response
      : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async getConditionsItem(journalId, companyId) {
    const response = await axios.get(this.#conditionsUrlItem(journalId, companyId)).catch(error => {
      console.error(error)
    })

    const { status, data } = response
      ? response
      : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async editConditions(journalId, companyId, queryData) {
    const response = await axios
      .put(this.#conditionsUrlEdit(journalId, companyId), queryData)
      .catch(error => {
        console.error(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async archiveConditionsJournal(companyId, queryData) {
    const response = await axios
      .patch(this.#archiveConditionsUrl(companyId), queryData)
      .catch(error => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  async getIssueCase(company_id, queryData) {
    const response = await axios.get(this.#issueCaseUrl(company_id) + queryData).catch(error => {
      console.error(error)
    })

    const { status, data } = response
      ? response
      : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async addIssueCase(company_id, queryData) {
    const response = await axios.post(this.#issueCaseUrl(company_id), queryData).catch(error => {
      false && console.log(error)

      const { status, data } = error.response

      return { status, data }
    })

    const { status, data } = response

    return { status, data }
  }

  async getIssueCaseItem(journalId, companyId) {
    const response = await axios.get(this.#issueCaseUrlItem(journalId, companyId)).catch(error => {
      console.error(error)
    })

    const { status, data } = response
      ? response
      : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async getReceivedByNameList(companyId) {
    const response = await axios.get(this.#receivedByNameList(companyId)).catch(error => {
      console.error(error)
    })

    const { status, data } = response
      ? response
      : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async editIssueCase(journalId, companyId, queryData) {
    const response = await axios
      .put(this.#issueCaseUrlEdit(journalId, companyId), queryData)
      .catch(error => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async editIssueCaseReturn(journalId, companyId) {
    const response = await axios
      .patch(this.#issueCaseUrlReturn(journalId, companyId))
      .catch(error => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async deleteIssueCase(journalId, company_id) {
    const response = await axios
      .delete(this.#issueCaseUrlEdit(journalId, company_id))
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response

    return { status, data }
  }

  async archiveIssueCase(company_id, queryData) {
    const response = await axios
      .patch(this.#issueCaseArchiveUrl(company_id), queryData)
      .catch(error => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  async getKitVerifications(company_id, queryData) {
    const response = await axios
      .get(this.#kitVerificationsUrl(company_id) + queryData)
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response
      ? response
      : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async addKitVerifications(company_id, queryData) {
    const response = await axios
      .post(this.#kitVerificationsUrl(company_id), queryData)
      .catch(error => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async getKitVerificationsItem(journalId, companyId) {
    const response = await axios
      .get(this.#kitVerificationsUrlItem(journalId, companyId))
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response
      ? response
      : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async editKitVerifications(journalId, companyId, queryData) {
    const response = await axios
      .put(this.#kitVerificationsUrlEdit(journalId, companyId), queryData)
      .catch(error => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async deleteKitVerifications(journalId, company_id) {
    const response = await axios
      .delete(this.#kitVerificationsUrlEdit(journalId, company_id))
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response

    return { status, data }
  }

  async archiveKitVerifications(company_id, queryData) {
    const response = await axios
      .patch(this.#kitVerificationsArchiveUrl(company_id), queryData)
      .catch(error => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  async getKitPassports(company_id, queryData) {
    const response = await axios.get(this.#kitPassportsUrl(company_id) + queryData).catch(error => {
      console.error(error)
    })

    const { status, data } = response
      ? response
      : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async getKitPassportsItem(journalId, companyId) {
    const response = await axios
      .get(this.#kitPassportsUrlItem(journalId, companyId))
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response
      ? response
      : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async editKitPassports(journalId, companyId, queryData) {
    const response = await axios
      .put(this.#kitPassportsUrlEdit(journalId, companyId), queryData)
      .catch(error => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async archiveKitPassports(journal_id, company_id) {
    const response = await axios
      .patch(this.#kitPassportsArchiveUrl(journal_id, company_id))
      .catch(error => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async KitPassportsPdf(company_id, queryData) {
    const response = await axios
      .patch(this.#kitPassportsPdfUrl(company_id), queryData, {
        headers: this.headers,
        responseType: 'arraybuffer',
      })
      .catch(error => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async getResponsibleNameList(companyId) {
    const response = await axios.get(this.#responsibleNameListUrl(companyId)).catch(error => {
      console.error(error)
    })

    const { status, data } = response
      ? response
      : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  async getComplect(param) {
    const response = await axios.get(this.#complectUrl + param).catch(error => {
      console.error(error)
    })

    const { status, data } = response
      ? response
      : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async addComplect(queryData) {
    const response = await axios.post(this.#complectUrl, queryData).catch(error => {
      false && console.log(error)

      const { status, data } = error.response

      return { status, data }
    })

    const { status, data } = response

    return { status, data }
  }

  async getComplectItem(journalId) {
    const response = await axios.get(this.#complectUrlItem(journalId)).catch(error => {
      console.error(error)
    })

    const { status, data } = response
      ? response
      : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async editComplect(journalId, queryData) {
    const response = await axios.put(this.#complectUrlEdit(journalId), queryData).catch(error => {
      false && console.log(error)

      const { status, data } = error.response

      return { status, data }
    })

    const { status, data } = response

    return { status, data }
  }

  async deleteComplect(param) {
    const response = await axios.delete(this.#complectUrl + '/' + param).catch(error => {
      console.error(error)
    })

    const { status, data } = response

    return { status, data }
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  async getReceivedJournals(company_id, queryData) {
    const response = await axios.get(this.#receivedUrl(company_id) + queryData).catch(error => {
      console.error(error)
    })

    const { status, data } = response
      ? response
      : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async addReceivedJournalRow(company_id, queryData) {
    const response = await axios.post(this.#receivedUrl(company_id), queryData).catch(error => {
      false && console.log(error)

      const { status, data } = error.response

      return { status, data }
    })

    const { status, data } = response

    return { status, data }
  }

  async getReceivedItem(journalId, companyId) {
    const response = await axios.get(this.#receivedUrlItem(journalId, companyId)).catch(error => {
      console.error(error)
    })

    const { status, data } = response
      ? response
      : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async editReceived(journalId, companyId, queryData) {
    const response = await axios
      .put(this.#receivedUrlEdit(journalId, companyId), queryData)
      .catch(error => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async deleteReceived(journalId, companyId) {
    const response = await axios
      .delete(this.#receivedUrlEdit(journalId, companyId))
      .catch(error => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async archiveReceivedJournal(company_id, queryData) {
    const response = await axios
      .patch(this.#receivedArchiveUrl(company_id), queryData)
      .catch(error => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  async getArchiveJournals(company_id, queryData) {
    const response = await axios.get(this.#archiveUrl(company_id) + queryData).catch(error => {
      console.error(error)
    })

    const { status, data } = response
      ? response
      : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async getArchiveEntriesJournals(company_id, entry_id, queryData = '') {
    const response = await axios
      .get(this.#archiveEntriesUrl(company_id, entry_id) + queryData)
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response
      ? response
      : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async unarchiveJournals(company_id, entry_id) {
    const response = await axios
      .patch(this.#archiveUnarchiveUrl(company_id, entry_id))
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response
      ? response
      : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async sheduleDeleteJournals(company_id, entry_id, queryData) {
    const response = await axios
      .patch(this.#archiveDeleteUrl(company_id, entry_id), queryData)
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response
      ? response
      : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async getEditArchiveJournals(company_id, entry_id) {
    const response = await axios.get(this.#archiveGetEditUrl(company_id, entry_id)).catch(error => {
      console.error(error)
    })

    const { status, data } = response
      ? response
      : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async editArchiveJournals(company_id, entry_id, queryData) {
    const response = await axios
      .put(this.#archiveEditUrl(company_id, entry_id), queryData)
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response
      ? response
      : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async getArchivePdf(company_id) {
    const response = await axios
      .get(this.#archivePdf(company_id), {
        headers: this.headers,
        responseType: 'arraybuffer',
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response
      ? response
      : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async getArchiveEntriesPdf(company_id, queryData) {
    const response = await axios
      .get(this.#archiveEntriesPdf(company_id) + queryData, {
        headers: this.headers,
        responseType: 'arraybuffer',
      })
      .catch(error => {
        console.error(error)
      })

    console.log(response)

    const { status, data } = response
      ? response
      : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async getArchiveCsv(company_id) {
    const response = await axios.get(this.#archiveCsv(company_id)).catch(error => {
      console.error(error)
    })

    const { status, data } = response
      ? response
      : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async getArchiveExcel(company_id) {
    const response = await axios.get(this.#archiveExcel(company_id)).catch(error => {
      console.error(error)
    })

    const { status, data } = response
      ? response
      : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  async getMetersList(param) {
    const response = await axios.get(this.#metersUrlList + param).catch(error => {
      console.error(error)
    })

    const { status, data } = response
      ? response
      : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async getMetersListWithParams(measurementId, searchQuery) {
    const response = await axios
      .get(this.#metersUrlWithParams(measurementId, searchQuery))
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response
      ? response
      : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async getMeterItem(param, callback = () => {}) {
    console.log(this.#metersUrl + '/' + param)

    const response = await axios.get(this.#metersUrl + '/' + param).catch(error => {
      console.error(error)
    })

    const { status, data } = response
      ? response
      : { status: 'ошибка обращения к api', data: { data: null } }

    response && callback()

    return { status, data }
  }

  async addMeter(measurementId, queryData) {
    const response = await axios
      .post(this.#metersUrl + '/' + measurementId, queryData)
      .catch(error => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async editMeter(measurementId, queryData, meterId) {
    const response = await axios
      .put(this.#metersUrl + '/' + measurementId + '/' + Number(meterId), queryData)
      .catch(error => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async deleteMeters(measurementId, queryData) {
    const response = await axios
      .delete(this.#metersUrl + '/' + measurementId, { data: queryData }, { headers: this.headers })
      .catch(error => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  // ----------------------------------------------------------------
  // возможно метод будет перенесен в статус deprecated
  // ----------------------------------------------------------------

  async getUserRegions() {
    const response = await axios.get(this.#userRegionsUrl).catch(error => {
      false && console.log(error)

      const { status, data } = error.response

      return { status, data }
    })

    const { status, data } = response

    return { status, data }
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  async getUsers(param) {
    const response = await axios.get(this.#usersUrl + param).catch(error => {
      console.error(error)
    })

    const { status, data } = response
      ? response
      : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async getUserList(param) {
    const response = await axios.get(this.#usersListUrl + param).catch(error => {
      false && console.log(error)

      const { status, data } = error.response

      return { status, data }
    })

    const { status, data } = response

    return { status, data }
  }

  async addUser(queryData) {
    const response = await axios.post(this.#usersUrl, queryData).catch(error => {
      false && console.log(error)

      const { status, data } = error.response

      return { status, data }
    })

    const { status, data } = response

    return { status, data }
  }

  async addUserPut(userId, queryData) {
    const response = await axios.put(this.#usersUrlItem(userId), queryData).catch(error => {
      false && console.log(error)

      const { status, data } = error.response

      return { status, data }
    })

    const { status, data } = response

    return { status, data }
  }

  async getUsersItem(userId) {
    const response = await axios.get(this.#usersUrlItem(userId)).catch(error => {
      console.error(error)
    })

    const { status, data } = response
      ? response
      : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async changeUserTrust(userId, queryData) {
    const response = await axios.patch(this.#usersTrustUrl(userId), queryData).catch(error => {
      false && console.log(error)

      const { status, data } = error.response

      return { status, data }
    })

    const { status, data } = response

    return { status, data }
  }

  async changeUserBan(userId) {
    const response = await axios.patch(this.#usersBanUrl(userId)).catch(error => {
      false && console.log(error)

      const { status, data } = error.response

      return { status, data }
    })

    const { status, data } = response

    return { status, data }
  }

  async deleteUser(userId) {
    const response = await axios.delete(this.#usersUrlItem(userId)).catch(error => {
      false && console.log(error)

      const { status, data } = error.response

      return { status, data }
    })

    const { status, data } = response

    return { status, data }
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  async getEtalon(param) {
    const response = await axios.get(this.#etalonUrl + param).catch(error => {
      console.error(error)
    })

    const { status, data } = response
      ? response
      : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async getKitsList(param) {
    const response = await axios.get(this.#kitsList + param).catch(error => {
      console.error(error)
    })

    const { status, data } = response
      ? response
      : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async getEtalonItem(etalonId) {
    const response = await axios.get(this.#etalonUrlItem(etalonId)).catch(error => {
      console.error(error)
    })

    const { status, data } = response
      ? response
      : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async getEtalonEditItem(etalonId) {
    const response = await axios.get(this.#etalonUrlEditItem(etalonId)).catch(error => {
      console.error(error)
    })

    const { status, data } = response
      ? response
      : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async addEtalon(queryData) {
    const response = await axios.post(this.#etalonUrl, queryData).catch(error => {
      false && console.log(error)

      const { status, data } = error.response

      return { status, data }
    })

    const { status, data } = response

    return { status, data }
  }

  async deleteEtalon(param) {
    const response = await axios.delete(this.#etalonUrl + '/' + param).catch(error => {
      false && console.log(error)

      const { status, data } = error.response

      return { status, data }
    })

    const { status, data } = response

    return { status, data }
  }

  async editEtalon(etalonId, queryData) {
    const response = await axios.put(this.#etalonUrlEdit(etalonId), queryData).catch(error => {
      false && console.log(error)

      const { status, data } = error.response

      return { status, data }
    })

    const { status, data } = response

    return { status, data }
  }

  async getEtalonDevicesList(kitGroupId) {
    const response = await axios.get(this.#etalonUrlDevicesList(kitGroupId)).catch(error => {
      console.error(error)
    })

    const { status, data } = response
      ? response
      : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async getEtalonClassesList() {
    const response = await axios.get(this.#etalonUrlClassesList).catch(error => {
      console.error(error)
    })

    const { status, data } = response
      ? response
      : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async getKitsAccuracyClasses() {
    const response = await axios.get(this.#kitsAccuracyClassesUrl).catch(error => {
      false && console.log(error)

      const { status, data } = error.response

      return { status, data }
    })

    const { status, data } = response

    return { status, data }
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  async getProtocol(param, callback = () => {}) {
    const response = await axios.get(this.#protocolsUrl + '/' + param).catch(error => {
      console.error(error)
    })

    const { status, data } = response
      ? response
      : { status: 'ошибка обращения к api', data: { data: null } }

    if (status !== 'ошибка обращения к api') callback()

    return { status, data }
  }

  async editProtocol(protocol_id, queryData) {
    const response = await axios
      .put(this.#protocolsUrlEdit(protocol_id), queryData)
      .catch(error => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async postProtocol(queryData) {
    const response = await axios.post(this.#protocolsUrl, queryData).catch(error => {
      false && console.log(error)

      const { status, data } = error.response

      return { status, data }
    })

    const { status, data } = response

    return { status, data }
  }

  async getProtocolsList(param) {
    const response = await axios.get(this.#protocolsUrl + param).catch(error => {
      false && console.log(error)

      const { status, data } = error.response

      return { status, data }
    })

    const { status, data } = response

    return { status, data }
  }

  async deleteProtocol(param) {
    const response = await axios.delete(this.#protocolsUrl + '/' + param).catch(error => {
      false && console.log(error)

      const { status, data } = error.response

      return { status, data }
    })

    const { status, data } = response

    return { status, data }
  }

  async addFiles(queryData) {
    const response = await axios
      .post(this.#filesUrl, queryData, {
        headers: {
          Authorization: `Bearer ${Cookies.get('accessToken')}`,
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'multipart/form-data',
        },
      })
      .catch(error => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async protocolCheck(queryData) {
    const response = await axios.patch(this.#protocolsCheck, queryData).catch(error => {
      false && console.log(error)

      const { status, data } = error.response

      return { status, data }
    })

    const { status, data } = response

    return { status, data }
  }

  async protocolArchive(queryData) {
    const response = await axios.patch(this.#protocolsArchive, queryData).catch(error => {
      false && console.log(error)

      const { status, data } = error.response

      return { status, data }
    })

    const { status, data } = response

    return { status, data }
  }

  async protocolUnArchive(queryData) {
    const response = await axios.patch(this.#protocolsUnArchive, queryData).catch(error => {
      false && console.log(error)

      const { status, data } = error.response

      return { status, data }
    })

    const { status, data } = response

    return { status, data }
  }

  async protocolPdf(queryData) {
    const response = await axios
      .patch(this.#protocolsPdf, queryData, {
        headers: this.headers,
        responseType: 'arraybuffer',
      })
      .catch(error => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  async getHeaderData() {
    const response = await axios.get(this.#headerDataUrl).catch(error => {
      false && console.log(error)

      const { status, data } = error.response

      return { status, data }
    })

    const { status, data } = response

    return { status, data }
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  async getJournalSettings(company_id) {
    const response = await axios.get(this.#journalsSettings(company_id)).catch(error => {
      false && console.log(error)

      const { status, data } = error.response

      return { status, data }
    })

    const { status, data } = response

    return { status, data }
  }

  async changeJournalSettings(company_id, queryData) {
    const response = await axios.put(this.#journalsSettings(company_id), queryData).catch(error => {
      false && console.log(error)

      const { status, data } = error.response

      return { status, data }
    })

    const { status, data } = response

    return { status, data }
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  async logListChange(queryData) {
    const response = await axios.post(this.#supportUrlLogs, queryData).catch(error => {
      const { status, data } = error.response

      return { status, data }
    })

    const { status, data } = response

    return { status, data }
  }
}

const R = new Request()
export default R
