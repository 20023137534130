import { styled } from '@mui/material'

export const Wrapper = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  [theme.breakpoints.down('lg')]: {
    gap: '23px',
  },
}))

export const Container = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'flex-start',
  gap: '16px',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    gap: '23px',
  },
}))

export const CheckBoxFrame = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  [theme.breakpoints.down('lg')]: {
    gap: '0px',
  },
}))

export const LabelFrame33 = styled('div')(({ theme }) => ({
  width: '33%',
  display: 'flex',
  flexDirection: 'column',
  gap: '6px',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
    gap: '0px',
  },
}))

export const LabelFrame50 = styled('div')(({ theme }) => ({
  width: '50%',
  display: 'flex',
  flexDirection: 'column',
  gap: '6px',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
    gap: '0px',
  },
}))

export const LabelFrame70 = styled('div')(({ theme }) => ({
  width: '67%',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
  },
}))

export const OwnedSi = styled('div')(({ theme }) => ({
  width: '33%',
  display: 'flex',
  flexDirection: 'column',
  gap: '6px',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
    gap: '10px',
  },
}))

export const TotalAcceptedTrue = styled('div')(({ theme }) => ({
  padding: '15px 20px',
  borderRadius: '12px',
  background: '#e5f3fc',
  border: `1px solid ${theme.palette.text.secondary}`,
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  [theme.breakpoints.down('lg')]: {
    gap: '0px',
  },
}))

export const TotalAcceptedFalse = styled('div')(({ theme }) => ({
  padding: '15px 20px',
  borderRadius: '12px',
  background: '#ffeeef',
  border: `1px solid ${theme.palette.background.error}`,
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  [theme.breakpoints.down('lg')]: {
    gap: '0px',
  },
}))
