import useBlocker from './useBlocker.ts'

const usePrompt = (message: string, when: boolean = true): void => {
  console.log('блокировщик маршрутов работает корректно')

  const blocker = (tx: { retry: () => void }) => {
    if (window.confirm(message)) {
      tx.retry()
    }
  }

  useBlocker(blocker, when)
}

export default usePrompt
