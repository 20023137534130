// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import CheckCircleIcon from '../../../../img/icon/CheckCircleIcon'
import VisibilityToggleIcon from '../../../../img/icon/VisibilityToggleIcon'
import DatePicker from '../../LocalElements/DatePicker/DatePicker'
import Measurement from '../../LocalElements/Filter/FilterMeasurement'
import Feedback from '../../LocalElements/Feedback/Feedback'
import R from '../../../../services/app/client-server/request.service.js'
import { changeIssueCase, resetIssueCase } from '../../../../store/slices/app/views/journals'
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from '../../../../store/slices/app/comps/snackbar'
import { refreshIssueCase } from '../../../../store/slices/app/controlers/updater'
import dayjs from 'dayjs'
import Input from '../../../../ui/input/index.tsx'
import Button from '../../../../ui/button/index.tsx'
import RadioButton from '../../../../ui/radio-button/index'
import { Typography } from '@mui/material'
import { Wrapper, Frame, Content, ContentFrame, LabelFrame } from '../style'

import usePrompt from '../../../hooks/usePrompt.ts'

const Add = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userRole = useSelector(state => state.headerReducer.role)
  const show = useSelector(state => state.feedbackReducer.show)

  const [isDisabledBtn, setIsDisabledBtn] = useState(false)
  const [getPrompt, setGetPrompt] = useState(true)

  const [errors, setErrors] = useState({})
  const companyIdSuperAdmin = useSelector(state => state.companyIdReducer.companyId)
  const companyIdUser = useSelector(state => state.headerReducer.companyId)
  const companyId = userRole === 'super' ? companyIdSuperAdmin : companyIdUser
  const measurement = useSelector(state => state.journalReducer.issueCase.measurement)
  const caseNum = useSelector(state => state.journalReducer.issueCase.caseNum)
  const issuedByName = useSelector(state => state.journalReducer.issueCase.issuedName)
  const receivedByName = useSelector(state => state.journalReducer.issueCase.receivedName)
  const isReturned = useSelector(state => state.journalReducer.issueCase.returned)
  const issueDate = useSelector(state => state.journalReducer.issueCase.issueDate)
  const receivedDate = useSelector(state => state.journalReducer.issueCase.receivedDate)

  usePrompt(
    'Вы уверены, что хотите продолжить действие? Все введенные или измененные данные не будут сохранены',
    getPrompt,
  )

  const changeMeasurement = param => {
    dispatch(changeIssueCase({ value: 'measurement', label: param }))
    setErrors(prevErrors => ({ ...prevErrors, measurement_id: '' }))
  }

  const handleCaseNumChange = param => {
    dispatch(changeIssueCase({ value: 'caseNum', label: param }))
    setErrors(prevErrors => ({ ...prevErrors, inner_name: '' }))
  }

  const handleIssuedByNameChange = param => {
    dispatch(changeIssueCase({ value: 'issuedName', label: param }))
    setErrors(prevErrors => ({ ...prevErrors, issued_by_name: '' }))
  }

  const handleReceivedNameChange = param => {
    dispatch(changeIssueCase({ value: 'receivedName', label: param }))
    setErrors(prevErrors => ({ ...prevErrors, received_by_name: '' }))
  }

  const handleIssuedDateChange = date => {
    if (date) {
      dispatch(
        changeIssueCase({
          value: 'issueDate',
          label: date.format('YYYY-MM-DD'),
        })
      )
    } else {
      dispatch(changeIssueCase({ value: 'issueDate', label: '' }))
    }
  }

  const handleReceivedDateChange = date => {
    if (date) {
      dispatch(changeIssueCase({ value: 'receivedDate', label: date.format('YYYY-MM-DD') }))
    } else {
      dispatch(changeIssueCase({ value: 'receivedDate', label: '' }))
    }
  }

  const changeIsReturnedChecked = event => {
    const value = event.target.value === 'true'
    dispatch(changeIssueCase({ value: 'returned', label: value }))
  }

  const handleSave = async () => {
    setIsDisabledBtn(true)
    setGetPrompt(false)

    const data = {
      measurement_id: measurement,
      inner_name: caseNum,
      issued_date: issueDate,
      issued_by_name: issuedByName,
      received_by_name: receivedByName,
      received_date: receivedDate,
      is_returned: isReturned,
    }

    const { status, data: responseData } = await R.addIssueCase(companyId, data)

    if (status === 201) {
      dispatch(setMessage('Запись успешно добавлена'))
      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
      dispatch(refreshIssueCase())
      dispatch(resetIssueCase())
      navigate('/metriva/journals')
    } else if (status === 422) {
      setErrors(responseData.errors || {})
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Запись не добавлена - ошибка валидации'))
      setIsDisabledBtn(false)
      setGetPrompt(true)
    } else {
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage(responseData.message))
      setIsDisabledBtn(false)
      setGetPrompt(true)
    }
  }

  const navigateToProtocols = () => {
    navigate('/metriva/journals')
  }

  const measurementLabelIcon = measurement ? (
    <CheckCircleIcon
      sx={{
        color: '#63E6BE',
        marginLeft: '8px',
        marginTop: '0',
      }}
    />
  ) : (
    <VisibilityToggleIcon
      sx={{
        color: '#FFD43B',
        marginLeft: '8px',
        marginTop: '0',
      }}
    />
  )

  return (
    <main className='main'>
      {show ? (
        <Feedback />
      ) : (
        <Wrapper>
          <Frame onClick={navigateToProtocols}>
            <i
              className='fas fa-arrow-left icon_back'
              style={{ width: '30px', height: '30px', marginRight: '6px' }}
            />
            <Typography variant='subtitle2'>Все записи документа</Typography>
          </Frame>

          <Typography variant='h2'>Журнал выдачи средств поверки</Typography>

          <Content>
            <Typography variant='h2' style={{ marginBottom: '4px' }}>
              Информация о журнале
            </Typography>

            <ContentFrame style={{ marginBottom: '4px' }}>
              <LabelFrame sx={{ width: '50%' }}>
                <Measurement
                  measurementId={measurement}
                  setMeasurementId={changeMeasurement}
                  label={{
                    text: 'Область измерения',
                    topBg: 'white',
                    bottomBg: 'white',
                  }}
                />
                {errors.measurement_id && <div className='error'>{errors.measurement_id[0]}</div>}
              </LabelFrame>

              <LabelFrame sx={{ width: '50%' }}>
                <Input
                  label={'№ комплекта СП'}
                  type={'text'}
                  error={!!errors.inner_name}
                  value={caseNum}
                  actions={{
                    change: handleCaseNumChange,
                  }}
                />
                {errors.inner_name && <div className='error'>{errors.inner_name[0]}</div>}
              </LabelFrame>
            </ContentFrame>

            <ContentFrame>
              <LabelFrame sx={{ width: '50%' }}>
                <Input
                  label={'Выдавший (ФИО и должность)'}
                  placeholder={'Введите ФИО выдавшего комплект'}
                  type={'text'}
                  value={issuedByName}
                  error={!!errors.issued_by_name}
                  actions={{
                    change: handleIssuedByNameChange,
                  }}
                />
                {errors.issued_by_name && <div className='error'>{errors.issued_by_name[0]}</div>}
              </LabelFrame>

              <LabelFrame sx={{ width: '50%' }}>
                <Input
                  label={'Принявший (ФИО и должность)'}
                  placeholder={'Введите ФИО принявшего комплект'}
                  type={'text'}
                  value={receivedByName}
                  error={!!errors.received_by_name}
                  actions={{
                    change: handleReceivedNameChange,
                  }}
                />
                {errors.received_by_name && (
                  <div className='error'>{errors.received_by_name[0]}</div>
                )}
              </LabelFrame>
            </ContentFrame>

            <ContentFrame>
              <LabelFrame sx={{ width: '50%' }}>
                <Typography variant='subtitle2' sx={{ display: 'flex', alignItems: 'center' }}>
                  Дата выдачи комплекта
                  {!!issueDate ? (
                    <CheckCircleIcon
                      sx={{
                        color: '#63E6BE',
                        marginLeft: '8px',
                        marginTop: '0px',
                      }}
                    />
                  ) : (
                    <VisibilityToggleIcon
                      sx={{
                        color: '#FFD43B',
                        marginLeft: '8px',
                        marginTop: '0px',
                      }}
                    />
                  )}
                </Typography>
                <DatePicker selectedDate={issueDate} dateChange={handleIssuedDateChange} />
              </LabelFrame>
              <LabelFrame sx={{ width: '50%' }}>
                <Typography variant='subtitle2'>Отметка о возврате</Typography>
                <RadioButton
                  value={isReturned}
                  onChange={changeIsReturnedChecked}
                  trueLabel={'Присутствует'}
                  falseLabel={'Отсутствует'}
                  trueValue={true}
                  falseValue={false}
                />
              </LabelFrame>
            </ContentFrame>

            {isReturned && (
              <ContentFrame>
                <LabelFrame sx={{ width: '50%' }}>
                  <Typography variant='subtitle2' sx={{ display: 'flex', alignItems: 'center' }}>
                    Дата возврата комплекта
                    {isReturned ? (
                      !!receivedDate ? (
                        <CheckCircleIcon
                          sx={{
                            color: '#63E6BE',
                            marginLeft: '8px',
                            marginTop: '-0px',
                          }}
                        />
                      ) : (
                        <VisibilityToggleIcon
                          sx={{
                            color: '#FFD43B',
                            marginLeft: '8px',
                            marginTop: '-0px',
                          }}
                        />
                      )
                    ) : (
                      <CheckCircleIcon
                        sx={{
                          color: '#63E6BE',
                          marginLeft: '8px',
                          marginTop: '-0px',
                        }}
                      />
                    )}
                  </Typography>
                  <DatePicker
                    selectedDate={receivedDate}
                    dateChange={handleReceivedDateChange}
                    minDate={issueDate ? dayjs(issueDate) : null}
                  />
                </LabelFrame>
                <LabelFrame sx={{ width: '50%' }}></LabelFrame>
              </ContentFrame>
            )}
          </Content>

          <Button
            onClick={handleSave}
            label='Добавить запись'
            disabled={isDisabledBtn}
            color='primary'
            fullWidth
          />
        </Wrapper>
      )}
    </main>
  )
}

export default Add
