import styled from 'styled-components'

const css = {
  Container: styled.div`
    display: flex;
    align-items: flex-start;
    align-items: center;
    gap: 10px;

    margin: ${props => (props.margin ? props.margin : '0')};

    @media (max-width: 1050px) {
      flex-direction: column;
      gap: 23px;
    }
  `,
  DescriptionTypeBox: styled.div`
    margin: ${props => (props.margin ? props.margin : '0')};
    width: 32.8%;

    @media (max-width: 1050px) {
      width: 100%;
      margin: 0 0 15px;
    }
  `,

  DescriptionTypeBoxLastElement: styled.div`
    margin: ${props => (props.margin ? props.margin : '0')};
    width: 32.8%;

    @media (max-width: 1050px) {
      width: 100%;
      margin: 0 0 0px;
    }
  `,

  QLimitDescriptionBox: styled.div`
    margin: ${props => (props.margin ? props.margin : '0')};
    width: 50%;

    @media (max-width: 1050px) {
      width: 100%;
      margin: 0 0 15px;
    }
  `,

  QLimitDescriptionBoxLastElement: styled.div`
    margin: ${props => (props.margin ? props.margin : '0')};
    width: 50%;

    @media (max-width: 1050px) {
      width: 100%;
      margin: 0 0 0px;
    }
  `,

  IndicationBox: styled.div`
    margin: ${props => (props.margin ? props.margin : '0')};
    width: 24.5%;

    @media (max-width: 1050px) {
      width: 100%;
      margin: ${props => (props.margin ? props.margin : '0')};
    }
  `,

  QActualBox: styled.div`
    margin: ${props => (props.margin ? props.margin : '0')};
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 22px;
    padding-right: 0px;
    background: linear-gradient(
      90deg,
      rgba(248, 249, 252, 1) 0%,
      rgba(248, 249, 252, 1) 66%,
      rgba(248, 249, 252, 0) 100%
    );
    border-radius: 12px;

    @media (max-width: 1050px) {
      flex-direction: column;
      margin: 24px 0 24px;
      padding-left: 20px;
      padding-right: 20px;
      background: rgba(248, 249, 252, 1);
    }
  `,
  TitleQActualElement: styled.h4`
    margin: 0;
    width: 50%;

    @media (max-width: 1050px) {
      margin: 0;
      width: 100%;
    }
  `,

  InputQActualElement: styled.div`
    margin: 0;
    width: calc(50% - 20px);

    @media (max-width: 1050px) {
      margin: 10px 0 0;
      width: 100%;
    }
  `,

  ConversionFactor: styled.div`
    margin: ${props => (props.margin ? props.margin : '0')};
    width: 32.8%;

    @media (max-width: 1050px) {
      margin: ${props => (props.margin ? props.margin : '15px 0')};
      width: 100%;
    }
  `,

  IndicationBoxLastElement: styled.div`
    margin: 0;
    width: 24.5%;

    @media (max-width: 1050px) {
      width: 100%;
    }
  `,

  CountIndicationBox: styled.div`
    width: 44px;
    height: 44px;
    border-radius: 12px;
    background-color: #e6eaf3;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #132532;
    margin: ${props => (props.margin ? props.margin : '0px')};

    @media (max-width: 1050px) {
      width: 100%;
      height: 55px;
      font-weight: bold;
    }
  `,

  ParagraphIndications: styled.p`
    margin: 14px 0 0;
    width: 90%;
    font-style: italic;
    font-size: 14px;
    line-height: 22px;

    @media (max-width: 1050px) {
      margin: 16px 0 12px;
    }
  `,

  ParagraphConditions: styled.p`
    margin: 14px 0 22px;
    width: 90%;
    font-size: 14px;
    font-style: italic;
    line-height: 22px;

    @media (max-width: 1050px) {
      margin: 16px 0;
    }
  `,

  ConditionsContainer: styled.div`
    margin: ${props => (props.margin ? props.margin : '0')};
    width: 100%;
    padding: 20px;
    border-radius: 12px;
    background-color: #f8f9fc;
  `,

  ConditionsBox: styled.div`
    margin: ${props => (props.margin ? props.margin : '0')};
    display: flex;
    align-items: center;
    gap: 10px;

    @media (max-width: 1050px) {
      flex-direction: column;
    }
  `,
}

export default css
