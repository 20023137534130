// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox'
import AddBoxIcon from '@mui/icons-material/AddBox'
import Checkbox from '@mui/material/Checkbox'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'

import Button from '@mui/material/Button'

import Table from '../../LocalElements/Table/Table'
import Input from '../../LocalElements/Input/InputMUI'

import {
  setOpen,
  setTitle,
  setChildren,
  setBD,
  showBA,
  resetState,
} from '../../../../store/slices/app/comps/modal'

import R from '../../../../services/app/client-server/request.service'

const Individual = props => {
  const { activeTariff } = props
  const dispatch = useDispatch()

  const [servicesDataMain, setServicesDataMain] = useState([])
  const [servicesDataExtra, setServicesDataExtra] = useState([])

  const [expandedDescription, setExpandedDescription] = useState(false)
  const [expandedTariff, setExpandedTariff] = useState(false)

  const handleAccordionDescriptionChange = () => {
    setExpandedDescription(prev => !prev)
  }

  const handleAccordionTariffChange = () => {
    setExpandedTariff(prev => !prev)
  }

  let colData = [
    {
      field: 'service',
      headerName: 'Услуга',
      flex: 1.5,
    },
    {
      field: 'description',
      headerName: 'Описание',
      flex: 1.5,
    },
    {
      field: 'billing',
      headerName: 'Биллинг-единица',
      flex: 1,
    },
    {
      field: 'count',
      headerName: 'Количество единиц',
      flex: 1,
      renderCell: params => {
        return <Input placeholder={'Неизвестно'} className={'inputInTable'} />
      },
    },
    {
      field: 'prise',
      headerName: 'Стоимость, ₽ (за единицу)',
      flex: 0.6,
    },
    {
      field: 'priseForService',
      headerName: 'Стоимость услуги, ₽(за расчетный период)',
      flex: 0.84,
      renderCell: params => {
        return <Input placeholder={'Сумма не указана'} className={'inputInTable'} />
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Включить',
      flex: 0.5,
      getActions: item => {
        return [
          <div
            style={{
              display: 'flex',
              flexFlow: 'row',
            }}
          >
            <Checkbox />
          </div>,
        ]
      },
    },
  ]

  useEffect(() => {
    if (activeTariff === 'individual') {
      ;(async () => {
        const { data: subscriptionService } = await R.subscriptionService(`/3`)

        let ratesArray = subscriptionService.data.rates
        ratesArray.sort((a, b) => a.id - b.id)

        const filteredMainArray = ratesArray.filter(
          item => !item.is_hidden && ![21, 22].includes(item.id),
        )

        const filteredExtraArray = ratesArray.filter(
          item => !item.is_hidden && [21, 22].includes(item.id),
        )

        setServicesDataMain(filteredMainArray)
        setServicesDataExtra(filteredExtraArray)
      })()
    }
  }, [activeTariff])

  useEffect(() => {
    return () => {
      dispatch(resetState())
    }
  }, [])

  return (
    <React.Fragment>
      <div className='box'>
        <h2 style={{ marginBottom: '25px' }}>Тариф «Индивидуальный»</h2>

        <Accordion
          expanded={expandedDescription}
          onChange={handleAccordionDescriptionChange}
          style={{
            backgroundColor: '#fffff',
            border: '1px solid #d7ddea',
            boxShadow: 'none',
            borderRadius: '12px',
            padding: '14px 0',
            '&::before': {
              display: 'none',
            },
            marginBottom: '20px',
          }}
        >
          <AccordionSummary
            expandIcon={null}
            aria-controls='panel1-content'
            id='panel1-header'
            style={{
              fontSize: '17px',
              fontWeight: 600,
              paddingTop: '0px',
            }}
          >
            {expandedDescription ? (
              <IndeterminateCheckBoxIcon
                fontSize='medium'
                sx={{
                  color: '#0084e2',
                  marginRight: '10px',
                  marginTop: '-1px',
                }}
              />
            ) : (
              <AddBoxIcon
                fontSize='medium'
                sx={{
                  color: '#0084e2',
                  marginRight: '10px',
                  marginTop: '-1px',
                }}
              />
            )}
            Краткое описание
          </AccordionSummary>
          <AccordionDetails
            style={{
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '24px',
            }}
          >
            Тариф «Индивидуальный» предоставляет клиентам все возможности системы, включая
            синхронизацию с ФГИСами и пользовательскими системами на индивидуальных условиях. В
            зависимости от конфигурации, пользователи могут без ограничений применять все
            инструменты и интерфейсы. Рекомендуем этот тариф для максимальной
            клиентоориентированности, а также полной интеграции системы в рабочие процессы клиентов.
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expandedTariff}
          onChange={handleAccordionTariffChange}
          style={{
            backgroundColor: '#fffff',
            border: '1px solid #d7ddea',
            boxShadow: 'none',
            borderRadius: '12px',
            padding: '14px 0',
            '&::before': {
              display: 'none',
            },
            marginBottom: '10px',
          }}
        >
          <AccordionSummary
            expandIcon={null}
            aria-controls='panel1-content'
            id='panel1-header'
            style={{
              fontSize: '17px',
              fontWeight: 600,
              paddingTop: '0px',
            }}
          >
            {expandedTariff ? (
              <IndeterminateCheckBoxIcon
                fontSize='medium'
                sx={{
                  color: '#0084e2',
                  marginRight: '10px',
                  marginTop: '-1px',
                }}
              />
            ) : (
              <AddBoxIcon
                fontSize='medium'
                sx={{
                  color: '#0084e2',
                  marginRight: '10px',
                  marginTop: '-1px',
                }}
              />
            )}
            Управление тарифом
          </AccordionSummary>
          <AccordionDetails
            style={{
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '24px',
            }}
          >
            <h4 style={{ marginBottom: '15px' }}>Выбор основных опций</h4>
            <Table
              css={{
                marginTop: '12px',
                backgroundColor: '#ffffff',
                border: '1px solid #d7ddea',
                '& .MuiDataGrid-columnHeaderTitle': {
                  whiteSpace: 'normal',
                  lineHeight: '1.2',
                  wordWrap: 'break-word',
                },
                '& .MuiDataGrid-cell:hover': {
                  cursor: 'default',
                },
                '& .MuiDataGrid-cell': {
                  whiteSpace: 'normal',
                  wordWrap: 'break-word',

                  height: 'auto',
                  lineHeight: '1.5',
                  padding: '8px',
                  boxSizing: 'border-box',
                },
              }}
              rowData={servicesDataMain.map(item => ({
                id: item.id,
                service: item?.description,
                description: item?.tariff?.description,
                billing: item?.units,
                count: '',
                prise: item?.price,
                priseForService: item?.price,
              }))}
              colData={colData}
              hideFooter={true}
              hasCheckboxSelection={false}
              getRowHeight={() => 'auto'}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 22,
                  },
                },
              }}
            />

            <div style={{ marginTop: '16px' }}>
              <p style={{ marginBottom: '22px' }}>
                <span style={{ color: '#0084e2' }}>*</span> - стоимость может меняться в зависимости
                от числа поверок
                <span
                  style={{
                    color: '#0084e2',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    dispatch(setOpen(true))
                    dispatch(setTitle('Стоимость может меняться в зависимости от числа поверок'))

                    dispatch(
                      setChildren(
                        <div>
                          <p style={{ marginBottom: '8px' }}>
                            До 500 поверок в месяц - 15,00 ₽/шт.
                          </p>
                          <p style={{ marginBottom: '8px' }}>
                            От 501 до 1000 поверок в месяц - 13,50 ₽/шт.
                          </p>
                          <p style={{ marginBottom: '8px' }}>
                            От 1001 до 1500 поверок в месяц - 12,00 ₽/шт.
                          </p>
                          <p style={{ marginBottom: '8px' }}>
                            От 1501 до 2000 поверок в месяц - 10,50 ₽/шт.
                          </p>
                          <p style={{ marginBottom: '8px' }}>
                            От 2001 до 3000 поверок в месяц - 9,00 ₽/шт.
                          </p>
                          <p style={{ marginBottom: '8px' }}>
                            От 3001 до 5000 поверок в месяц - 7,50 ₽/шт.
                          </p>
                          <p style={{ marginBottom: '8px' }}>
                            От 5001 до 7000 поверок в месяц - 5,25 ₽/шт.
                          </p>
                          <p>Более 7001 поверок в месяц - 4,00 ₽/шт.</p>
                        </div>,
                      ),
                    )
                    dispatch(setBD('Cкрыть'))
                    dispatch(showBA(false))
                  }}
                >
                  см. подробнее
                </span>
              </p>

              <div
                className='flexContainerWithGap'
                style={{
                  marginBottom: '18px',
                  alignItems: 'center',
                  paddingLeft: '10px',
                }}
              >
                <h4>Указать фиксированную стоимость, ₽:</h4>
                <div style={{ width: '350px' }}>
                  <Input className={'inputTariff'} />
                </div>
              </div>
            </div>

            <h4 style={{ marginBottom: '15px' }}>Выбор дополнительных опций</h4>

            <Table
              css={{
                marginTop: '12px',
                backgroundColor: '#ffffff',
                border: '1px solid #d7ddea',
                '& .MuiDataGrid-columnHeaderTitle': {
                  whiteSpace: 'normal',
                  lineHeight: '1.2',
                  wordWrap: 'break-word',
                },
                '& .MuiDataGrid-cell:hover': {
                  cursor: 'default',
                },
                '& .MuiDataGrid-cell': {
                  whiteSpace: 'normal',
                  wordWrap: 'break-word',

                  height: 'auto',
                  lineHeight: '1.5',
                  padding: '8px',
                  boxSizing: 'border-box',
                },
              }}
              colData={colData}
              rowData={servicesDataExtra.map(item => ({
                id: item.id,
                service: item?.description,
                description: item?.tariff?.description,
                billing: item?.units,
                count: '',
                prise: item?.price,
                priseForService: item?.price,
              }))}
              hideFooter={true}
              hasCheckboxSelection={false}
              getRowHeight={() => 'auto'}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
            />

            <div
              className='flexContainerWithGap'
              style={{
                marginBottom: '24px',
                alignItems: 'center',
                paddingLeft: '10px',
                marginTop: '24px',
              }}
            >
              <h4>Указать фиксированную стоимость, ₽:</h4>
              <div style={{ width: '350px' }}>
                <Input className={'inputTariff'} />
              </div>
            </div>

            <div
              className='flexContainerWithGap'
              style={{ marginBottom: '10px', paddingLeft: '10px' }}
            >
              <p>Действие тарифа:</p>
              <h4>Не активирован</h4>
            </div>
            <div className='flexContainerWithGap' style={{ paddingLeft: '10px' }}>
              <p>Общая стоимость, ₽:</p>
              <h4>0,00</h4>
            </div>

            <div style={{ marginTop: '20px' }}>
              <Button
                variant='contained'
                sx={{ mt: 1, mr: 1 }}
                style={{
                  textTransform: 'none',
                  fontSize: '15px',
                  fontFamily: '"Wix Madefor Display", sans-serif',
                  padding: '11px 20px 11px',
                  margin: 0,
                  borderRadius: '12px',
                  width: '100%',
                }}
              >
                Старт
              </Button>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </React.Fragment>
  )
}

export default Individual
