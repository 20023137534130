import React from 'react'
import { StyledButton } from './styles'

interface ButtonProps {
  label?: string
  onClick?: () => void
  startIcon?: React.ReactNode
  endIcon?: React.ReactNode
  color?: 'primary' | 'secondary' | 'text' | 'error' | 'secondary-error'
  disabled?: boolean
  fullWidth?: boolean
  styles?: React.CSSProperties
}

const Button: React.FC<ButtonProps> = ({
  label,
  onClick,
  startIcon,
  endIcon,
  color = 'primary',
  disabled = false,
  fullWidth = false,
  styles = {},
}) => {
  return (
    <StyledButton
      onClick={onClick}
      startIcon={startIcon}
      endIcon={endIcon}
      className={color}
      disabled={disabled}
      fullWidth={fullWidth}
      style={styles}
    >
      {label}
    </StyledButton>
  )
}

export default Button
